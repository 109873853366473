import '../Features.scss'
import { MockMessage } from './MockMessage'
import { useEffect, useState } from 'react'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import { AiOutlineLoading } from 'react-icons/ai'
import { Title } from '../../../../Components/Title/Title'
import { FaRobot } from 'react-icons/fa6'

// NOTE: CONFIG
const prompt: string = 'hola'
const conversations = [
        {
                agent: {
                        name: 'Marketing AI Expert',
                        shortDescription: 'Transform marketing with AI',
                        logoUrl: 'FaBullhorn', // Icon for marketing
                        logoColorHex: '#FFEB08', // Yellow
                },
                response: 'marketing response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
                agent: {
                        name: 'Education AI Expert',
                        shortDescription: 'Enhancing learning with AI',
                        logoUrl: 'FaGraduationCap', // Icon for education
                        logoColorHex: '#6A0DAD', // Purple
                },
                response: 'education response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
                agent: {
                        name: 'Android Development AI Expert',
                        shortDescription: 'Power app development with AI',
                        logoUrl: 'FaRobot',
                        logoColorHex: '#4682B4',
                },
                response: 'tech response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
]
const summary = {
        summarizer: {
                name: 'General Summarizer',
                shortDescription: 'Summarize anything',
                logoUrl: 'FaRobot',
                logoColorHex: '#FF6347',
        },
        summarizedResponse: 'summarized response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
}


interface conversationInterface {
        agent: any,
        response: string,
}

enum Phase {
        Conversations = 0,
        Loading = 1,
        Summarizing = 2,
}

interface propsInterface {
        description: string
}

export const SummarizersF = (props: propsInterface) => {
        const [phase, setPhase] = useState<Phase>(Phase.Conversations)
        const [index, setIndex] = useState<number>(0)
        const [response, setResponse] = useState<string>('')

        useEffect(() => {
                if (phase === Phase.Loading) {
                        setTimeout(() => {
                                setPhase(Phase.Summarizing)
                        }, 3000)
                }
        }, [phase])

        useEffect(() => {       // typing effect
                if (phase === Phase.Summarizing) {
                        let interval: NodeJS.Timeout

                        interval = setInterval(() => {
                                setIndex((prevIndex) => {
                                        if (prevIndex < summary.summarizedResponse.length) {
                                                setResponse((prev) => prev + summary.summarizedResponse[prevIndex])
                                                return prevIndex + 1
                                        } else {
                                                clearInterval(interval)
                                                return prevIndex
                                        }
                                })
                        }, 10) // Speed of typing

                        return () => clearInterval(interval)
                } else {
                        setIndex(0)
                        setResponse('')
                }
        }, [phase, conversations])


        return (
                <div className="summarizer-wrapper" >
                        <div className="summarizer-wrapper-title" onClick={() => setPhase(Phase.Conversations)}>
                                <Title title="Summarizers" subtitle={props.description} imgPosition="top" titleImg={() => <FaRobot />} />
                        </div>
                        <div className="features-feature features-summarizers">

                                {phase === Phase.Conversations ?
                                        <div className="features-summarizers-funtool">
                                                <div className="features-summarizers-funtool-prompt">
                                                        <MockMessage
                                                                authorName="You"
                                                                message={prompt}
                                                                isUser={true}
                                                                className="no-hover"
                                                        />
                                                </div>
                                                <div className="features-summarizers-funtool-responses">
                                                        {conversations.map((conv: conversationInterface, index: number) => {
                                                                return (
                                                                        <div
                                                                                key={index}
                                                                                className="features-summarizers-funtool-responses-response"
                                                                        >
                                                                                <MockMessage
                                                                                        authorName={conv.agent.name}
                                                                                        message={conv.response}
                                                                                        iconColor={conv.agent.logoColorHex}
                                                                                        isUser={false}
                                                                                        className="no-hover"
                                                                                />
                                                                        </div>
                                                                )
                                                        })}
                                                </div>
                                                <button className="features-summarizers-funtool-button" onClick={() => setPhase(Phase.Loading)}>
                                                        <h2>Summarize</h2>
                                                </button>
                                        </div>
                                        :
                                        <div className="features-summarizers-loadingWrapper">
                                                <div
                                                        className={'features-summarizers-loadingWrapper-loading ' + (phase === Phase.Summarizing ? 'features-summarizers-loadingWrapper-loading-loaded' : 'features-summarizers-loadingWrapper-loading-notLoaded'
                                                        )}
                                                >
                                                        {phase === Phase.Loading && <AgentCard
                                                                agent={{
                                                                        name: summary.summarizer.name,
                                                                        shortDescription: summary.summarizer.shortDescription,
                                                                        tags: [],
                                                                        logoColorHex: summary.summarizer.logoColorHex,
                                                                        authorId: '',
                                                                        logoUrl: summary.summarizer.logoUrl,
                                                                        description: '',
                                                                        usageInstruction: '',
                                                                        id: '',
                                                                        isPublic: true,
                                                                        suggestedAIModel: '',
                                                                        documentDisplayPermission: 0,
                                                                        agentType: '',
                                                                }}
                                                                description={true}
                                                                onClickRedirect={false}
                                                                onClick={() => {
                                                                }}
                                                        />}
                                                        {phase === Phase.Loading &&
                                                                <AiOutlineLoading className="features-summarizers-loadingWrapper-loading-icon" />}
                                                        {phase === Phase.Summarizing && <MockMessage
                                                                authorName={summary.summarizer.name}
                                                                isUser={false}
                                                                message={response}
                                                                iconColor={summary.summarizer.logoColorHex}
                                                        />}
                                                </div>
                                        </div>
                                }
                        </div>
                </div>
        )
}

import { apiGet, apiPost, apiPut } from '../Utils/RequestsUtil'
import Cookies from 'js-cookie'

export const getMyUserAccount = async () => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'User/MyUserAccount',
        null,
        false,
        // @ts-ignore TODO: fix when tsx is applied
        jwt,
    )
}

export const getUserAccount = async (userId: string) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Developer/GetAuthorById/' + userId,
        null,
        false,
        // @ts-ignore TODO: fix when tsx is applied
        jwt,
    )
}

export const registerAsDeveloper = async () => {
    const jwt = Cookies.get('jwt')
    return await apiPost(
        'Developer/PromoteUserToDeveloper',
        {},
        false,
        // @ts-ignore
        jwt,
    )
}

export const loginAsUser = async () => {
    const jwt = Cookies.get('jwt')
    return await apiPost(
        'Developer/LoginAsUser',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getMyDeveloperAccount = async () => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Developer/MyDeveloperAccount',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getMyUserStats = async (from: string, to: string) => {
    console.log(from, to)
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'User/Stats',
        // @ts-ignore
        {
            start: from,
            end: to,
        },
        true,
        // @ts-ignore
        jwt,
    )
}

export const getMyUserStatsDaily = async (from: string, to: string) => {
    console.log(from, to)
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'User/DailyStats',
        // @ts-ignore
        {
            start: from,
            end: to,
        },
        true,
        // @ts-ignore
        jwt,
    )

}

export const getMyDeveloperStats = async (from: string, to: string) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Developer/StatsDaily',
        // @ts-ignore
        {
            start: from,
            end: to,
        },
        true,
        // @ts-ignore
        jwt,
    )
}

export const editUser = async (name: string, surname: string) => {
    const jwt = Cookies.get('jwt')
    return await apiPut(
        'User/User',
        // @ts-ignore
        {
            name: name,
            surname: surname,
        },
        false,
        // @ts-ignore
        jwt,
    )
}

export const verifyUser = async (code: string | null) => {
    return await apiPost(
        'User/VerifyUser/' + code,
        null,
        false,
    )
}
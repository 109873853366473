import './Overview.scss'
import WeeklyTeamUsage from '../../Charts/WeeklyTeamUsage'
import { FaPlus, FaRobot } from 'react-icons/fa'
import { GoRepoForked } from 'react-icons/go'
import { OrganizationInterface } from '../../../../Interfaces/organizationInterfaces'
import { getExpert } from '../../../../Requests/agentRequests'
import { useEffect, useState } from 'react'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import { AiOutlineUser } from 'react-icons/ai'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { Modal } from '../../../../Components/Modal/Modal'
import TextareaAutosize from 'react-textarea-autosize'
import { addMemberToOrganization } from '../../../../Requests/organizationsRequests'


interface OverviewInterface {
    organization: OrganizationInterface
    team: any[]
    handleEmployeeClick: (employeeId: string | null) => void
    userRole: string
    userId: string | null
}

const Overview = ({ organization, team, handleEmployeeClick, userRole, userId }: OverviewInterface) => {

    const [experts, setExperts] = useState<AgentInterface[]>([])
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')

    useEffect(() => {
        setExperts([])
        organization.assignedExpertsIds?.forEach((expertId) => {
            getExpert(expertId).then((res) => {
                if (experts.find((e) => e.id === res.id)) return
                setExperts((prev) => [...prev, res])
            })
        })
    }, [organization])

    useEffect(() => {
        handleEmployeeClick(null)
    }, [])

    const onConfirm = () => {
        setModalOpen(false)
        addMemberToOrganization(organization.id, email.trim()).then((res) => {
            window.location.reload()
        }).catch((err) => {
            // sendAlert
        })
    }

    return (
        <div className="overview-content-overview">
            {modalOpen &&
                <Modal close={() => {
                    setModalOpen(false)
                }} className="overview-content-overview-modal" size="small">
                    <p>Add a new member</p>
                    <TextareaAutosize value={email} onChange={(e) => setEmail(e.target.value)} minRows={1} maxRows={1}
                                      className="dummyFormLeft-forms-input"
                                      required={true}
                                      maxLength={160}
                    >
                    </TextareaAutosize>
                    <div className="overview-content-overview-modal-buttons">
                        <button onClick={() => {
                            setModalOpen(false)
                        }}>Cancel
                        </button>
                        <button onClick={onConfirm}>Confirm</button>
                    </div>
                </Modal>
            }
            <div className="overview-content-overview-left">
                {/*<div className='overview-content-overview-item overview-content-overview-info'>*/}
                {/*</div>*/}
                {userRole !== 'Member' &&
                    <div className="overview-content-overview-item overview-content-overview-quickActions">
                        <a className="overview-content-overview-quickActions-item"
                           href="../developer/agents/new?type=expert">
                            <FaRobot className="overview-content-overview-quickActions-item-img" />
                            <h1>New expert</h1>
                        </a>
                        <div className="overview-content-overview-quickActions-line" />
                        <a className="overview-content-overview-quickActions-item"
                           href="../developer/agents/new?type=summarizer">
                            <GoRepoForked className="overview-content-overview-quickActions-item-img" />
                            <h1>New summarizer</h1>
                        </a>
                        <div className="overview-content-overview-quickActions-line" />
                        <button className="overview-content-overview-quickActions-item" onClick={() => {
                            setModalOpen(true)
                        }}>
                            <FaPlus className="overview-content-overview-quickActions-item-img" />
                            <h1>New member</h1>
                        </button>
                    </div>}
                <div
                    className={userRole !== 'Member' ? 'overview-content-overview-item overview-content-overview-employees' : 'overview-content-overview-item overview-content-overview-employees-member'}>
                    <h1>Team</h1>
                    {team.sort((a, b) => {
                        if (a.id === userId) return -1
                        if (b.id === userId) return 1

                        const roleOrder = ['Admin', 'Manager', 'Member']
                        return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role)
                    }).map((employee, index) => {
                        return (
                            <div
                                className={index === 0 ? 'overview-content-overview-employees-item-user overview-content-overview-employees-item' : 'overview-content-overview-employees-item'}
                                key={index} onClick={() => {
                                handleEmployeeClick(employee.id)
                            }}>
                                <AiOutlineUser className="overview-content-overview-employees-item-img" />
                                <h1>{employee.name + ' ' + employee.surname}</h1>
                                {employee.role !== 'User' &&
                                    <h1 className="overview-content-overview-employees-item-role">{employee.role}</h1>}
                                <MdOutlineKeyboardArrowUp className="overview-content-overview-employees-item-arrow" />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="overview-content-overview-right">
                {userRole !== 'Member' && <WeeklyTeamUsage organization={organization} employeeId={null} />}
                {userRole === 'Member' && <WeeklyTeamUsage organization={organization} employeeId={userId} />}
                <div className="overview-content-overview-item overview-content-overview-experts">
                    <h1>Experts</h1>
                    {experts.map((expert, index) => {
                        return (
                            <AgentCard agent={expert} key={index} small={true} description={false} colored={false} />
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default Overview
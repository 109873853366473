import { MessageFlow } from '../Utils/enums'
import { ContextInterface } from './documentInterfaces'
import { AgentInterface } from './agentsInterfaces'

export enum MessageSender {
    USER,
    AGENT,
    SUMMARIZER
}

export enum MessageType {
    CONTEXT_MESSAGE = 'context_message',
    USER_MESSAGE = 'user_message',
    EXPERT_ANSWER = 'expert_answer',
    SUMMMARIZER_ANSWER = 'summarizer_answer',
}

export interface ConversationInterface {
    id: string,
    message: MessageInterfaceApi[],
    name: string,
    startedAt: Date,
    startedConversationBy: string,
    language: string,
    organization: string
}

export interface MessageInterfaceApi {
    answeredAt: Date,
    createdAt: Date,
    id: string,
    invokeUserId: string,
    isAnswered: boolean,
    messageContentParts: MessageContentPartInterface[],
    messageDepth: number,
    messageType: MessageType,
    nextMessagesIds: string[],
    agentAnswerIds: string[],
    agentAnswers?: ExpertAnswerInterface[],
    summarizerAnswerIds: string,
    selectedExpertAnswerId: string | null,
    selectedSummarizerAnswerId: string | null,
    previousMessageId: string | null,
    listOfAgentIds: string,
}

export interface MessageInterface extends MessageInterfaceApi {
    messageFlow: MessageFlow,
    agentAnswerIds: string[],
    agentAnswers: ExpertAnswerInterface[],
    summarizerAnswerIds: string,
    summarizerAnswer: any,
    versions: number,
    children: MessageInterface[],
    depth: number,
    finished: boolean,
}

export interface MessageContentPartInterface {
    id: string,
    messagePartContent_TextAlt: string,
    messagePartType: string,
    text: string,
    page: null,
    boundingBoxUpperLeftPoint: null
    boundingBoxBottomRightPoint: null
}

interface AIModelSettingsInterface {
}

export interface ExpertAnswerInterface {
    id: string,
    expertResponding: AgentInterface,
    chosen: boolean,
    chosenFinal: boolean,
    agentAnswerType: MessageType,
    finished: boolean,
    aiModelSettings?: AIModelSettingsInterface,
    context?: ContextInterface[],
    conversationId: string,
    createdAt: Date,
    response: ResponseInterface[],
    chunk: 0,
}

export enum MessagePartType {
    TEXT = 'text_messagePart',
}

export interface ResponseInterface {
    id: string,
    messagePartContent_TextAlt: string
    messagePartType: MessagePartType,
    text: string,
    senderId: string,
}
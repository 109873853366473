import Cookies from 'js-cookie'


const Logout = (navigate: Function) => {
    Cookies.remove('jwt', { domain: '.' + process.env.REACT_APP_APPI_DOMAIN })
    window.location.href = 'http://' + process.env.REACT_APP_APPI_URL
    localStorage.removeItem('hasOrganization')
    localStorage.removeItem('userName')
}

export default Logout
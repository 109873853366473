import React, { useRef } from 'react'
import './Contact.scss'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import TextareaAutosize from 'react-textarea-autosize'
import { PopupButton } from 'react-calendly'
import { AlertSeverity, AlertType } from '../../../Interfaces/alertsInterfaces'

export const Contact = ({ sendAlert }: { sendAlert: Function }) => {
    return (
        <div className="contactWrapper">
            <FreeBlobEnv />
            <ContactComponent title={true} side={true} sendAlert={sendAlert} />
        </div>
    )
}

interface ContactComponentInterface {
    title: boolean
    side: boolean
    sendAlert: Function
}

export const ContactComponent = (props: ContactComponentInterface) => {

    const nameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const subjectRef = useRef<HTMLInputElement>(null)
    const messageRef = useRef<HTMLTextAreaElement>(null)

    const onSend = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!nameRef.current || !emailRef.current || !messageRef.current) {
            alert('Please fill out all required fields.')
            return
        }

        const name = nameRef.current.value
        const email = emailRef.current.value
        const subject = subjectRef.current?.value || 'No subject'
        const message = messageRef.current.value

        const apiKey = process.env.REACT_APP_MAILERLITE
        const groupId = process.env.REACT_APP_MAILERLITE_CONTACTED_GROUP_ID

        const response = await fetch(`https://connect.mailerlite.com/api/subscribers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            },
            body: JSON.stringify({
                name,
                email,
                fields: {
                    subject,
                    message,
                },
                groups: [groupId],
            }),
        })

        if (response.status === 422) {
            props.sendAlert(AlertType.UNKNOWN, 'Wrong data format', AlertSeverity.ERROR)
        } else if (response.status === 400) {
            alert('Email already exists.')
            props.sendAlert(AlertType.UNKNOWN, 'Email already exists', AlertSeverity.ERROR)
        } else if (response.ok) {
            props.sendAlert(AlertType.SUCCESS, 'Thank you for signing up!', AlertSeverity.SUCCESS)
            nameRef.current.value = ''
            emailRef.current.value = ''
            if (subjectRef.current)
                subjectRef.current.value = ''
            messageRef.current.value = ''
        } else {
            alert('Something went wrong. Please try again later.')
        }
    }

    return (
        <div className="contact">
            {props.side && <div className="contact-left">
                <h1 className="contact-title">Schedule time with us to:</h1>
                <ul>
                    <li>Get all your questions answered</li>
                    <li>Do a live walkthrough of the platform</li>
                    <li>See customized automation examples</li>
                    <li>Explore which pricing plan is best for you</li>
                </ul>
                <PopupButton
                    url="https://calendly.com/adam-kaniasty/30min"
                    rootElement={document.getElementById('root') as HTMLElement}
                    text="Click here to schedule!"
                />
            </div>}
            <div className="contact-right">
                <form onSubmit={(e) => onSend(e)}>
                    <label className="contact-label">Your name / company</label>
                    <input ref={nameRef} className="contact-input" required={true} />
                    <label className="contact-label">Your email</label>
                    <input ref={emailRef} type="email" className="contact-input" required={true} />
                    <label className="contact-label">Subject (optional)</label>
                    <input ref={subjectRef} className="contact-input" />
                    <label className="contact-label">Message</label>
                    <TextareaAutosize ref={messageRef} minRows={10} maxRows={15} className="contact-textarea"
                                      required={true} />
                    <button className="contact-button" type="submit">Send</button>
                </form>
            </div>
        </div>
    )
}

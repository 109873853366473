import './OrganizationCreate.scss'

interface OrganizationCreateInterface {
    sendAlert: Function
}

export const OrganizationCreate = ({ sendAlert }: OrganizationCreateInterface) => {
    return (
        <div className='organization-create'>
            <h1>Organization Create</h1>
        </div>
    )
}
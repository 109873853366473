import './IndividualChatProcessing.scss'
import { FaImage, FaSquareRootAlt, FaTable, FaTextHeight } from 'react-icons/fa'

export const IndividualChatProcessing = () => {


    const icons = {
        tables: <FaTable />,
        images: <FaImage />,
        paragraphs: <FaTextHeight />,
        formulas: <FaSquareRootAlt />,
    }

    return (
        <div className="individualChatProcessing">
            {Object.keys(icons).map((key, index) => (
                <div key={index} className="individualChatProcessing-icon">
                    {icons[key as keyof typeof icons]}
                    <p>{key}</p>
                </div>
            ))}
        </div>
    )
}


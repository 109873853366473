import React, { useEffect, useState } from 'react'
import { SidebarItem } from '../SidebarItem/SidebarItem'
import { SearchBar } from '../SearchBar'
import { useGlobalState } from '../../../../GlobalStateContext'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import { useChatContext } from '../../ChatPropsManager'


export const SidebarAgents = () => {

    const [agents_list_filtered, setAgents_list_filtered] = React.useState<any>(null)
    const [globalState, globalActions] = useGlobalState()
    const [agentsListPrivate, setAgentsListPrivate] = useState<any>([])
    const [agentsListPublic, setAgentsListPublic] = useState<any>([])

    const {
        agentsList,
        agentsSelected,
        setAgentsSelected,
        selectedItem,
        setSelectedItem,
    } = useChatContext()

    useEffect(() => {
        const newPrivate: AgentInterface[] = []
        const newPublic: AgentInterface[] = []
        agentsList.forEach((agent) => {
            if (agent.isPublic) {
                newPublic.push(agent)
            } else {
                newPrivate.push(agent)
            }
        })
        setAgentsListPrivate(newPrivate)
        setAgentsListPublic(newPublic)
    }, [agentsList])


    return (
        <div className="chat-sidebar-agents">
            <h1 className="chat-sidebar-titles">Agents</h1>
            <SearchBar
                id="expert"
                agents_list={agentsList}
                setAgents_list_filtered={setAgents_list_filtered}
            />
            {!agents_list_filtered && <>
                {(globalState.user?.favouriteExperts?.length !== 0 && !agents_list_filtered) &&
                    <div className="line-wrapper">
                        <h1>Favourites</h1>
                    </div>}
                {globalState.user?.favouriteExperts?.map((agent: any, index: number) => (
                    <SidebarItem
                        id={agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id) ? 'agents-item-selected' : ''}
                        onClick={() => {
                            if (agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id)) {
                                setAgentsSelected(agentsSelected.filter((agent_item: any) => agent_item.id !== agent.id))
                            } else {
                                if (agentsSelected.length < 5)
                                    setAgentsSelected([...agentsSelected, agent])
                            }
                        }
                        }
                        item={agent}
                        type={true}
                        key={index}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                ))}
                {(agentsListPrivate.length !== 0 && !agents_list_filtered) &&
                    <div className="line-wrapper">
                        <div className="line" />
                        <h1>Private</h1>
                    </div>}
                {agentsListPrivate.map((agent: any, index: number) => (
                    <SidebarItem
                        id={agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id) ? 'agents-item-selected' : ''}
                        onClick={() => {
                            if (agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id)) {
                                setAgentsSelected(agentsSelected.filter((agent_item: any) => agent_item.id !== agent.id))
                            } else {
                                if (agentsSelected.length < 5)
                                    setAgentsSelected([...agentsSelected, agent])
                            }
                        }
                        }
                        item={agent}
                        type={true}
                        key={index}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                ))}
                {globalState.user?.favouriteExperts.length !== 0 && <div className="line-wrapper">
                    <div className="line" />
                    <h1>All</h1>
                </div>}
                {agentsListPublic.map((agent: any, index: number) => {
                    return (
                        <SidebarItem
                            id={agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id) ? 'agents-item-selected' : ''}
                            onClick={() => {
                                if (agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id)) {
                                    setAgentsSelected(agentsSelected.filter((agent_item: any) => agent_item.id !== agent.id))
                                } else {
                                    if (agentsSelected.length < 5)
                                        setAgentsSelected([...agentsSelected, agent])
                                }
                            }}
                            item={agent}
                            type={true}
                            key={index}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                        />
                    )
                })}
            </>}
            {agents_list_filtered && agents_list_filtered?.map((agent: any, index: number) => (
                <SidebarItem
                    id={agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id) ? 'agents-item-selected' : ''}
                    onClick={() => {
                        if (agentsSelected.map((item: AgentInterface) => item.id).includes(agent.id)) {
                            setAgentsSelected(agentsSelected.filter((agent_item: any) => agent_item.id !== agent.id))
                        } else {
                            if (agentsSelected.length < 5)
                                setAgentsSelected([...agentsSelected, agent])
                        }
                    }}
                    item={agent}
                    type={true}
                    key={index}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
            ))}
            <h1 className="chat-sidebar-agents-filler">_</h1>
        </div>

    )
}
import '../Features.scss'
import { FaRobot } from 'react-icons/fa'
import { IoDocumentTextOutline } from 'react-icons/io5'

const description_left: string = 'Access your organization\'s collective knowledge instantly as our AI Experts intelligently search and retrieve exactly what you need from your project archives.'
const description_right: string = 'Watch your AI Experts grow smarter with every interaction, continuously evolving to better understand and address your industry-specific challenges.'


const Animation1 = () => {
    return (
        <div className="features-knowledgebase-container-animation">
            <div className="features-knowledgebase-container-animation-orbiting">
                <FaRobot className="features-knowledgebase-container-animation-center-div" />
                {[...Array(6)].map((_, index) => (
                    <IoDocumentTextOutline
                        className="features-knowledgebase-container-animation-orbiting-div"
                        style={{ animationDelay: `${(index * 5) / 6}s` }} // Add animation delay
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
}

const Animation2 = () => {
    return (
        <div className="features-knowledgebase-container-animation">
            <div className="features-knowledgebase-container-animation-line">
                <IoDocumentTextOutline className="features-knowledgebase-container-animation-line-icon" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <img src={require('../../../../Images/Logo/APPI_icon_white.png')} alt={'appi'}
                     className="features-knowledgebase-container-animation-line-appi" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <div className="features-knowledgebase-container-animation-line-dot" />
                <FaRobot className="features-knowledgebase-container-animation-line-icon" />
            </div>
        </div>
    )
}

export const Knowledgebase = () => {
    return (
        <div className="features-feature features-knowledgebase">
            <div className="features-knowledgebase-container">
                <h2 className="features-knowledgebase-container-title">Project intelligence</h2>
                <p className="features-knowledgebase-container-description">{description_left}</p>
                <Animation1 />
            </div>
            <div className="features-knowledgebase-container">
                <h2 className="features-knowledgebase-container-title">Adaptive learning</h2>
                <p className="features-knowledgebase-container-description">{description_right}</p>
                <Animation2 />
            </div>
        </div>
    )
}

import { useState } from 'react'
import { AiOutlineSend, AiOutlineUser } from 'react-icons/ai'
import '../../../Landing/DocumentShowcase/DocumentShowcase.scss'
import { MockMessage } from './MockMessage'

// NOTE: CONFIG
const leftSide = {
        title: 'titleee',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
}
const responses = [
        'amazing response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', // with custom profile
        'decent response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', // without custom profile
]
const agent = {
        name: 'Cybersecurity AI Expert',
        shortDescription: 'Strengthen security with AI',
        logoUrl: 'FaShieldAlt', // Icon for cybersecurity
        logoColorHex: '#F5A302', // Orange
}
const profile = {
        name: 'Friendly John',
        instruction: 'Feel free to use a friendly tone and call me John. Keep explanations simple and conversational. I’m looking for suggestions rather than strict guidelines, so feel free to offer opinions or flexible options.',
}

enum Phase {
        showProfile = 0,
        showResponses = 1
}

export const CustomProfileF = () => {
        const [phase, setPhase] = useState<Phase>(Phase.showProfile)

        return (
                <div className="features-feature features-customProfile">
                        <div className="features-customProfile-rightSide">
                                {phase === Phase.showProfile ?
                                        <div
                                                className="features-customProfile-rightSide-profile"
                                                onClick={() => setPhase(Phase.showResponses)}
                                        >
                                                <div className="features-customProfile-rightSide-profile-header">
                                                        <AiOutlineUser className="features-customProfile-rightSide-profile-header-icon" />
                                                        <h2>Custom Profile</h2>
                                                </div>
                                                <h2>Profile name</h2>
                                                <div className="features-customProfile-rightSide-profile-textContainer">
                                                        <p>{profile.name}</p>
                                                </div>
                                                <h2>Profile instruction</h2>
                                                <div className="features-customProfile-rightSide-profile-textContainer">
                                                        <p>{profile.instruction}</p>
                                                </div>
                                                <div className="features-customProfile-rightSide-profile-click">
                                                        <AiOutlineSend className="features-customProfile-rightSide-profile-click-icon" />
                                                </div>
                                        </div>
                                        :
                                        <div className="features-customProfile-rightSide-responses">
                                                {responses.map((response: string, index: number) => {
                                                        return (
                                                                <div
                                                                        className="features-customProfile-rightSide-responses-response"
                                                                        key={index}
                                                                        onClick={() => setPhase(Phase.showProfile)}
                                                                >
                                                                        <div className="features-customProfile-rightSide-responses-response-profile">
                                                                                <h2>{index === 0 ? profile.name : 'No profile'}</h2>
                                                                        </div>
                                                                        <MockMessage
                                                                                authorName={agent.name}
                                                                                message={response}
                                                                                isUser={false}
                                                                                iconColor={agent.logoColorHex}
                                                                        />
                                                                </div>
                                                        )
                                                })}
                                        </div>
                                }
                        </div>
                </div>
        )
}

// useFetchData.js
import React, { useEffect } from 'react'
import { getCustomProfiles } from '../../../Requests/customProfilesRequests'
import { getExperts, getSummarizers } from '../../../Requests/agentRequests'
import { CustomProfile } from '../../../Interfaces/chatInterfaces'
import { AgentInterface } from '../../../Interfaces/agentsInterfaces'
import { getModels } from '../../../Requests/aiModelRequests'
import { AiModelInterface } from '../../../Interfaces/modelInterfaces'

const useFetchData = (
    customProfiles: CustomProfile[],
    setCustomProfiles: React.Dispatch<React.SetStateAction<CustomProfile[]>>,
    agentsList: AgentInterface[],
    setAgentsList: React.Dispatch<React.SetStateAction<AgentInterface[]>>,
    summarizersList: AgentInterface[],
    setSummarizersList: React.Dispatch<React.SetStateAction<AgentInterface[]>>,
    models: AiModelInterface[],
    setModels: React.Dispatch<React.SetStateAction<AiModelInterface[]>>,
    setSelectedModel: React.Dispatch<React.SetStateAction<AiModelInterface>>,
    globalState: any,
    randomColor: Function,
) => {
    useEffect(() => {
        if (customProfiles.length === 1) {
            getCustomProfiles().then((response) => {
                setCustomProfiles(customProfiles.concat(response))
            })
        }

        if (agentsList.length === 0 || summarizersList.length === 0 || globalState.user.id) {
            getExperts(globalState.developer).then((response) => {
                response.map((agent: AgentInterface) => {
                    if (!agent.hasOwnProperty('img') && (!agent.hasOwnProperty('logoColorHex') || agent.logoColorHex === '')) {
                        agent.logoColorHex = randomColor()
                    }
                    return agent
                })
                setAgentsList(response)
            })

            getSummarizers().then((response) => {
                response.map((agent: AgentInterface) => {
                    if (!agent.hasOwnProperty('img') && !agent.hasOwnProperty('logoColorHex')) {
                        agent.logoColorHex = randomColor()
                    }
                    return agent
                })
                setSummarizersList(response)
            })
        }

        // Fetch models if models list is empty
        if (models.length === 0) {
            getModels().then((response) => {
                setModels(response)
                setSelectedModel(response[0])
            })
        }
    }, [globalState.developer, globalState.user])
}

export default useFetchData

import TextareaAutosize from 'react-textarea-autosize'
import { RiAuctionLine } from 'react-icons/ri'

import { AiFillFile, AiFillPlusCircle, AiOutlineLoading, AiOutlineSend } from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import { FaRobot } from 'react-icons/fa'
import { BsPlugin, BsPlusSquare } from 'react-icons/bs'
import React, { useEffect, useState } from 'react'
import './UserInput.scss'
import './UserInputMobile.scss'
import { AgentType, ConversationState } from '../../../Utils/enums'
import { UserInputAgentIcon } from './UserInputAgentIcon/UserInputAgentIcon'
import Dropzone from 'react-dropzone'
import { useChatContext } from '../ChatPropsManager'
import { agentIconType } from '../../../Interfaces/agentsInterfaces'
import { UserInputSearchBar } from './UserInputSearchBar/UserInputSearchBar'

interface UserInputInterface {
    SidebarInterface: any
    sendAnswers: Function
    sendFinalAnswer: Function
    sendMessage: Function
    user_message_ref: any
}

export const UserInput = ({
                              SidebarInterface,
                              sendAnswers,
                              sendFinalAnswer,
                              sendMessage,
                              user_message_ref,
                          }: UserInputInterface) => {

    const {
        agentsSelected,
        setSidebar,
        summarizer,
        conversationState,
        selectedAnswers,
        finalAnswer,
        setSidebarModal,
        setAgentsSelected,
        setSummarizer,
        mobile,
        files,
        setFiles,
        agentsList,
    } = useChatContext()
    const icons = FaIcons as agentIconType
    const [searchModal, setSearchModal] = useState<boolean>(false)
    const handleKeyDown = async (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()
            if (agentsSelected.length > 0 && conversationState === ConversationState.READY)
                await sendMessage()
        }
        if (e.key === '@') {
            e.preventDefault()
            setSearchModal(true)
        }
    }

    const handleFocus = () => {
        // @ts-ignore
        document.querySelector('.userInput-wrapper').classList.add('userInput-wrapper-focused')
    }

    const handleBlur = () => {
        // @ts-ignore
        document.querySelector('.userInput-wrapper').classList.remove('userInput-wrapper-focused')
    }

    const handeSearchSelection = (agent: any) => {
        if (agentsSelected.length < 5) {
            setAgentsSelected([...agentsSelected, agent])
        }
        setSearchModal(false)
    }

    useEffect(() => {
        if (searchModal) {
            document.addEventListener('click', (e) => {
                if (!(e.target as any).classList.contains('userInput-searchBar')) {
                    setSearchModal(false)
                }
            })
        }
    }, [searchModal])

    if (conversationState !== ConversationState.CHOOSE && conversationState !== ConversationState.CHOOSE_FINAL) {
        return (
            <div className='userInput-wrapper'>
                {searchModal &&
                    <UserInputSearchBar options={agentsList.filter(
                        agent => !agentsSelected.some(selected => selected.id === agent.id),
                    )} onSelection={handeSearchSelection} />
                }
                <div className='userInput-wrapper-top'>
                    <TextareaAutosize autoFocus minRows={1} maxRows={10} className='userInput'
                                      placeholder='Type a message' onKeyDown={handleKeyDown}
                                      ref={user_message_ref}
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                    />
                    {(agentsSelected.length !== 0 && conversationState === ConversationState.READY) && (
                        <AiOutlineSend className='send' onClick={() => sendMessage()} />
                    )}
                    {(conversationState === ConversationState.LOADING_AGENT_RESPONSE || conversationState === ConversationState.LOADING_SUMMARIZER_RESPONSE) && (
                        <div className='userInput-loading'>
                            <AiOutlineLoading id='major' />
                        </div>
                    )}
                </div>
                <div className='userInput-wrapper-bottom'>
                    {/*<div className='userInput-media'>*/}
                    {/*        <BsPlusSquare className='userInput-media-icon' />*/}
                    {/*        <p>Attach</p>*/}
                    {/*</div>*/}
                    <Dropzone onDrop={acceptedFiles => setFiles([...files, ...acceptedFiles])}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='userInput-media'>
                                <input {...getInputProps()} />
                                {files.map((file, index) => (
                                    <div key={index} className='userInput-media-file'>
                                        <AiFillFile className='userInput-media-file-icon' />
                                        <p>{file.name.slice(0, 20)}</p>
                                    </div>
                                ))}
                                <BsPlusSquare className='userInput-media-icon' />
                                {files.length === 0 && <p>Attach</p>}
                            </div>
                        )}
                    </Dropzone>
                    {summarizer &&
                        <div className='userInput-summarizer-wrapper'>
                            <UserInputAgentIcon
                                agent={summarizer}
                                setSidebar={setSidebar}
                                setSidebarModal={setSidebarModal}
                                SidebarInterface={SidebarInterface}
                                type={AgentType.SUMMARIZER}
                                removeAgent={() => {
                                    setSummarizer(null)
                                }}
                            />
                        </div>
                    }
                    {!summarizer && (
                        <div className='userInput-summarizer' onClick={() => {
                            setSidebarModal(true)
                            setSidebar(SidebarInterface.Summarizer)

                        }}>
                            <div className='userInput-summarizer-iconWrapper'>
                                <RiAuctionLine id='major' />
                                <AiFillPlusCircle id='minor' />
                            </div>
                            <p>Summarizers</p>
                        </div>
                    )}
                    {agentsSelected.length > 0 && (
                        <div className='userInput-agents'>
                            {!mobile && agentsSelected.map((agent: any, index: number) => {
                                return (
                                    <UserInputAgentIcon
                                        agent={agent}
                                        setSidebar={setSidebar}
                                        setSidebarModal={setSidebarModal}
                                        SidebarInterface={SidebarInterface}
                                        key={index}
                                        type={AgentType.EXPERT}
                                        removeAgent={() => {
                                            setAgentsSelected(agentsSelected.filter((agent_selected: any) => agent_selected.id !== agent.id))
                                        }}
                                    />
                                )
                            })}
                            {mobile && <div className='userInput-agents-mobile' onClick={() => {
                                setSidebarModal(true)
                                setSidebar(SidebarInterface.Agents)
                            }}>
                                <FaRobot className='userInput-agents-mobile-icon' />
                                <h1>{agentsSelected.length}</h1>
                            </div>}
                        </div>
                    )}
                    {agentsSelected.length === 0 && (
                        <div className='userInput-addIcon' onClick={() => {
                            setSidebarModal(true)
                            setSidebar(SidebarInterface.Agents)
                        }}>
                            <div className='userInput-addIcon-iconWrapper'>
                                <BsPlugin id='major' />
                                <AiFillPlusCircle id='minor' />
                            </div>
                            <p>Experts</p>
                        </div>
                    )}
                </div>
            </div>
        )
    } else if (conversationState === ConversationState.CHOOSE) {
        return (
            <div className='userInput-wrapper'>
                <div className='userInput-wrapper-top'>

                    <h1 className='userInput-choosePrompt'>Choose experts answers</h1>
                    {(selectedAnswers.length !== 0) && (
                        <AiOutlineSend className='send' onClick={() => sendAnswers(selectedAnswers)} />
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <div className='userInput-wrapper'>
                <div className='userInput-wrapper-top'>
                    <h1 className='userInput-choosePrompt'>Choose final answer</h1>
                    {finalAnswer && (
                        <AiOutlineSend className='send send-final' onClick={() => sendFinalAnswer(finalAnswer)} />
                    )}
                </div>
            </div>
        )
    }
}
import { useEffect } from 'react'
import fetchFullConversation from './fetchFullConversation'
import { ConversationState } from '../../../Utils/enums'
import { useChatContext } from '../ChatPropsManager'

const useSwitchConversation = (
    scrollDown: any,
    globalState: any,
    defaultInstruction: any,
) => {

    const {
        selectedConversation,
        setLoadingConversation,
        messages,
        setGenerationStage,
        setCustomProfilesOpen,
        setFlow,
        setMessages,
        setChosenMessages,
        setSelectedCustomInstruction,
        setSummarizerId,
        setAgentsSelected,
        setSummarizer,
        setConversationState,
        setInspectorContext,
        setInspectorOpen,
    } = useChatContext()

    useEffect(() => {
        if (selectedConversation !== null) {
            setLoadingConversation(true)
            fetchFullConversation(
                null,
                setLoadingConversation,
                selectedConversation,
                messages,
                setMessages,
            ).then(r => {
                    scrollDown()
                    setGenerationStage({})
                },
            )
        }
        if (selectedConversation === null) {
            setLoadingConversation(false)
            setCustomProfilesOpen(false)
            setFlow([])
            setMessages([])
            setChosenMessages([])
            setSelectedCustomInstruction(defaultInstruction)
            setSummarizerId([null, null])
            setAgentsSelected(globalState.user?.defaultExperts ? globalState.user.defaultExperts : [])
            setSummarizer(globalState.user?.defaultSummarizer ? globalState.user.defaultSummarizer : null)
            setConversationState(ConversationState.READY)
            setInspectorContext([])
            setInspectorOpen(false)
            setGenerationStage({})
        }
    }, [selectedConversation])
}

export default useSwitchConversation

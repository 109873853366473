import React from 'react'
import './NotFound.scss'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'

const NotFound = () => {
    return (
        <div className='notFound'>
            <FreeBlobEnv />
            <div className='notFound-content'>
                <h1 className='notFound-title'>This is not the page you are looking for</h1>
                <p className='notFound-text'>Contact us, if you need help</p>
                <a href='/contact' className='notFound-button'>Contact</a>
            </div>
        </div>
    )
}

export default NotFound

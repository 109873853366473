import React, { useEffect, useState } from 'react'
import './Application.scss'
import './ApplicationMobile.scss'
import {
    getExpert,
    getExpertDetails,
    modifyDefaultExperts,
    modifyDefaultSummarizer,
    modifyFavExperts,
    modifyFavSummarizers,
} from '../../Requests/agentRequests'
import { FaRobot, FaTasks } from 'react-icons/fa'
import { getUserAccount } from '../../Requests/userRequests'
import { AiOutlineLoading } from 'react-icons/ai'
import { useLocation } from 'react-router-dom'
import { useGlobalState } from '../../GlobalStateContext'
import { AgentDetailsInterface, AgentInterface } from '../../Interfaces/agentsInterfaces'
import FreeBlobEnv from '../../Components/Blobs/FreeBlob/FreeBlobEnv'
import { DevApplicationStats } from '../Developer/DevApplication/Stats/DevApplicationStats'
import { Settings } from '../Developer/DevApplication/Settings/Settings'
import { Prompts } from '../Developer/DevApplication/Prompts/Prompts'
import { Documents } from '../Developer/DevApplication/Documents/Documents'
import { IoSettingsSharp, IoStatsChartSharp } from 'react-icons/io5'
import SEO from '../../Components/SEO'

interface AgentPageInterface {
    handleError: Function
    sendAlert: Function
}

const enum Page {
    OVERVIEW = 'overview',
}

const enum DevPage {
    STATS = 'stats',
    SETTINGS = 'settings',
    PROMPTS = 'prompts',
    DOCUMENTS = 'documents',
}

const Application = ({ handleError, sendAlert }: AgentPageInterface) => {
    const location = useLocation()
    const [agent, setAgent] = useState<AgentInterface | AgentDetailsInterface | null>(null)
    const [author, setAuthor] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [summarizer, setSummarizer] = useState<any>(false)
    const [developer, setDeveloper] = useState<boolean>(false)
    const [globalState, globalActions] = useGlobalState()
    const [page, setPage] = useState<Page | DevPage>(Page.OVERVIEW)

    useEffect(() => {
        const id = location.pathname.split('/')[2]
        getExpertDetails(id).then((res) => {
            setAgent(res)
            setDeveloper(true)
        }).catch((err) => {
            getExpert(id).then((res2) => {
                setAgent(res2)
                setSummarizer(true)
            })
        })
    }, [location.pathname])

    useEffect(() => {
        if (agent?.authorId) {
            getUserAccount(agent?.authorId).then((res) => {
                setAuthor(res)
                setLoading(false)
            })
        }
    }, [agent])
    const handleFavClick = () => {
        if (agent) {
            if (!summarizer) {
                let favs = globalState.user?.favouriteExperts.map((e: AgentInterface) => e.id)
                if (!favs?.includes(agent?.id))
                    favs?.push(agent?.id)
                else
                    favs = favs?.filter((e: string) => e !== agent?.id)
                if (favs)
                    modifyFavExperts(favs).then((res) => {
                        globalActions.setUser(res)
                    }).catch((err) => {
                        handleError(err)
                    })
            } else {
                let favs = globalState.user?.favouriteSummarizers.map((e: AgentInterface) => e.id)
                if (!favs?.includes(agent?.id))
                    favs?.push(agent?.id)
                else
                    favs = favs?.filter((e: string) => e !== agent?.id)
                if (favs)
                    modifyFavSummarizers(favs).then((res) => {
                        globalActions.setUser(res)
                    }).catch((err) => {
                        handleError(err)
                    })
            }
        }
    }
    const handleDefaultClick = () => {
        if (agent) {
            if (!summarizer) {
                let defaults = globalState.user?.defaultExperts.map((e: AgentInterface) => e.id)
                if (!defaults?.includes(agent?.id))
                    defaults?.push(agent?.id)
                else
                    defaults = defaults?.filter((e: string) => e !== agent?.id)
                if (defaults)
                    modifyDefaultExperts(defaults).then((res) => {
                        globalActions.setUser(res)
                    }).catch((err) => {
                        handleError(err)
                    })
            } else {
                const defaultSummarizer = globalState.user?.defaultSummarizer.id
                if (defaultSummarizer !== agent.id)
                    modifyDefaultSummarizer(agent?.id).then((res: any) => {
                        globalActions.setUser(res)
                    }).catch((err: Error) => {
                        handleError(err)
                    })
                else
                    modifyDefaultSummarizer('').then((res) => {
                        globalActions.setUser(res)
                    }).catch((err) => {
                        handleError(err)
                    })
            }
        }
    }
    return (
        <div className="applicationWrapper" id={summarizer ? 'applicationSummarizer' : ''}>
            <SEO
                title={'APPI | ' + agent?.name ?? 'APPI | Agent'}
                description="Account settings"
                keywords={['appi', 'account', 'settings', 'user', 'developer', 'api', 'keys', 'delete', 'danger', 'zone']}
                type="website"
                name="APPI Organization"
            />
            {loading &&
                <div className="application-loading">
                    <AiOutlineLoading className="application-loading-icon" />
                </div>
            }
            {!loading &&
                <div className="application">
                    <div className="application-informationPanel">
                        <FreeBlobEnv width={'100%'} height={'100%'} min={40} max={20} />
                        <p>{author?.name} {author?.surname} / <span>{agent?.name}</span></p>
                    </div>
                    <div className="application-selector">
                        <div className={`application-selector-item ${page === Page.OVERVIEW ? 'selected' : ''}`}
                             onClick={() => {
                                 setPage(Page.OVERVIEW)
                             }}>
                            <FaRobot />
                            <h1>Overview</h1>
                        </div>
                        {developer &&
                            <div className={`application-selector-item ${page === DevPage.STATS ? 'selected' : ''}`}
                                 onClick={() => {
                                     setPage(DevPage.STATS)
                                 }}>
                                <IoStatsChartSharp />
                                <h1>Stats</h1>
                            </div>}
                        {developer &&
                            <div className={`application-selector-item ${page === DevPage.SETTINGS ? 'selected' : ''}`}
                                 onClick={() => {
                                     setPage(DevPage.SETTINGS)
                                 }}>
                                <IoSettingsSharp />
                                <h1>Settings</h1>
                            </div>}
                        {developer &&
                            <div className={`application-selector-item ${page === DevPage.PROMPTS ? 'selected' : ''}`}
                                 onClick={() => {
                                     setPage(DevPage.PROMPTS)
                                 }}>
                                <FaTasks />
                                <h1>Prompts</h1>
                            </div>}
                        {developer &&
                            <div className={`application-selector-item ${page === DevPage.DOCUMENTS ? 'selected' : ''}`}
                                 onClick={() => {
                                     setPage(DevPage.DOCUMENTS)
                                 }}>
                                <IoStatsChartSharp />
                                <h1>Documents</h1>
                            </div>}
                    </div>
                    <div className="application-page">
                        {page === DevPage.STATS && <DevApplicationStats
                            agent={agent ?? undefined}
                        />}
                        {page === DevPage.SETTINGS && <Settings
                            agent={agent ?? undefined}
                            setAgent={setAgent}
                            type={agent ? agent.agentType : ''}
                            sendAlert={sendAlert}
                        />}
                        {page === DevPage.PROMPTS && <Prompts
                            agent={agent}
                            setAgent={setAgent}
                            type={agent ? agent.agentType : ''}
                        />}
                        {page === DevPage.DOCUMENTS && <Documents
                            agent={agent ?? undefined}
                            sendAlert={sendAlert}
                        />}
                    </div>
                    {/*{agent && (*/}
                    {/*    <div className="informationPanel">*/}
                    {/*        {agent?.logoUrl &&*/}
                    {/*            <FaRobot className="informationPanel-logo informationPanel-logo-default"*/}
                    {/*                     style={{ color: agent?.logoColorHex }} />}*/}
                    {/*        <h1>{agent?.name}</h1>*/}
                    {/*        {*/}
                    {/*            summarizer ? (*/}
                    {/*                    globalState.user?.favouriteSummarizers.map((e: any) => e.id).includes(agent?.id) ?*/}
                    {/*                        <AiFillStar className="informationPanel-favourite"*/}
                    {/*                                    onClick={handleFavClick} /> :*/}
                    {/*                        <AiOutlineStar*/}
                    {/*                            className="informationPanel-favourite informationPanel-favourite-outline"*/}
                    {/*                            onClick={handleFavClick} />) :*/}
                    {/*                (*/}
                    {/*                    globalState.user?.favouriteExperts.map((e: any) => e.id).includes(agent?.id) ?*/}
                    {/*                        <AiFillStar className="informationPanel-favourite"*/}
                    {/*                                    onClick={handleFavClick} /> :*/}
                    {/*                        <AiOutlineStar*/}
                    {/*                            className="informationPanel-favourite informationPanel-favourite-outline"*/}
                    {/*                            onClick={handleFavClick} />*/}
                    {/*                )*/}
                    {/*        }*/}
                    {/*        {*/}
                    {/*            summarizer ? (*/}
                    {/*                    globalState.user?.defaultSummarizer === agent?.id ?*/}
                    {/*                        <AiOutlineCheckSquare*/}
                    {/*                            className="informationPanel-default informationPanel-default-check"*/}
                    {/*                            onClick={handleDefaultClick} /> :*/}
                    {/*                        <AiOutlineCheckSquare className="informationPanel-default"*/}
                    {/*                                              onClick={handleDefaultClick} />) :*/}
                    {/*                (*/}
                    {/*                    globalState.user?.defaultExperts.map((e: any) => e.id).includes(agent?.id) ?*/}
                    {/*                        <AiOutlineCheckSquare*/}
                    {/*                            className="informationPanel-default informationPanel-default-check"*/}
                    {/*                            onClick={handleDefaultClick} /> :*/}
                    {/*                        <AiOutlineCheckSquare className="informationPanel-default"*/}
                    {/*                                              onClick={handleDefaultClick} />*/}
                    {/*                )*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {/*<div className="application-categories">*/}
                    {/*    {agent?.tags.map((category: string, index: number) => (*/}
                    {/*        <div className="application-categories-item" key={index}>*/}
                    {/*            <h2>{category}</h2>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                    {/*<div className="application-description">*/}
                    {/*    <div className="application-description-description">*/}
                    {/*        <p>{agent?.description}</p>*/}
                    {/*    </div>*/}
                    {/*    <div className="application-description-author">*/}
                    {/*        <div className="application-description-author-card"*/}
                    {/*             onClick={() => navigate('/developer/' + author?.id)}>*/}
                    {/*            {author?.img ? <img src={author?.img} alt="author" /> :*/}
                    {/*                <AiOutlineUser className="application-description-author-card-icon" />}*/}
                    {/*            <p>{author?.name} {author?.surname}</p>*/}
                    {/*            <div className="application-description-author-card-link"*/}
                    {/*                 onClick={() => navigate('/developer/' + author?.id)}*/}
                    {/*            >View profile*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="application-chat" onClick={() => {*/}
                    {/*    if (summarizer)*/}
                    {/*        redirect('chat', '?new&summarizer=' + agent?.id)*/}
                    {/*    else*/}
                    {/*        redirect('chat', '?new&expert=' + agent?.id)*/}
                    {/*}}>*/}
                    {/*    Try it now!*/}
                    {/*</div>*/}
                </div>}

        </div>
    )
}

export default Application

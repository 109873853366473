import React from 'react'
import './Blobs.scss'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'

export default function Blobs() {
    return (
        <div className="blobs">
            <AnimatedBlob number={5} top={'35vh'} left={'43vw'} scale={12} rotate={'45deg'} animated={true} />
            <AnimatedBlob number={11} top={'180vh'} right={'40vh'} scale={5} rotate={'90deg'} animated={false} />
            <AnimatedBlob number={13} top={'270vh'} left={'20vw'} scale={5} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={4} top={'340vh'} right={'20vw'} scale={5} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={13} top={'430vh'} left={'-10vh'} scale={7} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={14} top={'510vh'} right={'20vh'} scale={7} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={4} top={'600vh'} left={'20vh'} scale={7} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={3} top={'690vh'} right={'10vh'} scale={4} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={2} top={'790vh'} left={'10vh'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={8} top={'890vh'} right={'30vh'} scale={7} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={5} top={'1030vh'} right={'10vw'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={11} top={'1130vh'} left={'10vw'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={13} top={'1230vh'} right={'10vw'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={4} top={'1330vh'} left={'10vw'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={13} top={'1430vh'} right={'10vw'} scale={6} rotate={'45deg'} animated={false} />
            <AnimatedBlob number={14} top={'1530vh'} left={'10vw'} scale={6} rotate={'45deg'} animated={false} />
        </div>
    )
}

import './MeetAppiPlatform.scss'
import { FaTableList } from 'react-icons/fa6'
import { TbPlusEqual } from 'react-icons/tb'
import { BsGraphUp } from 'react-icons/bs'

interface MeetAppiPlatformInterface {
    hovered?: boolean
}

const leftImages = [
    {
        'img': require('../../../../../Images/Assets/Icons/claude.png'),
        'text': 'Claude',
    },
    {
        'img': require('../../../../../Images/Assets/Icons/mistral.png'),
        'text': 'Mistral',
    },
    {
        'img': require('../../../../../Images/Assets/Icons/gpt.png'),
        'text': 'Gpt',
    },
]

const rightImages = [
    {
        'img': <FaTableList />,
        'text': 'Tables',
    },
    {
        'img': <TbPlusEqual />,
        'text': 'Formulas',
    },
    {
        'img': <BsGraphUp />,
        'text': 'Graphs',
    },
]


export const MeetAppiPlatform = ({ hovered }: MeetAppiPlatformInterface) => {

    return (
        <div className="staticListPoint-children">
            <div className={hovered ? 'meetAppiPlatform meetAppiPlatform-hovered' : 'meetAppiPlatform'}>

                {leftImages.map((item, i) => (
                    <div key={i} className="meetAppiPlatform-sideImage" style={!hovered ?
                        { transform: `translateX(-${(i + 1) * 8}rem)` }
                        :
                        {}
                    }>
                        <img src={item.img} alt="Meet Appi Platform" />
                        <p>{item.text}</p>
                    </div>
                ))}
                <img src={require('../../../../../Images/Logo/APPI_icon_white.png')} alt="Meet Appi Platform"
                     className="meetAppiPlatform-mainImage" />
                {rightImages.map((img, i) => (
                    <div key={i} className="meetAppiPlatform-sideImage" style={!hovered ?
                        { transform: `translateX(${(i + 1) * 8}rem)` }
                        :
                        {}
                    }>
                        {img.img}
                        <p>{img.text}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
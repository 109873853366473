import './FakeChat.scss'
import { AiOutlineWarning } from 'react-icons/ai'
import useRedirect from '../../../Utils/redirect'

const background = require('../../../Images/Assets/FakeChat/img.png')
export const FakeChat = () => {
    const redirect = useRedirect()
    return (
        <div className='fakeChat' style={{ backgroundImage: `url(${background})` }}>
            <div className='fakeChat-navbar' />
            <div className='fakeChat-modal'>
                <AiOutlineWarning className='fakeChat-modal-icon' />
                <h1 className='fakeChat-modal-header'>It looks like you are not logged in</h1>
                <button className='fakeChat-modal-login' onClick={() => redirect('', 'login')}>
                    Log in
                </button>
            </div>
        </div>
    )
}
import './Newsletter.scss'
import { Title } from '../../../../Components/Title/Title'
import useRedirect from '../../../../Utils/redirect'
import { AlertSeverity, AlertType } from '../../../../Interfaces/alertsInterfaces'
import { useRef } from 'react'

const appi_img = require('../../../../Images/Logo/APPI_icon.png')

interface NewsletterInterface {
    sendAlert: Function
    formOnly?: boolean
}

export const Newsletter = ({ sendAlert, formOnly }: NewsletterInterface) => {
    const redirect = useRedirect()
    const emailRef = useRef<HTMLInputElement>(null)

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!emailRef.current || emailRef.current?.value === '') return

        const apiKey = process.env.REACT_APP_MAILERLITE
        const groupId = process.env.REACT_APP_MAILERLITE_NEWSLETTER_GROUP_ID

        const response = await fetch(`https://connect.mailerlite.com/api/subscribers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            },
            body: JSON.stringify({
                email: emailRef.current?.value,
                groups: [groupId],
            }),
        })
        console.log(response)
        if (response.status === 422) {
            sendAlert(AlertType.UNKNOWN, 'Wrong email format', AlertSeverity.ERROR)
        }
        if (response.status === 400) {
            sendAlert(AlertType.UNKNOWN, 'Email already exists', AlertSeverity.ERROR)
        } else if (response.status / 100 === 2) {
            sendAlert(AlertType.SUCCESS, 'Thank you for signing up!', AlertSeverity.SUCCESS)
            emailRef.current.value = ''
        }
    }

    return (
        <div className="landingSubpage newsletter">
            {formOnly === false && <Title
                title="Ready to"
                highlight="get started?"
                imgPosition="top"
                titleImg={() => <img src={appi_img} alt="newsletter" />}
            />}
            <div className="newsletter-form">
                <input type="email" placeholder="Your e-mail" ref={emailRef} />
                <button onClick={(e) => handleSubmit(e)}>Join waitlist</button>
            </div>
            {formOnly === false && <div className="newsletter-divider" />}
            {formOnly === false && <div className="newsletter-intents">
                <h1>We are looking for early-stage partners</h1>
                <h2>Learn more about our offer</h2>
                <button onClick={() => redirect('app', 'early-partners')}>Here</button>
            </div>}
        </div>
    )
}
import './Modal.scss'
import { useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

export const ConfirmModal = ({ title, description, close, proceed }: {
    title: any,
    description: string,
    close: Function,
    proceed: Function
}) => {

    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="modal" onClick={() => close()}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h1 className="modal-content-title">{title}</h1>
                <p className="modal-content-description">{description}</p>
                <div className="modal-content-buttons">
                    {!loading && <div className="modal-content-buttons-cancel" onClick={() => close()}>Cancel</div>}
                    {!loading && <div className="modal-content-buttons-proceed" onClick={
                        () => {
                            setLoading(true)
                            proceed()
                        }}>Proceed
                    </div>}
                    {loading && <AiOutlineLoading className="modal-content-buttons-loading" />}
                </div>
            </div>
        </div>
    )
}

interface ModalInterface {
    children: any
    close: Function
    className?: string
    size?: string
}

export const Modal = ({ children, close, className, size = 'big' }: ModalInterface) => {
    return (
        <div className={'modal ' + className} onClick={() => close()}>
            <div className={'modal-content modal-content-' + size} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}
import { useEffect, useRef, useState } from 'react'

interface SearchBarInterface {
    id: string,
    agents_list: any,
    setAgents_list_filtered: any,
}

export const SearchBar = ({
                              id,
                              agents_list,
                              setAgents_list_filtered,
                          }: SearchBarInterface) => {
    const [search, setSearch] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (search.length > 2) {
            setAgents_list_filtered(agents_list.filter((agent: any) => agent.name.toLowerCase().includes(search.toLowerCase())))
        } else {
            setAgents_list_filtered(null)
        }
    }, [search])


    return (
        <div className='chat-sidebar-searchbar' id={id}>
            <input ref={inputRef} type='text' placeholder='Search'
                   onChange={() => setSearch(inputRef.current!.value)} />
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { OrganizationInterface } from '../../../Interfaces/organizationInterfaces'
import { getMyOrganizations } from '../../../Requests/organizationsRequests'
import { useGlobalState } from '../../../GlobalStateContext'
import { useNavigate } from 'react-router-dom'
import './OrganizationSelector.scss'
import { AiOutlineLoading, AiOutlinePlusSquare } from 'react-icons/ai'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'

export const OrganizationSelector = () => {
    const navigate = useNavigate()
    const [organizations, setOrganizations] = useState<OrganizationInterface[]>([])
    const defaultOrganization: OrganizationInterface = {
        id: '',
        name: 'None',
        contactEmail: '',
        members: [],
        //organizationOwnerId: '',
        assignedExpertsIds: [],
    }
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInterface>(defaultOrganization)
    const [globalState, globalActions] = useGlobalState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (globalState.user) {
            getMyOrganizations().then((res) => {
                setLoading(false)
                setOrganizations(res)
                if (res.length === 1) {
                    setSelectedOrganization(res[0])
                }
            })
        }
    }, [globalState.user])

    useEffect(() => {
        if (selectedOrganization)
            navigate(`/organization/${selectedOrganization.id}`)
    }, [selectedOrganization])
    return (
        <div className='organization-selector'>
            <FreeBlobEnv width={'100%'} height={'100%'} min={40} max={20} />
            <div className='organization-selector-title'>
                <h1>Select an organization</h1>
                <AiOutlinePlusSquare className='organization-selector-title-new'
                                     onClick={() => navigate('/organization/create')} />
            </div>
            {!loading && <div className='organization-selector-items'>
                {organizations.map((organization) => (
                    <div className='organization-selector-item' onClick={() => setSelectedOrganization(organization)}>
                        <h1>{organization.name}</h1>
                    </div>
                ))}
            </div>}
            {loading && <AiOutlineLoading className='organization-selector-loading' />}

        </div>
    )
}
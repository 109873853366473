import { ConversationInterface, MessageInterface, MessageInterfaceApi } from '../../../Interfaces/messageInterfaces'
import { getConversation } from '../../../Requests/conversationRequests'
import React from 'react'
import fetchMessages from './fetchMessages'

const fetchFullConversation = async (
    response: null | ConversationInterface = null,
    setLoadingConversation: React.Dispatch<React.SetStateAction<boolean>>,
    selectedConversation: ConversationInterface | null,
    messages: MessageInterface[],
    setMessages: React.Dispatch<React.SetStateAction<MessageInterface[]>>,
) => {
    let response_messages: string | any[] = []
    const fetchFullConversationLogin = async (response: ConversationInterface) => {
        // if (response.customUserInstructionDto) {
        //     const id = response.customUserInstructionDto.id
        //     const customInstruction: CustomInstruction | undefined = customProfiles.find(item => item.id === id)
        //     // @ts-ignore
        //     setSelectedCustomInstruction(customInstruction)
        // } else {
        //     setSelectedCustomInstruction(defaultInstruction)
        // }
        // @ts-ignore
        response_messages = response.message.sort((a: MessageInterfaceApi, b: MessageInterfaceApi) => new Date(a.createdAt) - new Date(b.createdAt))
        await fetchMessages(response_messages, messages, setMessages)
        setLoadingConversation(false)
    }
    if (selectedConversation) {
        if (!response)
            getConversation(selectedConversation.id).then(async (response: ConversationInterface) => {
                await fetchFullConversationLogin(response)
            })
        else {
            await fetchFullConversationLogin(response)
        }
    }
}

export default fetchFullConversation
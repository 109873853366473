import { useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { ChunkInterface, ChunkType } from '../Message/MessageInterface'
import { GenerationStage, useChatContext } from '../ChatPropsManager'
import {
    ExpertAnswerInterface,
    MessagePartType,
    MessageType,
    ResponseInterface,
} from '../../../Interfaces/messageInterfaces'

const useStreamResponse = () => {
    const [chunks, setChunks] = useState([])
    const [error, setError] = useState(null)
    const chunksRef = useRef([])
    const batchSize = 10

    const {
        selectedConversation,
        setGenerationStage,
        generationStage,
        chosenMessages,
    } = useChatContext()


    const processChunk = (
        chunk: string,
        last_message_id: string,
        name: string,
        agentId: string,
    ) => {
        let chunkParsed: ChunkInterface
        try {
            chunkParsed = JSON.parse(chunk)
        } catch (e) {
            return
        }
        chunkParsed['lastMessageId'] = last_message_id
        chunkParsed['name'] = name
        chunkParsed['agentId'] = agentId
        chunkParsed['conversationId'] = selectedConversation ? selectedConversation.id : ''
        console.log(chunkParsed)
        let target_message = null
        switch (chunkParsed.chunk.Type) {
            case ChunkType.CONTEXT:
                target_message = chosenMessages.find(
                    (message) => message.id === chunkParsed.lastMessageId,
                )
                if (target_message) {
                    const target_submessage = target_message.agentAnswers.find(
                        (submessage) => submessage.id === chunkParsed.agentAnswerId,
                    )
                    if (target_submessage) {
                        target_submessage.context = JSON.parse(chunkParsed.chunk.Content)
                    }
                }
                break
            case ChunkType.RESPONSE:
                target_message = chosenMessages.find(
                    (message) => message.id === chunkParsed.lastMessageId,
                )
                if (target_message) {
                    const target_submessage = target_message.agentAnswers.find(
                        (submessage) => submessage.id === chunkParsed.agentAnswerId,
                    )
                    if (target_submessage) {
                        const target_response = target_submessage.response.find(
                            (response) => response.messagePartType === MessagePartType.TEXT,
                        )
                        if (target_response) {
                            target_response.text += chunkParsed.chunk.Content
                        }
                    }
                }
                break
            case
            ChunkType.STATUS
            :
                const content = chunkParsed.chunk.Content
                let stage: GenerationStage = GenerationStage.NONE
                switch (content) {
                    case 'PROCESSING_PROMPT':
                        stage = GenerationStage.PROCESSING_PROMPT
                        break
                    case 'RETRIEVING_CONTEXT':
                        stage = GenerationStage.RETRIEVING_CONTEXT
                        break
                    case 'COMBINING_ANSWERS':
                        stage = GenerationStage.COMBINING_RESPONSES
                        break
                    case 'VALIDATING_RESPONSE':
                        stage = GenerationStage.VALIDATING_RESPONSE
                        break
                    case 'STREAMING_RESPONSE':
                        stage = GenerationStage.STREAMING_RESPONSE
                        break
                    case 'AGENT_ANSWER_ID':
                        const new_response: ResponseInterface = {
                            id: '0',
                            text: '',
                            messagePartContent_TextAlt: '',
                            messagePartType: MessagePartType.TEXT,
                            senderId: agentId,
                        }
                        const new_submessage: ExpertAnswerInterface = {
                            id: chunkParsed.agentAnswerId,
                            response: [new_response],
                            expertResponding: {
                                name: chunkParsed.name,
                                id: agentId,
                                authorId: '',
                                logoColorHex: '',
                                logoUrl: '',
                                shortDescription: '',
                                description: '',
                                tags: [],
                                usageInstruction: '',
                                isPublic: false,
                                suggestedAIModel: '',
                                documentDisplayPermission: 0,
                                agentType: '',
                            },
                            createdAt: new Date(),
                            agentAnswerType: MessageType.EXPERT_ANSWER,
                            chosen: false,
                            chosenFinal: false,
                            finished: false,
                            chunk: 0,
                            conversationId: last_message_id,
                            context: undefined,
                        }
                        target_message = chosenMessages.find(
                            (message) => message.id === chunkParsed.lastMessageId,
                        )
                        if (target_message) {
                            target_message.agentAnswers.push(new_submessage)
                        }

                }
                setGenerationStage((prev) => ({
                    ...prev,
                    [chunkParsed.lastMessageId + '_' + chunkParsed.agentId]: stage,
                }))
                break
        }
    }

    const execute = async (
        URL: string,
        expertId: string,
        lastMessageId: string,
        name: string,
    ) => {
        const requestUrl =
            process.env.REACT_APP_API_URL +
            'StreamingResponse/' +
            URL +
            '?expertId=' +
            expertId +
            '&userMessageId=' +
            lastMessageId

        try {
            const response = await fetch(requestUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + Cookies.get('jwt'),
                },
                body: '{}', // Empty body can be modified later
            })

            if (!response.ok) {
                throw new Error(
                    'There was an issue with streaming response. Please try again later.',
                )
            }

            const reader = response.body?.getReader()
            const decoder = new TextDecoder('utf-8')
            if (reader) {
                let iter = 0
                const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

                while (iter < 2) {
                    try {
                        while (true) {
                            const { done, value } = await reader.read()
                            if (done) break

                            if (value) {
                                const decodedChunk = decoder.decode(value, { stream: true })
                                const chunksSplit = decodedChunk.split('\n')

                                chunksSplit.forEach((chunk) => {
                                    if (chunk !== '') {
                                        processChunk(chunk, lastMessageId, name, expertId)
                                    }
                                })

                            }
                        }
                    } catch (NSError) {
                    }
                    await delay(400)
                    iter++
                }

                setGenerationStage({})
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    return { chunks, error, execute }
}


export default useStreamResponse

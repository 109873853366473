import { apiDelete, apiGet, apiPost } from '../Utils/RequestsUtil'
import Cookies from 'js-cookie'
import axios from 'axios'

export const startConversation = async (
    prompt: string,
    expertsIds: string[],
    summarizerId: string,
    customInstructionId: string | null = null,
    selectedAIModelId: string | null = null,
    language: string | null = null,
) => {
    const jwt = Cookies.get('jwt')
    let agents_str = expertsIds.join(',')
    if (summarizerId) {
        agents_str += '&' + summarizerId
    }
    const data = {
        'prompt': prompt,
        'expertsIds': expertsIds,
        'selectedAIModelId': selectedAIModelId,
        'summarizerId': summarizerId,
        'customUserInstructionId': customInstructionId,
        'language': language,
        'listOfAgentIds': agents_str,
    }
    return await apiPost(
        'Conversation/StartConversation',
        data,
        false,
        // @ts-ignore
        jwt,
    )
}


export const getConversation = async (conversationId: string) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Conversation/GetFullConversation',
        // @ts-ignore
        {
            'conversationId': conversationId,
        },
        true,
        // @ts-ignore
        jwt,
    )
}


export const deleteConversation = async (conversationId: string) => {
    const jwt = Cookies.get('jwt')
    return await apiDelete(
        'Conversation/DeleteConversation/' + conversationId,
        // @ts-ignore
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const deleteAllConversations = async () => {
    const jwt = Cookies.get('jwt')
    return await apiDelete(
        'Conversation/DeleteAllConversationsOfUser',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const selectAsFinalResponse = async (messageId: string) => {
    console.log('SelectAnswerAsFinalResponse', messageId)
    const jwt = Cookies.get('jwt')
    return await apiPost(
        'Conversation/SelectExpertOrSummarizerAnswerAsFinalResponse',
        // @ts-ignore
        {
            'agentAnswerId': messageId,
        },
        true,
        // @ts-ignore
        jwt,
    )

}

export const SelectExpertsAnswers = async (messageIds: string[]) => {
    const jwt = Cookies.get('jwt')
    return await apiPost(
        'Conversation/SelectAnswerForSummarization',
        // @ts-ignore
        messageIds,
        false,
        // @ts-ignore
        jwt,
    )
}

export const AddNextPrompt = async (
    prompt: string,
    expertIds: string[],
    summarizerId: string | null,
    previousMessageId: string | null,
    conversationId: string | null,
) => {
    console.log('AddNextPrompt', prompt, expertIds, summarizerId, previousMessageId, conversationId)
    if (previousMessageId === null && conversationId === null) {
        throw new Error('Either previousMessageId or conversationId must be provided')
    }
    const jwt = Cookies.get('jwt')
    let agents_str = expertIds.join(',')
    if (summarizerId) {
        agents_str += '&' + summarizerId
    }
    return await apiPost(
        'Conversation/AddNextPrompt',
        // @ts-ignore
        {
            'prompt': prompt,
            'previousMessageId': previousMessageId,
            'listOfAgentIds': agents_str,
            'conversationId': conversationId,
        },
        false,
        // @ts-ignore
        jwt,
    )
}

export const EditPrompt = async (conversationId: string, prompt: string) => {
    const jwt = Cookies.get('jwt')
    return await apiPost(
        'Conversation/UpdatePrompt',
        // @ts-ignore
        {
            'messageId': conversationId,
            'prompt': prompt,
        },
        false,
        // @ts-ignore
        jwt,
    )
}

export const GetContextImage = async (documentId: string, nodeId: string) => {
    const jwt = Cookies.get('jwt')
    const url = 'ExpertDocument/GetDocumentExtractedImage/' + documentId
    const data = {
        'nodeId': nodeId,
    }
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
        'accept': '*/*',
    }
    const response = await axios
        .get(
            process.env.REACT_APP_API_URL + url,
            {
                params: data,
                headers: headers,
                responseType: 'blob',
            },
        )
        .catch((error: any) => {
            throw error
        })
    return await response.data
}
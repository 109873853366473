import './FunToolItem.scss'
import '../../DocumentShowcase/DocumentShowcase.scss'
import { BsCircle, BsCircleFill } from 'react-icons/bs'

interface FunToolItemInterface {
    title: string,
    description: string,
    num: number,
    totalSteps: number;
    children?: any,
}

export const FunToolItem = (props: FunToolItemInterface) => {
    const circles = []

    for (let i = 0; i < props.totalSteps; i++) {
        circles.push(i === props.num - 1 ? <BsCircleFill size={'1rem'} /> : <BsCircle size={'1rem'} />)
    }

    return (
        <div className="funToolItem">
            <div className="documentShowcase-stage">
                <div className="documentShowcase-stage-title">{props.title}</div>
                <div className="documentShowcase-stage-description">{props.description}</div>
            </div>
            <div className="funToolItem-middle">
                {props.children}
            </div>
            <div className="funToolItem-bottom">
                <div className="funToolItem-bottom-stepper">
                    {circles}
                </div>
            </div>
        </div>
    )
}

import './DocumentShowcase.scss'
import './DocumentShowcaseMobile.scss'
import { Title } from '../../../Components/Title/Title'
import { IoDocumentTextOutline } from 'react-icons/io5'
import React, { useEffect, useRef, useState } from 'react'
import { MdDone, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import AgentCard, { IconComponent } from '../../../Components/AgentCard/AgentCard'
import { AiOutlineClose, AiOutlineLoading } from 'react-icons/ai'
import { Parser } from '../../Chat/Message/Parser'
import { FaImage, FaNotEqual, FaTable } from 'react-icons/fa'

export const DocumentShowcase = () => {

    enum ShowcaseStage {
        CHOOSE_DOCUMENT = 1,
        EXTRACTING_KNOWLEDGE,
        GENERATING_RESPONSE,
        FINAL
    }


    const stages_config = {
        [ShowcaseStage.CHOOSE_DOCUMENT]: {
            title: 'Choose a document',
            description: 'Upload a document or a link to a document',
        },
        [ShowcaseStage.EXTRACTING_KNOWLEDGE]: {
            title: 'Extracting knowledge',
            description: 'Extracting knowledge from the document',
        },
        [ShowcaseStage.GENERATING_RESPONSE]: {
            title: 'Generating response',
            description: 'Generating response based on the extracted knowledge',
        },
        [ShowcaseStage.FINAL]: {
            title: 'Final',
            description: 'Final response',
        },
    }

    interface DocumentShowcaseFragment {
        type: string;
        text: string;
    }

    interface DocumentShowcaseItem {
        title: string;
        url: string;
        expert_name: string;
        expert_logo: string
        fragments: DocumentShowcaseFragment[]
        color: string
        question: string
        answer: string
    }

    const documents: DocumentShowcaseItem[] = [
        {
            title: 'Understanding Constitutional Law',
            url: '',
            expert_name: 'Law AI expert',
            expert_logo: 'FaGavel',
            color: '#8758a6',
            question: 'What is judicial review?',
            answer: 'Judicial review is the doctrine under which legislative and executive actions are subject to review by the judiciary.',
            fragments: [
                {
                    type: 'paragraph',
                    text: 'Constitutional law concerns itself with the structure and function of the government according to the Constitution. It defines the different roles of government branches, the rights of individuals, and the interpretation of those rights based on judicial review.',
                },
                {
                    type: 'table',
                    text: 'Judical review cases',
                },
            ],
        },
        {
            title: 'Principles of Microeconomics',
            url: '',
            expert_name: 'Economy AI expert',
            expert_logo: 'FaChartLine',
            color: '#cea25e',
            question: 'How do supply and demand affect market prices?',
            answer: 'Supply and demand determine the price of goods and services. If demand increases and supply remains unchanged, a shortage occurs, leading to higher prices.',
            fragments: [
                {
                    type: 'paragraph',
                    text: 'Microeconomics analyzes basic elements in the economy, including individual agents and markets, their interactions, and the outcomes of interactions. Topics such as demand and supply, market equilibrium, and the influence of government policy and regulations are crucial.',
                },
                {
                    type: 'formula',
                    text: 'Qd = a - bP',
                },
            ],
        },
        {
            title: 'Fundamentals of Civil Engineering',
            url: '',
            expert_name: 'Engineering AI expert',
            expert_logo: 'FaHardHat',
            color: '#be4b7a',
            question: 'What is the importance of structural analysis in civil engineering?',
            answer: 'Structural analysis is crucial as it ensures safety and durability of buildings and infrastructure. It involves analyzing stresses and forces within structures to predict their responses to loads. Below is an overview:\n\n**Key Points of Structural Analysis**\n\n1. **Safety**: Ensures structures can withstand expected loads without failure.\n2. **Durability**: Predicts long-term behavior under various conditions.\n3. **Efficiency**: Optimizes material usage, balancing safety and cost.\n\n### Example of Load Calculation\n\nConsider a simple beam subjected to a uniform load:\n\n$$sigma = \frac{M cdot y}{I}$$\n\nWhere:\n\n- $$sigma$$ is the stress at a distance $$y$$ from the neutral axis,\n- $$M$$ is the moment at the cross-section,\n- $$I$$ is the moment of inertia of the cross-section.\n\n### Structural Material Comparison\n\n| Material  | Strength (MPa) | Density (kg/m³) | Usage                |\n|-----------|----------------|-----------------|---------------------|\n| Steel     | 250 - 550      | 7850            | Beams, Columns       |\n| Concrete  | 20 - 50        | 2400            | Slabs, Foundations   |\n| Timber    | 5 - 50         | 600 - 900       | Trusses, Framing     |\n\n### Conclusion\n\nStructural analysis is a fundamental aspect of civil engineering, ensuring that every structure is safe, durable, and efficient, balancing various materials and design considerations.',
            fragments: [
                {
                    type: 'paragraph',
                    text: 'Civil engineering is a professional engineering discipline that deals with the design, construction, and maintenance of the physical and naturally built environment, including works like roads, bridges, canals, dams, and buildings.',
                },
            ],
        },
    ]

    const checklist_template = [
        { 'title': 'tables', 'done': false },
        { 'title': 'images', 'done': false },
        { 'title': 'text', 'done': false },
        { 'title': 'formulas', 'done': false },
        { 'title': 'references', 'done': false },
    ]

    const chatInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [stage, setStage] = useState<ShowcaseStage>(ShowcaseStage.CHOOSE_DOCUMENT)
    const [selectedDocument, setSelectedDocument] = useState<number | null>(null)
    const [checklist, setChecklist] = useState(checklist_template)
    const [selectedFragment, setSelectedFragment] = useState<number | null>(null)

    interface ChatMessage {
        text: string;
        sender?: string;
        color?: string;
        expert_logo: string;
    }

    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
    useEffect(() => {
        const timeouts: NodeJS.Timeout[] = []

        const clearAllTimeouts = () => timeouts.forEach(timeout => clearTimeout(timeout))

        if (stage === ShowcaseStage.EXTRACTING_KNOWLEDGE) {
            for (let i = 0; i < checklist.length; i++) {
                setTimeout(() => {
                    const newChecklist = [...checklist]
                    newChecklist[i].done = true
                    setChecklist(newChecklist)
                }, 700 * (i + 1))

            }
            setTimeout(() => {
                setStage(ShowcaseStage.GENERATING_RESPONSE)
            }, 700 * (checklist.length + 1))
        }

        const simulateTyping = async () => {
            setChatMessages([])
            const inspector = document.querySelector('.documentShowcase-content-generating-inspector')
            if (inspector === null)
                throw new Error('Inspector not found')
            // inspector.classList.remove('active')
            // setSelectedFragment(null)
            const text = documents[selectedDocument as number].question
            const textArray = text.split('')

            for (let i = 0; i < textArray.length; i++) {
                await new Promise<void>((resolve) => {
                    timeouts.push(setTimeout(() => {
                        if (chatInputRef.current) chatInputRef.current.value += textArray[i]
                        resolve()
                    }, 8))
                })
            }

            await new Promise<void>((resolve) => {
                timeouts.push(setTimeout(() => {
                    if (chatInputRef.current) chatInputRef.current.value = ''
                    setChatMessages(msgs => [...msgs, {
                        text,
                        sender: 'User',
                        color: '#4A90E2',
                        expert_logo: documents[selectedDocument as number].expert_logo,
                    }])
                    resolve()
                }, 1000))
            })


            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    inspector.classList.add('active')
                    setChatMessages(msgs => [...msgs, {
                        text: 'Running retrieval',
                        sender: documents[selectedDocument as number].expert_name,
                        color: documents[selectedDocument as number].color,
                        expert_logo: documents[selectedDocument as number].expert_logo,
                    }])
                    resolve()
                }, 1000)
            })

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve()
                }, 1500)
            })

            const answer = documents[selectedDocument as number].answer
            const answerArray = answer.split('')
            setChatMessages(msgs => {
                const newMessages = [...msgs]
                newMessages[newMessages.length - 1].text = ''
                return newMessages
            })
            for (let i = 0; i < answerArray.length; i++) {
                await new Promise<void>((resolve) => {
                    timeouts.push(setTimeout(() => {
                        setChatMessages(msgs => {
                            const newMessages = [...msgs]
                            newMessages[newMessages.length - 1].text += answerArray[i]
                            return newMessages
                        })
                        resolve()
                    }, 5))
                })
            }

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve()
                }, 1000)
            })

        }

        if (stage === ShowcaseStage.GENERATING_RESPONSE) {
            simulateTyping()
        }

        return clearAllTimeouts
    }, [stage],
    )

    console.log(selectedFragment)

    return (
        <div className="landingSubpage documentShowcase">
            <Title
                title="State of the art"
                imgPosition={'left'}
                newlineHighlight={'document processing'}
                titleImg={() => <IoDocumentTextOutline />}
            />
            <div className="documentShowcase-wrapper" id={'stage_' + stage}>
                <div className="documentShowcase-stage">
                    <div className="documentShowcase-stage-title">{stages_config[stage].title}</div>
                    <div className="documentShowcase-stage-description">{stages_config[stage].description}</div>
                </div>
                <div className="documentShowcase-content">
                    {stage === ShowcaseStage.CHOOSE_DOCUMENT && (
                        <div className="documentShowcase-content-documents">
                            {documents.map((document, index) => (
                                <div
                                    key={index}
                                    className="documentShowcase-content-documents-document"
                                    onClick={() => {
                                        setSelectedDocument(index)
                                        setStage(ShowcaseStage.EXTRACTING_KNOWLEDGE)
                                    }}
                                >
                                    <IoDocumentTextOutline style={{ color: document.color }} />
                                    <div
                                        className="documentShowcase-content-documents-document-title">{document.title}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {stage === ShowcaseStage.EXTRACTING_KNOWLEDGE && (
                        <div className="documentShowcase-content-extracting">
                            <div className="documentShowcase-content-extracting-selectedDocument">
                                <div className="documentShowcase-content-extracting-selectedDocument-title">
                                    {documents[selectedDocument as number].title}
                                </div>
                            </div>
                            <div className="documentShowcase-content-extracting-progress">
                                <MdOutlineKeyboardArrowRight
                                    className="documentShowcase-content-extracting-arrow" />
                                <div className="documentShowcase-content-extracting-checklist">
                                    {checklist.map((item, index) => (
                                        <div
                                            key={index}
                                            className="documentShowcase-content-extracting-checklist-item"
                                        >
                                            <div
                                                className="documentShowcase-content-extracting-checklist-item-title">{item.title}</div>
                                            {item.done ?
                                                <MdDone />
                                                :
                                                <AiOutlineLoading id="loading" />
                                            }

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <AgentCard
                                agent={{
                                    name: selectedDocument !== null ? documents[selectedDocument].expert_name : '',
                                    shortDescription: 'Extracting knowledge from the document',
                                    tags: [],
                                    logoColorHex: '#CE8B09',
                                    authorId: '',
                                    logoUrl: selectedDocument !== null ? documents[selectedDocument].expert_logo : '',
                                    description: '',
                                    usageInstruction: '',
                                    id: '',
                                    isPublic: true,
                                    suggestedAIModel: '',
                                    documentDisplayPermission: 0,
                                    agentType: '',
                                }}
                                description={true}
                                onClickRedirect={false}
                                onClick={() => {
                                }}
                            />
                        </div>
                    )}
                    {stage === ShowcaseStage.GENERATING_RESPONSE && (
                        <div className="documentShowcase-content-generating">
                            <div className="documentShowcase-content-generating-sidebar">
                                <h1>Experts</h1>
                                {documents.map((document, index) => (
                                    <AgentCard
                                        key={index}
                                        agent={{
                                            name: document.expert_name,
                                            shortDescription: 'Generating response based on the extracted knowledge',
                                            tags: [],
                                            logoColorHex: '#CE8B09',
                                            authorId: '',
                                            logoUrl: document.expert_logo,
                                            description: '',
                                            usageInstruction: '',
                                            id: '',
                                            isPublic: true,
                                            suggestedAIModel: '',
                                            documentDisplayPermission: 0,
                                            agentType: '',
                                        }}
                                        description={false}
                                        onClickRedirect={false}
                                        onClick={() => {
                                            setSelectedDocument(index)
                                            setChecklist(checklist_template)
                                            setStage(ShowcaseStage.EXTRACTING_KNOWLEDGE)
                                        }}
                                        id={selectedDocument as number === index ? 'expert_selected' : ''}
                                    />
                                ))}
                            </div>
                            <div className="documentShowcase-content-generating-main">
                                <div className="documentShowcase-content-generating-main-chat">
                                    {chatMessages.map((message, index) => (
                                        // <div
                                        //     key={index}
                                        //     className="documentShowcase-content-generating-main-chat-message"
                                        // >
                                        //     <span
                                        //         className="documentShowcase-content-generating-main-chat-message-author">
                                        //         AI Expert:
                                        //     </span>
                                        //     <Parser handleCopy={() => {
                                        //     }}>{message.text}</Parser>
                                        // </div>
                                        <div className="message">
                                            <div className="message-innerWrapper">
                                                <div className="message-icon-wrapper">
                                                    <IconComponent
                                                        icon={index === 0 ? 'AiOutlineUser' : message.expert_logo}
                                                        className={'message-icon'} />
                                                    <div className="message-icon-sender">{message.sender}</div>
                                                </div>
                                                <div className="message-text-wrapper">
                                                    <div className="message-text">
                                                        <Parser handleCopy={() => {
                                                        }}>{message.text}</Parser>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                                <div className="documentShowcase-content-generating-main-input">
                                    <input type="text" placeholder="" ref={chatInputRef} disabled={true} />
                                </div>
                            </div>
                            <div className="documentShowcase-content-generating-inspector"
                                id={selectedFragment !== null ? 'focus' : ''}>
                                {selectedFragment === null && <h1>Document fragments</h1>}
                                {(selectedFragment !== null && selectedDocument !== null) &&
                                    <AiOutlineClose className="documentShowcase-content-generating-inspector-close"
                                        onClick={() => {
                                            setSelectedFragment(null)
                                        }}
                                    />
                                }

                                {(selectedFragment !== null && selectedDocument !== null) &&
                                    <h1>{documents[selectedDocument].fragments[selectedFragment].type.toUpperCase()}</h1>
                                }
                                {(selectedFragment !== null && selectedDocument !== null) &&
                                    <div className="documentShowcase-content-generating-inspector-document">
                                        <p>{inwokacja}</p>
                                        <p>{milijon}</p>
                                        <div
                                            className="documentShowcase-content-generating-inspector-document-selected">
                                            {documents[selectedDocument].fragments[selectedFragment].type === 'table' && (
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    {[0, 1, 2, 3, 4].map((item, index) => (
                                                        <tr key={index}>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </table>

                                            )}
                                            {documents[selectedDocument].fragments[selectedFragment].type === 'image' && (
                                                <img src="https://via.placeholder.com/150" alt="placeholder" />
                                            )}
                                            {documents[selectedDocument].fragments[selectedFragment].type === 'paragraph' && (
                                                <h2>{documents[selectedDocument].fragments[selectedFragment].text}</h2>
                                            )}
                                        </div>
                                        <p>{farewell}</p>
                                    </div>
                                }
                                {(selectedDocument !== null && selectedFragment === null) && documents[selectedDocument].fragments.map((fragment, index) => (
                                    <div
                                        key={index}
                                        className="documentShowcase-content-generating-inspector-fragment"
                                        onClick={() => {
                                            setSelectedFragment(index)
                                        }}
                                    >
                                        {fragment.type === 'paragraph' && (
                                            <div
                                                className="documentShowcase-content-generating-inspector-fragment-text">
                                                {fragment.text.slice(0, 50)}...
                                            </div>
                                        )}
                                        {fragment.type === 'image' && (
                                            <div
                                                className="documentShowcase-content-generating-inspector-fragment-image">
                                                <FaImage />
                                            </div>
                                        )}
                                        {fragment.type === 'table' && (
                                            <div
                                                className="documentShowcase-content-generating-inspector-fragment-table">
                                                <FaTable />
                                                {fragment.text}
                                            </div>
                                        )}
                                        {fragment.type === 'formula' && (
                                            <div
                                                className="documentShowcase-content-generating-inspector-fragment-formula">
                                                <FaNotEqual />
                                                {fragment.text}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}

const inwokacja = 'Litwo! Ojczyzno moja! ty jesteś jak zdrowie. Ile cię trzeba cenić, ten tylko się dowie, Kto cię stracił. Dziś piękność twą w całej ozdobie Widzę i opisuję, bo tęsknię po tobie. Panno Święta, co Jasnej bronisz Częstochowy I w Ostrej świecisz Bramie! Ty, co gród zamkowy Nowogródzki ochraniasz z jego wiernym ludem! Jak mnie dziecko do zdrowia powróciłaś cudem(Gdy od płaczącej matki pod Twoję opiekę Ofiarowany, martwą podniosłem powiekę I zaraz mogłem pieszo do Twych świątyń progu Iść za wrócone życie podziękować Bogu), Tak nas powrócisz cudem na Ojczyzny łono.'
const milijon = 'LNazywam się Milijon – bo za milijony Kocham i cierpię katusze. Patrzę na ojczyznę biedną, jak na trupa; Z głową, związaną w cierniową koronę, Z otwartą piersią, w której serce bije.'
const farewell = 'Żegnam panią na zawsze. Proszę wybaczyć, że ośmieliłem się podnieść oczy na panią... Zapominam o wszystkim, co było między nami, i o tej chwili, w której popełniłem największe głupstwo życia'

import { useEffect, useReducer } from 'react'

import { ChunkInterface, StreamInterface } from './Message/MessageInterface'

type StreamHandlerAction =
    | { type: 'ADD_CHUNK'; payload: ChunkInterface }
    | { type: 'FLUSH_QUEUE' }
    | { type: 'RESET_LAST_NUMBER_CHUNKS' }
    | { type: 'ADD_CONTEXT'; payload: any };

interface StreamHandlerState {
    conversations: StreamInterface;
    chunksMap: Map<string, Map<number, ChunkInterface>>;
    lastChunkNumber: { [targetMessageId: string]: number };
    contexts: { [contextId: string]: any };
}

const initialState: StreamHandlerState = {
    conversations: {},
    chunksMap: new Map(),
    lastChunkNumber: {},
    contexts: {},
}

function streamHandlerReducer(state: StreamHandlerState, action: StreamHandlerAction): StreamHandlerState {
    switch (action.type) {
        case 'ADD_CHUNK': {
            const { payload: chunk } = action
            const messageId = chunk.conversationId + '_' + chunk.lastMessageId + '_' + chunk.agentAnswerId + '_' + chunk.agentId
            const updatedChunksSet = new Map(state.chunksMap)
            if (!updatedChunksSet.has(messageId)) {
                updatedChunksSet.set(messageId, new Map())
            }
            updatedChunksSet.get(messageId)?.set(chunk.responseChunkNumber, chunk)
            return { ...state, chunksMap: updatedChunksSet }
        }

        case 'ADD_CONTEXT': {
            const { payload: context } = action
            const parsedContext = JSON.parse(context.chunk.Content)
            const updatedContexts = { ...state.contexts }
            // @ts-ignore
            const agent_id = context.agentId
            updatedContexts[agent_id] = parsedContext
            return { ...state, contexts: updatedContexts }
        }

        case 'FLUSH_QUEUE': {
            const updatedConversations = { ...state.conversations }
            state.chunksMap.forEach((chunks, messageId) => {
                let currentChunkNumber = state.lastChunkNumber[messageId] ? state.lastChunkNumber[messageId] + 1 : 0
                while (chunks.has(currentChunkNumber)) {
                    const chunk = chunks.get(currentChunkNumber)
                    if (!chunk) {
                        return
                    }
                    if (updatedConversations[messageId]) {
                        updatedConversations[messageId].chunk.Content += chunk.chunk.Content
                        updatedConversations[messageId].responseChunkNumber = currentChunkNumber
                    } else {
                        updatedConversations[messageId] = { ...chunk, responseChunkNumber: currentChunkNumber }
                    }
                    chunks.delete(currentChunkNumber)
                    state.lastChunkNumber[messageId] = currentChunkNumber
                    currentChunkNumber += 1
                }
                if (currentChunkNumber === 0 && chunks.has(1)) {
                    currentChunkNumber = 1
                    while (chunks.has(currentChunkNumber)) {
                        const chunk = chunks.get(currentChunkNumber)
                        if (!chunk) {
                            return
                        }
                        if (updatedConversations[messageId]) {
                            updatedConversations[messageId].chunk.Content += chunk.chunk.Content
                            updatedConversations[messageId].responseChunkNumber = currentChunkNumber
                        } else {
                            updatedConversations[messageId] = { ...chunk, responseChunkNumber: currentChunkNumber }
                        }
                        chunks.delete(currentChunkNumber)
                        state.lastChunkNumber[messageId] = currentChunkNumber
                        currentChunkNumber += 1
                    }
                }
            })
            return { ...state, conversations: updatedConversations }
        }
        case 'RESET_LAST_NUMBER_CHUNKS': {
            return { ...state, conversations: {}, lastChunkNumber: {}, chunksMap: new Map() }
        }
        default:
            return state
    }
}

export function useStreamHandler() {
    const [state, dispatch] = useReducer(streamHandlerReducer, initialState)

    const addChunk = (chunk: ChunkInterface) => {
        dispatch({ type: 'ADD_CHUNK', payload: chunk })
    }

    const addContext = (context: any) => {
        dispatch({ type: 'ADD_CONTEXT', payload: context })
    }

    const resetLastNumberChunks = () => {
        dispatch({ type: 'RESET_LAST_NUMBER_CHUNKS' })
    }

    const flush = () => {
        dispatch({ type: 'FLUSH_QUEUE' })
    }

    useEffect(() => {
        const sum = Array.from(state.chunksMap.values()).reduce((acc, chunks) => acc + chunks.size, 0)
        if (sum > 10) {
            dispatch({ type: 'FLUSH_QUEUE' })
        }
    }, [state.chunksMap])

    const getConversation = (id: string) => {
        return state.conversations[id]
    }

    return {
        addChunk,
        addContext,
        getConversation,
        conversations: state.conversations,
        contexts: state.contexts,
        resetLastNumberChunks,
        flush,
    }
}

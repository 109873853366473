import React, { useEffect, useState } from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { CallsInterface } from '../Subpages/Stats/Stats'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)
const BarChart = ({ data, barChartConfig, setBarChartConfig }: {
    data: CallsInterface[],
    barChartConfig: any,
    setBarChartConfig: any
}) => {

    const [preparedData, setPreparedData] = useState<any>(null)
    useEffect(() => {
            let agents_count: {
                [key: string]: number
            } = {}
            let summarizers_count: {
                [key: string]: number
            } = {}
            let total_count: {
                [key: string]: number
            } = {}
            console.log(data)
            for (const day of data) {
                total_count[day.date] = day.moneyUsedSum
            }
            const labels = Object.keys(total_count).map((day: any) => day.split('T')[0])
            setPreparedData({
                labels: labels,
                datasets: [
                    {
                        label: 'Total',
                        data: Object.keys(total_count).map((day: any) => Math.floor((total_count[day] / 100000000) * 100) / 100),
                        backgroundColor: ['rgba(244,244,244,0.40)'],
                        borderColor: ['rgb(244,244,244)'],
                        borderWidth: 1,
                        barThickness: "flex",
                        borderRadius: 1,
                        stack: 'Stack 1',
                    },
                    // {
                    //     label: 'Agents',
                    //     data: Object.keys(agents_count).map((day: any) => agents_count[day]),
                    //     backgroundColor: ['rgba(206,139,9,0.40)'],
                    //     borderColor: ['rgb(206,139,9)'],
                    //     borderWidth: 1,
                    //     barThickness: 30,
                    //     borderRadius: 4,
                    //     hidden: false,
                    //     stack: 'Stack 0',
                    //
                    // },
                    // {
                    //     label: 'Summarizers',
                    //     data: Object.keys(summarizers_count).map((day: any) => summarizers_count[day]),
                    //     backgroundColor: ['rgba(237,22,96,0.4)'],
                    //     borderColor: ['rgba(237,22,96,1)'],
                    //     borderWidth: 1,
                    //     barThickness: 30,
                    //     borderRadius: 4,
                    //     hidden: false,
                    //     stack: 'Stack 0',
                    //
                    // },

                ],
            })

        }
        ,
        [data],
    )
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
            },

        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 15,
                },
            },
        },
    }
    return (
        <div className='numberOfRequestsPerDay'>
            {preparedData && <Bar
                height='100%'
                data={preparedData}
                options={options}
            />}
        </div>
    )
}

export default BarChart

import './CreateNewAgent.scss'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { AgentType, ErrorType } from '../../../Utils/enums'
import TextareaAutosize from 'react-textarea-autosize'
import { createExpert, createSummarizer } from '../../../Requests/agentRequests'
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import { ModelSettings } from '../../../Components/ModelSettings/ModelSettings'
import { AlertSeverity, AlertType } from '../../../Interfaces/alertsInterfaces'
import { ModelConfigurationInterface } from '../../../Interfaces/modelInterfaces'

export const CreateNewAgent = ({ navigate, sendAlert }: { navigate: Function, sendAlert: Function }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [descriptionLen, setDescriptionLen] = useState<number>(0)
    const [shortDescriptionLen, setShortDescriptionLen] = useState<number>(0)
    const [tags, setTags] = useState<string[]>([])
    const [addingTag, setAddingTag] = useState<boolean>(false)
    const [type, setType] = useState<AgentType | null>(null)
    const defaultModelConfiguration: ModelConfigurationInterface = {
        aiModelId: '',
        temperature: 1,
        maxLength: 256,
        topP: .95,
        frequencyPenalty: 0,
        presencePenalty: 0,
    }
    const [modelConfiguration, setModelConfiguration] = useState<ModelConfigurationInterface>(defaultModelConfiguration)
    const nameRef = useRef(null)
    const descriptionRef = useRef(null)
    const shortDescriptionRef = useRef(null)
    const sysPromptRef = useRef(null)
    const userPromptRef = useRef(null)
    const tagInputRef = useRef(null)

    useEffect(() => {
        const type = searchParams.get('type')
        if (type === 'expert' || !type) {
            setType(AgentType.EXPERT)
        } else {
            setType(AgentType.SUMMARIZER)
        }
    }, [searchParams])

    const descriptionChange = (e: any) => {
        // @ts-ignore
        setDescriptionLen(e.target.value.length)
    }

    const shortDescriptionChange = (e: any) => {
        // @ts-ignore
        setShortDescriptionLen(e.target.value.length)
    }

    const handleSend = (e: any) => {
        e.preventDefault()
        // @ts-ignore
        const name = nameRef.current.value
        // @ts-ignore
        const description = descriptionRef.current.value
        // @ts-ignore
        const shortDescription = shortDescriptionRef.current.value
        // @ts-ignore
        const sysPrompt = sysPromptRef.current.value
        // @ts-ignore
        const userPrompt = userPromptRef.current.value

        console.log(modelConfiguration)
        if (type === AgentType.EXPERT) {
            createExpert(name, shortDescription, description, '', sysPrompt, userPrompt, tags).then(r => {
                navigate('/agents/' + r.id)
            }).catch(e => {
                console.log(e)
                sendAlert(AlertType.UNKNOWN, 'There was an error creating the agent', AlertSeverity.ERROR)
            })
        } else if (type === AgentType.SUMMARIZER) {
            createSummarizer(name, shortDescription, description, sysPrompt, userPrompt, tags).then(r => {
                if (r === ErrorType.NOT_FOUND || r === ErrorType.INTERNAL_SERVER_ERROR || r === ErrorType.BAD_REQUEST) {
                    console.log('error')
                } else
                    navigate('/agents/' + r.id)
            })
        }
    }
    return (
        <div className='createNewAgent' id={type === AgentType.SUMMARIZER ? 'summarizer' : 'expert'}>
            <FreeBlobEnv width={'100%'} height={'100%'} min={40} max={20} />
            <form className='createNewAgent-form' onSubmit={(e) => handleSend(e)}>
                <h1 className='createNewAgent-form-title'>Create
                    new {type === AgentType.SUMMARIZER ? 'summarizer' : 'expert'}</h1>
                <span />
                <div className='createNewAgent-form-wrapper'>
                    <div className='createNewAgent-form-left'>
                        <label className='createNewAgent-form-label'>Name</label>
                        <input ref={nameRef} className='createNewAgent-form-input' required={true} />
                        <label className='createNewAgent-form-label'>Short Description</label>
                        <TextareaAutosize ref={shortDescriptionRef} minRows={4} maxRows={10}
                                          className='createNewAgent-form-input'
                                          required={true} maxLength={160} onChange={shortDescriptionChange}>
                        </TextareaAutosize>
                        <p className='createNewAgent-form-input-charcount'>{shortDescriptionLen}/160</p>
                        <label className='createNewAgent-form-label'>Description</label>
                        <TextareaAutosize ref={descriptionRef} minRows={7} maxRows={15}
                                          className='createNewAgent-form-input'
                                          required={true} maxLength={1000} onChange={descriptionChange}>
                        </TextareaAutosize>
                        <p className='createNewAgent-form-input-charcount'>{descriptionLen}/1000</p>
                        <label className='createNewAgent-form-tags-label'>Tags</label>
                        <div className='createNewAgent-form-tags'>
                            {tags.map((tag, index) => (
                                <div className='createNewAgent-form-tags-tag' key={index}>
                                    <p>{tag}</p>
                                    <AiOutlineClose
                                        className='createNewAgent-form-tags-tag-delete'
                                        onClick={() => {
                                            const newTags = [...tags]
                                            newTags.splice(index, 1)
                                            setTags(newTags)
                                        }} />
                                </div>
                            ))}
                            {!addingTag &&
                                <div className='createNewAgent-form-tags-tag createNewAgent-form-tags-tag-add'
                                     onClick={() => {
                                         setAddingTag(true)
                                     }}>
                                    <AiOutlinePlus />
                                </div>}
                            {addingTag &&
                                <div className='createNewAgent-form-tags-tag'>
                                    <input className='createNewAgent-form-tags-input' placeholder='Add tag'
                                           ref={tagInputRef} autoFocus />
                                    <AiOutlineClose
                                        className='createNewAgent-form-tags-tag-deleteCreating'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setAddingTag(false)
                                        }} />
                                    <AiOutlineCheck
                                        className='createNewAgent-form-tags-tag-confirm'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const newTags = [...tags]
                                            // @ts-ignore
                                            newTags.push(tagInputRef.current.value)
                                            setTags(newTags)
                                            setAddingTag(false)
                                        }} />
                                </div>}
                        </div>

                    </div>
                    <div className='createNewAgent-form-right'>
                        <label className='createNewAgent-form-label'>System prompt</label>
                        <TextareaAutosize ref={sysPromptRef} minRows={10} maxRows={15}
                                          className='createNewAgent-form-input'
                                          required={true} />
                        <label
                            className='createNewAgent-form-label'>{type === AgentType.SUMMARIZER ? 'Summarizer prompt' : 'Expert prompt'}</label>
                        <TextareaAutosize ref={userPromptRef} minRows={10} maxRows={15}
                                          className='createNewAgent-form-input'
                                          required={true} />
                        <ModelSettings type={type === AgentType.EXPERT ? 'expert' : 'summarizer'}
                                       modelConfiguration={modelConfiguration}
                                       setModelConfiguration={setModelConfiguration} />

                    </div>
                </div>
                <button className='createNewAgent-form-button'>Create</button>

            </form>
        </div>
    )
}
export enum NavbarContent {
    DEVELOPER,
    RESOURCES,
}

export enum ErrorType {
    UNAUTHORIZED,
    INTERNAL_SERVER_ERROR,
    NOT_FOUND,
    BAD_REQUEST,
}

export enum ConversationState {
    LOADING_AGENT_RESPONSE,
    LOADING_SUMMARIZER_RESPONSE,
    CHOOSE,
    READY,
    CHOOSE_FINAL,
    UNKNOWN
}

export enum AgentType {
    EXPERT,
    SUMMARIZER
}

export enum MessageFlow{
    FULL,
    NO_SUMMARY,
    ONE_EXPERT,
    NO_SUMMARY_ONE_EXPERT,
}

export enum SidebarPages {
    None = 0,
    Agents = 1,
    History = 2,
    Summarizer = 3,
    CustomProfiles = 4,
    SETTINGS = 5,
    DEV
}
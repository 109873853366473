import './FAQ.scss'
import { useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'

enum Categories {
    General = 'General',
    Developer = 'Developer',
    Business = 'Business',
    Pricing = 'Pricing',
}

interface FaqItemInterface {
    question: string
    answer: string
    category: Categories
}

const FaqItem: FaqItemInterface[] = [
    {
        question: 'What is APPI?',
        answer: 'APPI is a platform that connects businesses with AI experts to help them build custom AI solutions.',
        category: Categories.General,
    },
    {
        question: 'How does APPI work?',
        answer: 'APPI connects businesses with AI experts to help them build custom AI solutions. The platform is designed to make it easy for businesses to find and hire AI experts.',
        category: Categories.General,

    },
    {
        question: 'How do I get started?',
        answer: 'To get started, simply sign up for an account and start browsing our experts. You can also chat with experts to discuss your project and get a quote.',
        category: Categories.General,

    },
    {
        question: 'How much does APPI cost?',
        answer: 'APPI is free to use. You only pay for the services of the AI experts you hire.',
        category: Categories.General,

    },
    {
        question: 'How do I hire an AI expert?',
        answer: 'To hire an AI expert, simply sign up for an account and start browsing our experts. You can also chat with experts to discuss your project and get a quote.',
        category: Categories.Developer,

    },
    {
        question: 'How do I become an AI expert?',
        answer: 'To become an AI expert, simply sign up for an account and create a profile. You can then start offering your services to businesses.',
        category: Categories.Business,

    },
]


const FAQ = () => {
    const [category, setCategory] = useState<Categories>(Categories.General)
    const [itemsFiltered, setItemsFiltered] = useState<FaqItemInterface[]>(FaqItem.filter(item => item.category === category))

    useEffect(() => {
        setItemsFiltered([])
        setTimeout(() => setItemsFiltered(FaqItem.filter(item => item.category === category)), 50)
    }, [category])

    return (
        <div className='faq'>
            <h1 className='faq-title'>FAQ </h1>
            <div className='faq-content'>
                <div className='faq-categories'>
                    {Object.values(Categories).map((item, index) => (
                        <div key={index}
                             className={`faq-categories-category ${category === item ? 'faq-categories-category-active' : ''}`}
                             onClick={() => setCategory(item)}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className='faq-questions'>
                    {itemsFiltered.map((item, index) => (
                        <FAQItem key={index} question={item.question} answer={item.answer} category={item.category} />
                    ))}
                </div>
            </div>

        </div>
    )
}

const FAQItem = (props: FaqItemInterface) => {
    const [open, setOpen] = useState<boolean>(false)
    return (
        <div className='faq-questions-question' onClick={() => setOpen(!open)}>
            <MdOutlineKeyboardArrowUp id={open ? 'open' : 'closed'} />
            <h2>{props.question}</h2>
            {open && <p>{props.answer}</p>}
        </div>
    )

}

export default FAQ

import './Filtered.scss'
import React, { useEffect, useRef } from 'react'
import AgentCard from '../../../Components/AgentCard/AgentCard'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import { useSearchParams } from 'react-router-dom'
import { AgentInterface } from '../../../Interfaces/agentsInterfaces'


interface FilteredInterface {
    handleFilterSelection: Function
    filterList: any,
    experts: any[],
    summarizers: any[],
    navigate: Function
    loading: boolean
    setFilterList: Function
}

const Filtered = ({
                      handleFilterSelection,
                      filterList,
                      experts,
                      summarizers,
                      navigate,
                      loading,
                      setFilterList,
                  }: FilteredInterface) => {
    const [agents, setAgents] = React.useState<any>(experts.concat(summarizers))
    const [searchParams, setSearchParams] = useSearchParams()

    const expertsRef = useRef(null)
    const summarizersRef = useRef(null)
    const [categories, setCategories] = React.useState<any>([])

    useEffect(() => {
        if (experts.concat(summarizers).length > 0) {

            const filters = searchParams.get('categories')?.split(',')
            const string_to_match = searchParams.get('search')?.toLowerCase()
            const type = searchParams.get('type')
            setFilterList({
                categories: filters,
                search: string_to_match,
                type: type,
            })
        }
    }, [searchParams, experts, summarizers])

    useEffect(() => {
        let agentsToFilter = experts.concat(summarizers)
        let newAgents: AgentInterface[] = []

        const string_to_match = filterList.search
        const filters = filterList.categories

        if (filters)
            if (filters[filters?.length - 1] === '')
                filters?.pop()
        if (filterList.type === 'experts')
            agentsToFilter = experts
        else if (filterList.type === 'summarizers')
            agentsToFilter = summarizers


        agentsToFilter.forEach((item: any) => {
            let flag = false
            if (filters)
                filters.forEach((filter: any) => {
                    if (item.tags.map((tag: string) => tag?.toLowerCase()).includes((filter as string)?.toLowerCase())) {
                        flag = true
                    }
                })
            const tags_includes = item.tags.map((tag: string) => tag?.toLowerCase()).includes((string_to_match as string)?.toLowerCase())
            if (string_to_match && string_to_match !== '' && (item.name?.toLowerCase().includes(string_to_match as string) || tags_includes))
                flag = true
            if (string_to_match === undefined && filters === undefined)
                flag = true
            if (flag)
                newAgents.push(item)
        })
        setAgents(newAgents)
    }, [filterList])


    const handleFilter = (e: any) => {
        e.preventDefault()
        const newFilters = filterList
        newFilters.categories = categories

        setSearchParams(newFilters)
        console.log(newFilters)
    }
    return (
        <div className='agents filtered'>
            {/*<div className='agents-upperSection'>*/}
            {/*<div className='agents-upperSection-searchWrapper'>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className='agents-bottomSection'>
                <form className='agents-filters'>
                    <h1 className='agents-filters-title'>Search</h1>
                    <SearchBar agents={agents} />
                    <h1 className='agents-filters-title'>Type</h1>
                    <div className='agents-filters-radio'>
                        <input type='radio' id='experts' name='type' value='experts' ref={expertsRef} />
                        <label htmlFor='experts'>Experts</label>
                    </div>
                    <div className='agents-filters-radio'>
                        <input type='radio' id='summarizers' name='type' value='summarizers' ref={summarizersRef} />
                        <label htmlFor='summarizers'>Summarizers</label>
                    </div>
                    <h1 className='agents-filters-title'>Categories</h1>
                    <div className='agents-filters-checkbox'>
                        {['Code', 'Scholar', 'Research', 'Design'].map((item: string, index: number) => (
                            <div className='agents-filters-checkbox-item' key={index} onClick={() => {
                                if (categories.includes(item)) {
                                    const newCategories = categories.filter((category: string) => category !== item)
                                    setCategories(newCategories)
                                } else {
                                    setCategories([...categories, item])
                                }
                            }}>
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>
                    <button className='agents-filters-button' onClick={(e) => handleFilter(e)}>Filter</button>
                </form>
                <div className='agents-appList agents-appList-filtered'>
                    {(agents.length !== 0) && agents.map((app: AgentInterface, index: number) => (
                        <AgentCard type={'agents'} agent={app} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Filtered
interface ChatPresetInterface {
    name: string;
    prompt: string;
    experts_ids: string[];

}

export const chatPresets: ChatPresetInterface[] = [
    {
        name: 'Rura',
        prompt: 'Jakie są minimalne wymagania wytrzymałości dla PVDF?',
        experts_ids: [
            '413f16d5-2957-4692-afa1-7d197c52780c',
        ],
    },
]
import './Labs.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import Footer from '../../../Landing/Footer/Footer'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { StaticGrid } from '../../Components/StaticGrid/StaticGrid'
import { AiFillAlert, AiOutlineHeatMap, AiOutlineSafetyCertificate, AiOutlineStock } from 'react-icons/ai'
import { IoIosGitNetwork } from 'react-icons/io'
import { IoBarbell } from 'react-icons/io5'
import { RiTeamLine } from 'react-icons/ri'

import { FaBook } from 'react-icons/fa'
import { StaticUs } from '../../Components/StaticUs/StaticUs'
import { Title } from '../../../../Components/Title/Title'
import { Link } from 'react-scroll/modules'


const image = require('../../../../Images/Assets/About/img1.png')
export const Labs = () => {
    return (
        <div className="solutionsBusinesses-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="solutionsBusinesses">
                <StaticTitle
                    title={{
                        title: 'Labs',
                        imgPosition: 'top',
                        titleImg: () => <AiOutlineSafetyCertificate />,
                    }}
                    subtitle={'Join us on the quest to innovate and create.'}
                    buttons={{
                        main: 'Apply now',
                        secondary: 'Read more',
                        mainLink: () => {
                            window.open('https://forms.gle/gpXFGscCkN73MYdq7', '_blank')
                        },
                    }}
                />

                <StaticProps
                    title={{
                        highlight: 'What we offer',
                        imgPosition: 'left',
                    }}
                    items={[
                        {
                            'title': 'Team-Based Projects',
                            'text': 'Join our project teams to work on real products, enhancing your practical experience and teamwork skills.',
                            'icon': <RiTeamLine />,
                        },
                        {
                            'title': 'Focused Training',
                            'text': 'Benefit from specialized training sessions that are designed to boost your tech skills and industry knowledge.',
                            'icon': <IoBarbell />,
                        },
                        {
                            'title': 'Certification Programs',
                            'text': 'Achieve certifications that validate your skills and set you apart in the competitive tech landscape from the very start.',
                            'icon': <AiOutlineSafetyCertificate />,
                        },
                    ]}
                />

                <StaticProps
                    title={{
                        title: 'What you get',
                        imgPosition: 'top',
                        titleImg: () => <AiOutlineSafetyCertificate />,
                    }}
                    items={[
                        {
                            'title': 'Networking Opportunities',
                            'text': 'Expand your professional network by collaborating with industry experts and peers, opening doors to new opportunities.',
                            'icon': <IoIosGitNetwork />,
                        },
                        {
                            'title': 'Startup Immersion',
                            'text': 'Kickstart your journey with startups. Immerse yourself in the dynamic startup ecosystem and learn from the ground up.',
                            'icon': <AiOutlineStock />,
                        },
                        {
                            'title': 'Applied Knowledge',
                            'text': 'Apply your knowledge in real-world projects, adding valuable, tangible outcomes to your portfolio.',
                            'icon': <FaBook />,
                        },
                    ]}
                    altStyle={true}
                />

                <div className="landingSubpage ctaMission">
                    <Title
                        title={'We are'}
                        highlight={'innovators'}
                        titleImg={() => <AiOutlineHeatMap />}
                        imgPosition={'top'}
                    />
                    <h2 className="ctaMission-subtitle">Our mission is to support businesses with best LLM techniques,
                        allowing for them to grow and adapt to new AI-power reality.</h2>

                    <Link to={'aboutAppi'} className="ctaMission-button" spy={true} smooth={true} duration={500}
                          offset={-50}>
                        Let's cooperate
                    </Link>
                </div>

                <StaticGrid
                    title={{
                        highlight: 'Our projects',
                        imgPosition: 'left',
                        titleImg: () => <AiFillAlert />,
                    }}
                    items={[
                        {
                            'title': 'RAG based Question Answering',
                            'text': 'We provide a comprehensive analysis of your data, identifying patterns and trends that can be leveraged to drive business growth.',
                            'img': image,
                            'backside': <LabsRequirements
                                description={'We are looking for a student who is passionate about AI and has a strong foundation in machine learning and natural language processing.'}
                                requirements={[
                                    'Python',
                                    'Machine Learning',
                                    'Natural Language Processing',
                                    'Deep Learning',
                                ]}
                            />,
                        },
                        {
                            'title': 'APPI Synapse',
                            'text': 'Our internal tool for managing LLM RAG based conversation flow',
                            'img': image,
                            'backside': <LabsRequirements
                                description={'We are looking for full-stack developers who are passionate about AI and have a strong foundation in machine learning and natural language processing.'}
                                requirements={[
                                    'SQL',
                                    'Python',
                                    'Frontend Development',
                                ]}
                            />,
                        },
                        {
                            'title': 'APPI Recommendation Engine',
                            'text': 'State-of-the-art engine that provides personalized recommendations of experts to users during conversation',
                            'img': image,
                            backside: <LabsRequirements
                                description={'We are looking for a student who is passionate about AI and has a strong foundation in machine learning and natural language processing.'}
                                requirements={[
                                    'Python',
                                    'Natural Language Processing',
                                    'SQL',
                                    'Deep Learning',
                                    'Algorithm Design',
                                ]}
                            />,
                        },
                    ]}
                />
                <StaticUs
                    title={{
                        highlight: 'Meet our team',
                        imgPosition: 'left',
                        titleImg: () => <AiFillAlert />,
                    }}
                />
                <StaticPrompt title={'Ready to get started?'}
                              subtitle={'Secure your AI career today'}
                              href={'https://forms.gle/gpXFGscCkN73MYdq7'}
                              button={'Application form'}
                />
                <Footer />
            </div>
        </div>
    )
}

interface LabsRequirementsInterface {
    description: string,
    requirements: Array<string>
}

const LabsRequirements = ({ requirements, description }: LabsRequirementsInterface) => {
    return (
        <div className="labs-requirements">
            <p className="labs-requirements-description">{description}</p>
            <h2 className="labs-requirements-skills">Skills:</h2>
            <ul>
                {requirements.map((requirement, index) => (
                    <li key={index}>{requirement}</li>
                ))}
            </ul>
        </div>
    )

}
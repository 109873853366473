export interface DocumentFragmentsInterface {
}

export interface DocumentInterface {
    createdAt: string,
    documentAnalysis: any,
    documentFragments: DocumentFragmentsInterface[],
    documentName: string,
    documentUrl: string,
    id: string,
    isExpired: boolean,
    type: ContextType
}


enum ContextType {
    EXPERT_ANSWER = 'Context_ExpertAnswer',
}

export interface ContextInterface {
    createdAt: string,
    contextFragments: ContextPartInterface[],
    documentId: string,
    expertAnswerId: string,
    expertId: string,
    id: string,
    type: ContextType.EXPERT_ANSWER
}

export enum ContextPartType {
    TEXT = 'text',
    IMAGE = 'image',
    TABLE = 'table',
    FORMULA = 'formula',
    SECTION = 'section',
    PARAGRAPH = "paragraph",
    FIGURE = "figure",
}

export interface FragmentInterface {
    image?: string
    id: string,
    messagePartContent_TextAlt: string,
    messagePartType: ContextPartType,
    text?: string
    summary?: string
    cells?: string
}

export interface ContextPartInterface {
    documentId: string
    id: string,
    fragment: FragmentInterface
    boundingBoxUpperLeftPoint: {
        x: number,
        y: number,
    },
    boundingBoxBottomRightPoint: {
        x: number,
        y: number,
    }
    page: number,
    table?: {
        header: string[],
        rows: string[][]

    }
    index?: number
    isBounded?: boolean
    expertId?: string
    nodeId?: string
}
import './Feedback.scss'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'

export const Feedback = () => {
    return (
        <div className='feedbackWrapper'>
            <FreeBlobEnv />
            <form className='feedback'>
                <h1 className='feedback-form-title'>Leave your feedback!</h1>
                <input className='feedback-form-input' placeholder='Your name' />
                <input className='feedback-form-input' placeholder='Your email' />
                <textarea className='feedback-form-textarea' placeholder='Your feedback' />
                <button className='feedback-form-button'>Send</button>
            </form>
        </div>
    )
}
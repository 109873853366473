import React, { createContext, useContext, useRef, useState } from 'react'
import { ConversationInterface, MessageInterface } from '../../Interfaces/messageInterfaces'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { AgentInterface } from '../../Interfaces/agentsInterfaces'
import { ConversationState, SidebarPages } from '../../Utils/enums'
import { CustomProfile } from '../../Interfaces/chatInterfaces'
import { Heap } from './Conversation/Heap'
import { usePageBottom, useWindowDimensions } from '../../Utils/hooks'
import { useStreamHandler } from './StreamHandler'
import { useGlobalState } from '../../GlobalStateContext'
import Chat from './Chat'
import { ContextInterface } from '../../Interfaces/documentInterfaces'
import { AiModelInterface } from '../../Interfaces/modelInterfaces'

const languages = require('./languages.json')

export enum InspectorMode {
    BROWSER,
    DOCUMENT
}

export enum GenerationStage {
    NONE,
    PROCESSING_PROMPT,
    RETRIEVING_CONTEXT,
    STARTING_THREADS,
    COMBINING_RESPONSES,
    VALIDATING_RESPONSE,
    STREAMING_RESPONSE
}

interface ContextValueInterface {
    dims: any;
    mobile: boolean;
    navigate: NavigateFunction;
    location: any;
    agentsList: AgentInterface[];
    setAgentsList: React.Dispatch<React.SetStateAction<AgentInterface[]>>;
    summarizersList: AgentInterface[];
    setSummarizersList: React.Dispatch<React.SetStateAction<AgentInterface[]>>;
    agentsSelected: any[];
    setAgentsSelected: React.Dispatch<React.SetStateAction<any[]>>;
    summarizer: any;
    setSummarizer: React.Dispatch<React.SetStateAction<any>>;
    sidebar: SidebarPages;
    setSidebar: React.Dispatch<React.SetStateAction<SidebarPages>>;
    history: ConversationInterface[];
    setHistory: React.Dispatch<React.SetStateAction<ConversationInterface[]>>;
    selectedConversation: ConversationInterface | null;
    setSelectedConversation: React.Dispatch<React.SetStateAction<ConversationInterface | null>>;
    messages: MessageInterface[];
    setMessages: React.Dispatch<React.SetStateAction<MessageInterface[]>>;
    conversationState: ConversationState;
    setConversationState: React.Dispatch<React.SetStateAction<ConversationState>>;
    selectedItem: any;
    setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
    historyPing: any;
    setHistoryPing: React.Dispatch<React.SetStateAction<any>>;
    historyModal: any;
    setHistoryModal: React.Dispatch<React.SetStateAction<any>>;
    selectedAnswers: any[];
    setSelectedAnswers: React.Dispatch<React.SetStateAction<any[]>>;
    summarizerId: any;
    setSummarizerId: React.Dispatch<React.SetStateAction<any>>;
    finalAnswer: any[];
    setFinalAnswer: React.Dispatch<React.SetStateAction<any>>;
    models: AiModelInterface[];
    setModels: React.Dispatch<React.SetStateAction<AiModelInterface[]>>;
    selectedModel: any;
    setSelectedModel: React.Dispatch<React.SetStateAction<any>>;
    customProfiles: CustomProfile[];
    setCustomProfiles: React.Dispatch<React.SetStateAction<CustomProfile[]>>;
    selectedCustomInstruction: CustomProfile;
    setSelectedCustomInstruction: React.Dispatch<React.SetStateAction<CustomProfile>>;
    newInstruction: any;
    setNewInstruction: React.Dispatch<React.SetStateAction<any>>;
    editInstruction: any;
    setEditInstruction: React.Dispatch<React.SetStateAction<any>>;
    loadingHistory: boolean;
    setLoadingHistory: React.Dispatch<React.SetStateAction<boolean>>;
    loadingConversation: boolean;
    setLoadingConversation: React.Dispatch<React.SetStateAction<boolean>>;
    CustomProfilesOpen: boolean;
    setCustomProfilesOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarModal: boolean;
    setSidebarModal: React.Dispatch<React.SetStateAction<boolean>>;
    customInstructionNameRef: React.MutableRefObject<any>;
    customInstructionRef: React.MutableRefObject<any>;
    userMessageRef: React.MutableRefObject<any>;
    forest: Heap;
    setForest: React.Dispatch<React.SetStateAction<Heap>>;
    flow: number[];
    setFlow: React.Dispatch<React.SetStateAction<number[]>>;
    chosenMessages: MessageInterface[];
    setChosenMessages: React.Dispatch<React.SetStateAction<MessageInterface[]>>;
    selectedLanguage: any;
    setSelectedLanguage: React.Dispatch<React.SetStateAction<any>>;
    messagesLoaded: boolean;
    setMessagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    files: any[];
    setFiles: React.Dispatch<React.SetStateAction<any[]>>;
    inspectorOpen: boolean;
    setInspectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
    inspectorContext: any;
    setInspectorContext: React.Dispatch<React.SetStateAction<any>>;
    inspectorWidth: string;
    setInspectorWidth: React.Dispatch<React.SetStateAction<string>>;
    globalState: any;
    inspectorMode: InspectorMode;
    setInspectorMode: React.Dispatch<React.SetStateAction<InspectorMode>>;
    conversationContainerRef: React.MutableRefObject<any>;
    generationStage: { [id: string]: GenerationStage };
    setGenerationStage: React.Dispatch<React.SetStateAction<{ [id: string]: GenerationStage }>>;
}

const ChatContext = createContext<ContextValueInterface | undefined>(undefined)

export const ChatContextProvider = ({ sendAlert }: { sendAlert: Function }) => {
    const defaultInstruction: CustomProfile = {
        id: '-1',
        name: 'No profile',
        instruction: '',
    }

    const dims = useWindowDimensions()
    const mobile = dims.width < 991
    const navigate: NavigateFunction = useNavigate()
    const location = useLocation()
    const [agentsList, setAgentsList] = useState<AgentInterface[]>([])
    const [summarizersList, setSummarizersList] = useState<AgentInterface[]>([])
    const [agentsSelected, setAgentsSelected] = useState<any>([])
    const [summarizer, setSummarizer] = useState<any>(null)
    const [sidebar, setSidebar] = useState<SidebarPages>(mobile ? SidebarPages.None : SidebarPages.History)
    const [history, setHistory] = useState<ConversationInterface[]>([])
    const [selectedConversation, setSelectedConversation] = useState<ConversationInterface | null>(null)
    const [messages, setMessages] = useState<MessageInterface[]>([])
    const [conversationState, setConversationState] = useState<ConversationState>(ConversationState.READY)
    const [selectedItem, setSelectedItem] = useState<any>(null)
    const [historyPing, setHistoryPing] = useState<any>(false)
    const [historyModal, setHistoryModal] = useState<any>(null)
    const [selectedAnswers, setSelectedAnswers] = useState<any>([])
    const [summarizerId, setSummarizerId] = useState<any>(null)
    const [finalAnswer, setFinalAnswer] = useState<any>(null)
    const [models, setModels] = useState<any>([])
    const [selectedModel, setSelectedModel] = useState<any>(null)
    const [customProfiles, setCustomProfiles] = useState<CustomProfile[]>([defaultInstruction])
    const [selectedCustomInstruction, setSelectedCustomInstruction] = useState<CustomProfile>(defaultInstruction)
    const [newInstruction, setNewInstruction] = useState<any>(false)
    const [editInstruction, setEditInstruction] = useState<any>(null)
    const [loadingHistory, setLoadingHistory] = useState<boolean>(false)
    const [loadingConversation, setLoadingConversation] = useState<boolean>(false)
    const [CustomProfilesOpen, setCustomProfilesOpen] = useState<boolean>(false)
    const [sidebarModal, setSidebarModal] = useState<boolean>(false)
    const customInstructionNameRef = React.useRef<any>(null)
    const customInstructionRef = React.useRef<any>(null)
    const userMessageRef = useRef(null)
    const [globalState, _] = useGlobalState()
    const [forest, setForest] = useState<Heap>(new Heap())
    const [flow, setFlow] = useState<number[]>([])
    const [chosenMessages, setChosenMessages] = useState<MessageInterface[]>([])
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
    const [messagesLoaded, setMessagesLoaded] = useState<boolean>(false)
    const [files, setFiles] = useState<any[]>([])

    const [generationStage, setGenerationStage] = useState<{ [id: string]: GenerationStage }>({})
    const streamHandler = useStreamHandler()
    const reachedBottom = usePageBottom()

    const [inspectorOpen, setInspectorOpen] = useState<boolean>(false)
    const [inspectorContext, setInspectorContext] = useState<ContextInterface[] | []>([])
    const [inspectorWidth, setInspectorWidth] = useState<string>('50%')
    const [inspectorMode, setInspectorMode] = useState<InspectorMode>(InspectorMode.BROWSER)

    const conversationContainerRef = useRef(null)

    const contextValue: ContextValueInterface = {
        dims,
        mobile,
        navigate,
        location,
        agentsList,
        setAgentsList,
        summarizersList,
        setSummarizersList,
        agentsSelected,
        setAgentsSelected,
        summarizer,
        setSummarizer,
        sidebar,
        setSidebar,
        history,
        setHistory,
        selectedConversation,
        setSelectedConversation,
        messages,
        setMessages,
        conversationState,
        setConversationState,
        selectedItem,
        setSelectedItem,
        historyPing,
        setHistoryPing,
        historyModal,
        setHistoryModal,
        selectedAnswers,
        setSelectedAnswers,
        summarizerId,
        setSummarizerId,
        finalAnswer,
        setFinalAnswer,
        models,
        setModels,
        selectedModel,
        setSelectedModel,
        customProfiles,
        setCustomProfiles,
        selectedCustomInstruction,
        setSelectedCustomInstruction,
        newInstruction,
        setNewInstruction,
        editInstruction,
        setEditInstruction,
        loadingHistory,
        setLoadingHistory,
        loadingConversation,
        setLoadingConversation,
        CustomProfilesOpen,
        setCustomProfilesOpen,
        sidebarModal,
        setSidebarModal,
        customInstructionNameRef,
        customInstructionRef,
        userMessageRef,
        forest,
        setForest,
        flow,
        setFlow,
        chosenMessages,
        setChosenMessages,
        selectedLanguage,
        setSelectedLanguage,
        messagesLoaded,
        setMessagesLoaded,
        files,
        setFiles,
        inspectorOpen,
        setInspectorOpen,
        inspectorContext,
        setInspectorContext,
        globalState,
        inspectorWidth,
        setInspectorWidth,
        inspectorMode,
        setInspectorMode,
        conversationContainerRef,
        generationStage,
        setGenerationStage,
    }


    return <ChatContext.Provider value={contextValue}>
        <Chat sendAlert={sendAlert} />
    </ChatContext.Provider>
}
export const useChatContext = () => {
    const context = useContext(ChatContext)
    if (context === undefined) {
        throw new Error('useMyContext must be used within a ChatProvider')
    }
    return context
}

import './StaticGrid.scss'
import React, { ReactNode, useState } from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticGridItemInterface {
    title: string
    text: string
    description?: string
    img?: string
    backside?: ReactNode
}

interface StaticGridInterface {
    items?: StaticGridItemInterface[]
    title: TitleInterface
    backside?: ReactNode
}

export const StaticGrid = (props: StaticGridInterface) => {
    return (
        <div className="staticGrid">
            <Title
                title={props.title.title}
                subtitle={props.title.subtitle}
                imgPosition={props.title.imgPosition}
                highlight={props.title.highlight}
                titleImg={props.title.titleImg}
                newlineHighlight={props.title.newlineHighlight}
            />
            <div className="staticGrid-itemsWrapper">
                {props.items?.map((item, index) => (
                    <StaticPropsItem key={index} {...item} />
                ))}
            </div>
        </div>
    )
}

const StaticPropsItem = (props: StaticGridItemInterface) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleClick = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div
            className={`staticGrid-item`}
            style={{
                backgroundImage: isExpanded ? undefined : `url(${props.img})`,
            }}
        >
            {isExpanded ?
                <div className="staticGrid-item-description">
                    {props.backside}
                </div>
                :
                <div className="staticGrid-item-inside">
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>
                </div>
            }
            <button onClick={handleClick}>Read more</button>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import './RegisterComponent.scss'
import '../../Pages/Login/Login.scss'
import { sha256 } from 'js-sha256'
import { apiPost } from '../../Utils/RequestsUtil'
import { AlertSeverity, AlertType } from '../../Interfaces/alertsInterfaces'
import { MdCancel } from 'react-icons/md'
import { FaCheck } from 'react-icons/fa'

function RegisterComponent({ navigate, swap, sendAlert }) {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        passwordConfirm: '',
    })
    const [requirements, setRequirements] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData((prevState) => ({ ...prevState, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (formData.password !== formData.passwordConfirm) {
            sendAlert(AlertType.UNKNOWN, 'Passwords do not match', AlertSeverity.WARNING)
            return
        }

        apiPost(
            'User/register',
            {
                'name': formData.name,
                'surname': formData.surname,
                'email': formData.email,
                'password_sha256hash': sha256(formData.password),
            },
        ).then((response) => {
            sendAlert(
                AlertType.SUCCESS, 'We have send you a confirmation email', AlertSeverity.SUCCESS,
            )
        }).catch((error) => {
            if (error.response.status === 400) {
                sendAlert(AlertType.UNKNOWN, 'Your mail was already sent', AlertSeverity.ERROR)
            }
        })
    }

    const RequirementsWrapper = () => {

        useEffect(() => {
            const password = formData.password
            const isLengthValid = /^.{8,40}$/.test(password)
            const hasLowercase = /[a-z]+/.test(password)
            const hasUppercase = /[A-Z]+/.test(password)
            const hasNumber = /[0-9]+/.test(password)

            setRequirements(isLengthValid && hasLowercase && hasUppercase && hasNumber)
        }, [formData.password])

        return (
            <div className='registerComponent-requirements'>
                <Requirement name='8 - 40 characters' regex={/^.{8,40}$/} password={formData.password} />
                <Requirement name='1 lowercase' regex={/[a-z]+/} password={formData.password} />
                <Requirement name='1 uppercase' regex={/[A-Z]+/} password={formData.password} />
                <Requirement name='1 number' regex={/[0-9]+/} password={formData.password} />
            </div>
        )
    }

    const Requirement = ({ name, regex, password }) => {
        return (
            <div className='registerComponent-requirements-item'>
                {regex.test(password) ? <FaCheck /> : <MdCancel />}
                <p>{name}</p>
            </div>
        )
    }

    return (
        <div className='registerComponent'>
            <form onSubmit={handleSubmit}>
                <h1 className='registerComponent-registerTitle'>Register</h1>
                <div className='registerComponent-nameWrapper'>
                    <label>
                        <input
                            placeholder={'Name'}
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleInputChange}
                            required={true}
                        />
                    </label>
                    <label>
                        <input
                            placeholder={'Surname'}
                            type='text'
                            name='surname'
                            value={formData.surname}
                            onChange={handleInputChange}
                            required={true}
                        />
                    </label>
                </div>
                <label>
                    <input
                        placeholder={'Email'}
                        type='email'
                        name='email'
                        value={formData.email}
                        onChange={handleInputChange}
                        required={true}
                    />
                </label>
                <label>
                    <input
                        placeholder={'Password'}
                        type='password'
                        name='password'
                        value={formData.password}
                        onChange={handleInputChange}
                        required={true}
                    />
                    <RequirementsWrapper />
                </label>
                <label>
                    <input
                        placeholder={'Confirm password'}
                        type='password'
                        name='passwordConfirm'
                        value={formData.passwordConfirm}
                        onChange={handleInputChange}
                        required={true}
                    />
                </label>
                {requirements ? <button className='registerComponent-submit' type='submit'>Register</button> :
                    <div className='registerComponent-submit registerComponent-submit-inactive'
                         type='submit'>Register</div>}
            </form>
            <div className='line' />
            {/*<div className='registerComponent-altLogs'>*/}
            {/*    <FcGoogle className='registerComponent-google' />*/}
            {/*</div>*/}
            <div className='swap'>
                <h2>Already have an account?</h2>
                <button id='swap' onClick={swap}>Login</button>
            </div>
        </div>
    )
}

export default RegisterComponent

import React, { useEffect, useState } from 'react'

import './Agents.scss'
import './AgentsMobile.scss'
import Filtered from './Filtered/Filtered'
import { Unfiltered } from './Unfiltered/Unfiltered'
import { getPublicExperts, getSummarizers } from '../../Requests/agentRequests'
import { useLocation, useNavigate } from 'react-router-dom'
import SEO from '../../Components/SEO'
import { AnimatedBlob } from '../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { AgentInterface } from '../../Interfaces/agentsInterfaces'

const Agents = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [agents, setAgents] = useState<AgentInterface[]>([])
    const [summarizers, setSummarizers] = useState<any>([])
    const [filterList, setFilterList] = useState<any>({})
    const [appState, setAppState] = useState<boolean>(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getPublicExperts(false).then((res) => {
            setAgents(res)
        })
        getSummarizers().then((res) => {
            setSummarizers(res)
        })
        setLoading(false)
    }, [])
    const handleFilterSelection = (filter: any) => {
        let newFilterList = [...filterList]
        if (newFilterList.includes(filter)) {
            newFilterList = newFilterList.filter((item: any) => item !== filter)
        } else {
            newFilterList.push(filter)
        }
        setFilterList(newFilterList)
    }

    useEffect(() => {
        if (location.search) {
            setAppState(true)
        } else {
            setAppState(false)
        }
    }, [location.search])

    const tags = [
        'Code',
        'Scholar',
        'Research',
        'Design',
    ]

    return (
        <div className='agentsWrapper'>
            <SEO
                title='APPI | Agents'
                description='Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love.'
                name='Experts'
                type='website'
                keywords={['APPI', 'Experts', 'Chat', 'Create an account', 'Read more']}
            />
            <AnimatedBlob number={2} top={'-15rem'} left={'-50vw'} width={'80%'} height={'80%'} />
            <AnimatedBlob number={6} top={'-20rem'} left={'55vw'} width={'60%'} height={'60%'} />
            {/*<AnimatedBlob number={10} top={'60vh'} left={'55vw'} width={'50%'} height={'50%'} />*/}
            {/*<AnimatedBlob number={10} top={'180vh'} left={'55vw'} width={'50%'} height={'50%'} />*/}
            {/*<AnimatedBlob number={12} top={'160vh'} left={'-15vw'} width={'25%'} height={'25%'} />*/}


            {appState && <Filtered
                filterList={filterList}
                handleFilterSelection={handleFilterSelection}
                experts={agents}
                summarizers={summarizers}
                navigate={navigate}
                loading={loading}
                setFilterList={setFilterList}
            />}
            {!appState && <Unfiltered
                agents={agents}
                summarizers={summarizers}
                navigate={navigate}
                loading={loading}
                tags={tags}
            />}


        </div>
    )
}

export default Agents

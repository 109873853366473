import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'

const container = document.getElementById('root')
// @ts-ignore
const root = ReactDOM.createRoot(container)

root.render(
    <App />,
)

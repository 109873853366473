import { useEffect, useRef, useState } from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import {
    getEmployeeDailyStats,
    getEmployeeHourlyStats,
    getGeneralDailyStats,
    getGeneralHourlyStats,
} from '../../../Requests/organizationsRequests'
import Dropdown from '../../../Components/Dropdown/Dropdown'
import { OrganizationInterface } from '../../../Interfaces/organizationInterfaces'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

interface UsageGraphInterface {
    organization: OrganizationInterface,
    employeeId: string | null   //null if general stats
}

interface DataInterface {
    date: string,
    expertUsageCount: number,
    summarizerUsageCount: number,
    totalCreditsUsed: number,
    totalUsageCount: number
    hour: number
}
  
const WeeklyTeamUsage = ({organization, employeeId} : UsageGraphInterface) => {
    console.log(employeeId)
    const statOptions = [
        {id: 0, name: "Agent Usage"},
        {id: 1, name: "Expert Usage"},
        {id: 2, name: "Summarizer Usage"},
        {id: 3, name: "Credits Used"}
    ]

    const timeOptions = [
        {id: 0, name: "Hourly"},
        {id: 1, name: "Daily"}
    ]

    const periodOptions = [
        {id: 0, name: "12h"},
        {id: 1, name: "24h"},
        {id: 2, name: "48h"}
    ]

    const chartRef = useRef(null)
    const [data, setData] = useState<DataInterface[] | null>(null)
    const [stat, setStat] = useState<any>(statOptions[0]);
    const [time, setTime] = useState<any>(timeOptions[1]);
    const [period, setPeriod] = useState<any>(periodOptions[1]);

    const getDailyStats = () => {
        if (data === null) return null;
        let stats: number[];
        switch (stat.id) {
            case 1:
                stats = data.map(dataItem => {
                    return dataItem.expertUsageCount;
                })
                return stats;
            case 2:
                stats = data.map(dataItem => {
                    return dataItem.summarizerUsageCount;
                })
                return stats;
            case 3:
                stats = data.map(dataItem => {
                    return dataItem.totalCreditsUsed;
                })
                return stats;
            default:
                stats = data.map(dataItem => {
                    return dataItem.totalUsageCount;
                })
                return stats;
        }
    }

    const getHourlyStats = (hours: number) => {
        if (!data) return null;
    
        const initDate = new Date();
        initDate.setMinutes(0);
        initDate.setSeconds(0);
        initDate.setMilliseconds(0);
        initDate.setHours(initDate.getHours() - hours);
    
        let currentIndex = 0;
        for (let i = 0; i < data.length; i++) {
            const currDate = new Date(data[i].date);
            if (data[i].hour !== null) currDate.setHours(currDate.getHours() + data[i].hour);
            if (currDate >= initDate) {
                currentIndex = i;
                break;
            }
        }
    
        const result = [];
        for (let i = 0; i < hours; i++) {
            if (currentIndex < data.length) {
                const arrayDate = new Date(data[currentIndex].date);
                if (data[currentIndex].hour !== null) arrayDate.setHours(arrayDate.getHours() + data[currentIndex].hour);
    
                if (arrayDate.getTime() === initDate.getTime()) {
                    switch (stat.id) {
                        case 1:
                            result.push(data[currentIndex].expertUsageCount);
                            break;
                        case 2:
                            result.push(data[currentIndex].summarizerUsageCount);
                            break;
                        case 3:
                            result.push(data[currentIndex].totalCreditsUsed);
                            break;
                        default:
                            result.push(data[currentIndex].totalUsageCount);
                            break;
                    }
                    currentIndex++;
                }else {
                    result.push(0);
                }
            } else {
                result.push(0);
            }
            initDate.setHours(initDate.getHours() + 1);
        }
    
        return result;
    };
    
    
    

    const lastFiveDays = () => {
        if (data === null) return null;
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const weekdays = data.map(dataItem => {
            const day = new Date(dataItem.date);
            return days[day.getDay()];
        });
        return weekdays.slice(Math.max(-5, data.length * -1));
    }

    const hourLabels = (hours: number) => {
        const labels: string[] = [];
        const now = new Date();

        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);

      
        for (let i = 0; i < hours; i++) {
          const time = new Date(now.getTime() - i * 60 * 60 * 1000);
          const formattedTime = time.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          }).replace(':00 ', ' ');
          labels.unshift(formattedTime);
        }
      
        return labels;
    }
      

    useEffect(() => {
        if (time.id === 1) {
            if (employeeId !== null) getEmployeeDailyStats(organization.id, employeeId).then((res) => {
                if (res !== data) setData(res)
            })
            else getGeneralDailyStats(organization.id).then((res) => {
                if (res !== data) setData(res)
            })
        }
        else {
            if (employeeId !== null) getEmployeeHourlyStats(organization.id, employeeId).then((res) => {
                if (res !== data) setData(res)
            })
            else getGeneralHourlyStats(organization.id).then((res) => {
                if (res !== data) setData(res)
            })
        }
        console.log(data)
    }, [time])

    useEffect(() => {
        // @ts-ignore
        const ctx = chartRef.current.getContext('2d')
        const timestamp = lastFiveDays()
        let timePeriod: number;
        switch (period.id) {
            case 0:
                timePeriod = 12
                break
            case 2:
                timePeriod = 48
                break
            default:
                timePeriod = 24
                break
        }
        const teamUsageChart = new Chart(ctx, time.id === 1 ? {
            type: 'bar',
            data: {
                labels: timestamp === null ? [] : timestamp,
                datasets: [{
                    //label: "Hours Worked",
                    data: getDailyStats(),
                    backgroundColor: [
                        "rgba(176,154,81,0.8)"
                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
                layout: {
                    padding: {
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
        } : {
            type: 'line',
            data: {
                labels: hourLabels(timePeriod),
                datasets: [{
                    //label: "Hours Worked",
                    data: getHourlyStats(timePeriod),
                    borderColor: "rgba(176,154,81,0.8)",
                    backgroundColor: [
                        "rgba(176,154,81,0.8)"
                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
                layout: {
                    padding: {
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
        })

        return () => {
            teamUsageChart.destroy()
        }
    }, [stat, data, time, period, organization])


    return (
        <div className='overview-content-overview-weeklyTeamUsage'>
            <div className='overview-content-overview-weeklyTeamUsage-heading'>
                <h1>Usage</h1>
                <Dropdown selected={stat} setSelected={setStat} options={statOptions} displayAttribute={"name"}/>
            </div>
            <canvas ref={chartRef} />
            <div className='overview-content-overview-weeklyTeamUsage-bottom'>
                <Dropdown selected={time} setSelected={setTime} options={timeOptions} displayAttribute={"name"}/>
                {time.id === 0 && <Dropdown selected={period} setSelected={setPeriod} options={periodOptions} displayAttribute={"name"}/>}
            </div>
        </div>
    );
    
}

export default WeeklyTeamUsage
import './MeetAppiExperts.scss'
import AgentCard from '../../../../../Components/AgentCard/AgentCard'
import { useEffect } from 'react'

interface MeetAppiExpertsInterface {
    hovered?: boolean
}


export const MeetAppiExperts = ({ hovered }: MeetAppiExpertsInterface) => {

    // this useEffect is here to prevent the bug that happens when the user's phone magically turns into a desktop
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 800) {
                const scrollContainer = document.querySelector('.meetAppiExperts')
                if (scrollContainer) {
                    scrollContainer.scrollTo(0, 0)
                }
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="staticListPoint-children">
            <div
                className={window.innerWidth > 800 ? (hovered ? 'meetAppiExperts meetAppiExperts-hovered' : 'meetAppiExperts') : 'meetAppiExperts meetAppiExperts-scroll'}>
                {agentsConfig.map((agent, index) => {
                    return (
                        <AgentCard
                            key={index}
                            agent={{
                                name: agent.name,
                                shortDescription: agent.shortDescription,
                                tags: [],
                                logoColorHex: agent.logoColorHex,
                                authorId: '',
                                logoUrl: agent.logoUrl,
                                description: '',
                                usageInstruction: '',
                                id: '',
                                isPublic: true,
                                suggestedAIModel: '',
                                documentDisplayPermission: 0,
                                agentType: '',
                            }}
                            description={true}
                            onClickRedirect={false}
                            onClick={() => {
                            }}
                        />
                    )
                })}
            </div>

        </div>
    )
}

const agentsConfig = [
    {
        name: 'Law AI Expert',
        shortDescription: 'Solve legal problems with AI',
        logoUrl: 'FaGavel', // Icon for law
        logoColorHex: '#b40c0c', // Red
    },
    {
        name: 'Health AI Expert',
        shortDescription: 'AI solutions for healthcare',
        logoUrl: 'FaHeartbeat', // Icon for healthcare
        logoColorHex: '#0CB46C', // Green
    },
    {
        name: 'Finance AI Expert',
        shortDescription: 'Financial insights powered by AI',
        logoUrl: 'FaChartLine', // Icon for finance
        logoColorHex: '#B09A51', // Gold
    },
    {
        name: 'Education AI Expert',
        shortDescription: 'Enhancing learning with AI',
        logoUrl: 'FaGraduationCap', // Icon for education
        logoColorHex: '#6A0DAD', // Purple
    },
    {
        name: 'Tech Support AI Expert',
        shortDescription: 'AI-driven technical support',
        logoUrl: 'FaTools', // Icon for tech support
        logoColorHex: '#0C6CB4', // Blue
    },
    {
        name: 'Retail AI Expert',
        shortDescription: 'Revolutionizing retail with AI',
        logoUrl: 'FaShoppingCart', // Icon for retail
        logoColorHex: '#ED1660', // Pink
    },
    {
        name: 'Marketing AI Expert',
        shortDescription: 'Transform marketing with AI',
        logoUrl: 'FaBullhorn', // Icon for marketing
        logoColorHex: '#FFEB08', // Yellow
    },
    {
        name: 'Manufacturing AI Expert',
        shortDescription: 'Optimize manufacturing with AI',
        logoUrl: 'FaIndustry', // Icon for manufacturing
        logoColorHex: '#D18B95', // Rose Gold
    },
    {
        name: 'Cybersecurity AI Expert',
        shortDescription: 'Strengthen security with AI',
        logoUrl: 'FaShieldAlt', // Icon for cybersecurity
        logoColorHex: '#F5A302', // Orange
    },
    {
        name: 'Agriculture AI Expert',
        shortDescription: 'Innovative farming solutions with AI',
        logoUrl: 'FaTractor', // Icon for agriculture
        logoColorHex: '#4CBB17', // Kelly Green
    },
    {
        name: 'Logistics AI Expert',
        shortDescription: 'Smarter logistics through AI',
        logoUrl: 'FaTruckMoving', // Icon for logistics
        logoColorHex: '#4682B4', // Steel Blue
    },
    {
        name: 'Human Resources AI Expert',
        shortDescription: 'AI for HR and workforce management',
        logoUrl: 'FaUserTie', // Icon for human resources
        logoColorHex: '#FF6347', // Tomato
    },
    {
        name: 'Real Estate AI Expert',
        shortDescription: 'AI-driven real estate insights',
        logoUrl: 'FaBuilding', // Icon for real estate
        logoColorHex: '#DEB887', // Burlywood
    },
    {
        name: 'Entertainment AI Expert',
        shortDescription: 'AI enhancing media and entertainment',
        logoUrl: 'FaFilm', // Icon for entertainment
        logoColorHex: '#800080', // Purple
    },
    {
        name: 'Environmental AI Expert',
        shortDescription: 'AI for environmental monitoring and protection',
        logoUrl: 'FaEnvira', // Font Awesome icon for environmental themes
        logoColorHex: '#228B22', // Forest Green, representing environmental focus
    },
    {
        name: 'Space Exploration AI Expert',
        shortDescription: 'AI driving advancements in space exploration',
        logoUrl: 'FaRocket', // Font Awesome icon for space and rockets
        logoColorHex: '#483D8B', // Dark Slate Blue, evoking the vastness of space
    },
]

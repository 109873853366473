import './SidebarItem.scss'
import * as FaIcons from 'react-icons/fa'
import React from 'react'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLink } from 'react-icons/ai'
import { IconType } from 'react-icons'

interface SidebarItemInterface {
    id: string,
    onClick: any,
    item: any,
    type: boolean,
    selectedItem: any,
    setSelectedItem: any,
}

export const SidebarItem = ({
                                id,
                                onClick,
                                item,
                                type,
                                selectedItem,
                                setSelectedItem,
                            }: SidebarItemInterface) => {
    const navigate = useNavigate()
    const icons = FaIcons as { [key: string]: IconType }
    const CurrentIconComponent = item?.logoUrl ? icons[item?.logoUrl] : icons['FaRobot']

    return (
        <div
            className={selectedItem !== item.id ? 'chat-sidebar-item' : 'chat-sidebar-item chat-sidebar-item-selected'}
            id={id}
            onClick={onClick}
        >
            {item.img ?
                <img src={require('../../../../Images/Logo/Agents/' + item.img)} alt={item.name} />
                :
                (type ?
                        <CurrentIconComponent className='chat-sidebar-item-icon'
                                 style={{ color: item.logoColorHex }} />
                        :
                        <CurrentIconComponent className='chat-sidebar-item-icon'
                                       style={{ color: item.logoColorHex }} />
                )}
            <h1>{item.name}</h1>
            {selectedItem === item.id && <div className='chat-sidebar-item-expand'>
                {item.shortDescription}
            </div>}
            {selectedItem === item.id && <AiOutlineLink className='chat-sidebar-item-expand-button-redirect'
                                                        onClick={() => navigate('/agents/' + item.id)} />}

            <MdOutlineKeyboardArrowUp className='chat-sidebar-item-expand-button'
                                      id={selectedItem === item.id ? '' : 'rotate'} onClick={(e) => {
                e.stopPropagation()
                if (selectedItem === item.id) {
                    setSelectedItem(null)
                } else {
                    setSelectedItem(item.id)
                }
            }}
            />
        </div>
    )
}
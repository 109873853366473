import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import 'react-color-palette/css'

import './Settings.scss'
import {
    changePermissonForDocumentAccess,
    deleteExpert,
    updateExpert,
    updateSummarizer,
} from '../../../../Requests/agentRequests'
import { useNavigate } from 'react-router-dom'
import { ConfirmModal, Modal } from '../../../../Components/Modal/Modal'
import TextareaAutosize from 'react-textarea-autosize'
import { ErrorType } from '../../../../Utils/enums'
import * as FaIcons from 'react-icons/fa'
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'
import { agentIconType } from '../../../../Interfaces/agentsInterfaces'
import AgentIcon from '../../../../Utils/CustomAgentIcon'
import Dropdown from '../../../../Components/Dropdown/Dropdown'
import {
    assignExpertToOrganization,
    getMyOrganizations,
    unassignExpertFromOrganization,
} from '../../../../Requests/organizationsRequests'
import { OrganizationInterface } from '../../../../Interfaces/organizationInterfaces'
import { AlertSeverity, AlertType } from '../../../../Interfaces/alertsInterfaces'

interface SettingsInterface {
    agent: any;
    setAgent: any;
    type: string;
    sendAlert: Function;
}
//TODO: Fix changing to public settings
export const Settings = ({ agent, setAgent, type, sendAlert}: SettingsInterface) => {
    const dropdownItems = [
        {id: 0, name: 'Private'},
        {id : 1, name: 'Organization'},
        {id : 2, name: 'Public'}
    ]

    const [perms, setPerms] = useState<any>(dropdownItems[0])
    const [editing, setEditing] = useState(false)
    const [colorModal, setColorModal] = useState(false)
    const [iconsModal, setIconsModal] = useState(false)
    const [icon, setIcon] = useState<any>(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [transferModal, setTransferModal] = useState(false)
    const [color, setColor] = useState('#ffffff')
    const [descriptionLen, setDescriptionLen] = useState<number>(0)
    const [shortDescriptionLen, setShortDescriptionLen] = useState<number>(0)
    const navigate = useNavigate()
    const nameRef = React.useRef(null)
    const descriptionRef = React.useRef(null)
    const shortDescriptionRef = React.useRef(null)
    const [tags, setTags] = useState<string[]>([])
    const [addingTag, setAddingTag] = useState<boolean>(false)
    const tagInputRef = useRef(null)
    const [organizations, setOrganizations] = useState<OrganizationInterface[]>([])
    const defaultOrganization: OrganizationInterface = {
        id: '',
        name: 'None',
        contactEmail: '',
        members: [],
        //organizationOwnerId: '',
        assignedExpertsIds: [],
    }

    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInterface>(defaultOrganization)
    const deleteAgent = () => {
        deleteExpert(agent.id).then((res) => {
            navigate('/developer/agents')
        })
    }

    useEffect(() => {
        if (agent) {
            // @ts-ignore
            descriptionRef.current.value = agent.description
            // @ts-ignore
            shortDescriptionRef.current.value = agent.shortDescription
            // @ts-ignore
            nameRef.current.value = agent.name
            setColor(agent.logoColorHex)
            setTags(agent.tags)
            // @ts-ignore
            const iconComponent = FaIcons[agent.logoUrl]
            setPerms(dropdownItems[agent.documentDisplayPermission])
        }
    }, [agent])

    useEffect(() => {
        if (transferModal && organizations.length === 0) {
            getMyOrganizations().then((res) => {
                setOrganizations(res)
            })
        }
    }, [organizations, transferModal])
    const updateAgent = () => {
        // @ts-ignore
        let name = nameRef.current.value
        // @ts-ignore
        let description = descriptionRef.current.value
        // @ts-ignore
        let shortDescription = shortDescriptionRef.current.value
        if (name === agent.name) {
            name = null
        }
        if (description === '') {
            description = null
        }
        if (shortDescription === '') {
            shortDescription = null
        }
        const newColor = color === agent?.logoColorHex ? null : color
        if (type === 'expert') {
            updateExpert({
                id: agent.id,
                name: name,
                shortDescription: shortDescription,
                description: description,
                tags: tags,
                color: newColor,
                sysPrompt: agent.systemPrompt,
                userPrompt: agent.userPrompt,
                logoUrl: icon,
            }).then((res) => {
                if (!Object.values(ErrorType).includes(res)) {
                    setAgent(res)   //TODO: ?
                    setEditing(false)
                    changePermissonForDocumentAccess(agent.id, perms.id.toString()).then((r) => {
                        setAgent(r)
                    })
                }
            })
        } else {
            updateSummarizer({
                id: agent.id,
                name: name,
                shortDescription: shortDescription,
                description: description,
                tags: tags,
                color: newColor,
                sysPrompt: agent.systemPrompt,
                userPrompt: agent.userPrompt,
            }).then((res) => {
                if (!Object.values(ErrorType).includes(res)) {
                    setAgent(res)
                    setEditing(false)
                    changePermissonForDocumentAccess(agent.id, perms.id.toString()).then((r) => {
                        setAgent(r)
                    })
                }
            })
        }
    }

    type IconProps = {
        className?: string
        onClick?: () => void
    }

    const icons = FaIcons as agentIconType

    const swapVisibility = () => {

    }

    const assignToOrganization = () => {
        assignExpertToOrganization(selectedOrganization.id, agent.id).then((res) => {
            sendAlert(AlertType.UNKNOWN, 'Agent assigned to organization', AlertSeverity.SUCCESS)
            setTransferModal(false)
        }).catch(e => {
            sendAlert(AlertType.UNKNOWN, 'Agent assign failed', AlertSeverity.ERROR)
        })
    }

    const unassignFromOrganization = () => {
        const id = organizations.find(org => org.id === agent.assignedOrganizationId)?.id
        unassignExpertFromOrganization(id ? id : '', agent.id).then((res) => {
            sendAlert(AlertType.UNKNOWN, 'Agent unassigned from organization', AlertSeverity.SUCCESS)
            setTransferModal(false)
        }).catch((e) => {
            sendAlert(AlertType.UNKNOWN, 'Agent unassign failed', AlertSeverity.ERROR)
        })
    }
    return (
        <div className='settings' onClick={() => setColorModal(false)}>
            {deleteModal && <ConfirmModal
                close={() => setDeleteModal(false)}
                description={'Are you sure you want to delete this agent? This action is irreversible.'}
                title={'Delete agent'}
                proceed={() => deleteAgent()}
            />}
            {transferModal && <Modal close={() => setTransferModal(false)} className='transferModal'>
                <h1>Transfer ownership</h1>
                <p>Change visibility</p>
                <button>Switch to {agent.isPublic ? 'private' : 'public'}</button>
                <p>Transfer to organization</p>
                {!agent.assignedOrganizationId && <div className='transferModal-organization'>
                    <Dropdown<OrganizationInterface> selected={selectedOrganization}
                                                     setSelected={setSelectedOrganization}
                                                     options={organizations} displayAttribute='name' />
                    <button onClick={() => {
                        assignToOrganization()
                    }}>Transfer
                    </button>
                </div>}
                {agent.assignedOrganizationId && <div className='transferModal-organization transferModal-organization-remove'>
                    <button onClick={() => {
                        unassignFromOrganization()
                    }}>Remove from organization: {organizations.find(org => org.id === agent.assignedOrganizationId)?.name}
                    </button>
                </div>
                }
            </Modal>}
            <h1 className='settings-title'>Agent settings</h1>
            <span />
            <div className='settings-info'>
                <div className='settings-info-item' id={'info-name'}>
                    <h1>Name</h1>
                    <input className='settings-info-item-input' placeholder={agent?.name} ref={nameRef} disabled={agent?.systemPrompt === undefined}/>
                </div>
                <span />
                <div className='settings-info-item' id={'info-shortDescription'}>
                    <h1>Short description</h1>
                    <TextareaAutosize minRows={3} maxRows={8} className='settings-info-item-input'
                                      placeholder={agent?.shortDescription} ref={shortDescriptionRef}
                                      maxLength={160} disabled={agent?.systemPrompt === undefined} onChange={(e) => {
                        setShortDescriptionLen(e.target.value.length)
                    }}
                    />
                    <p className='settings-info-item-charcount'>{shortDescriptionLen}/160</p>
                </div>
                <span />
                <div className='settings-info-item' id={'info-description'}>
                    <h1>Description</h1>
                    <TextareaAutosize minRows={5} maxRows={8} className='settings-info-item-input'
                                      placeholder={agent?.description} disabled={agent?.systemPrompt === undefined} ref={descriptionRef}
                                      maxLength={1000} onChange={(e) => {
                        // @ts-ignore
                        setDescriptionLen(e.target.value.length)
                    }}
                    />
                    <p className='settings-info-item-charcount'>{descriptionLen}/1000</p>
                </div>
                {agent?.systemPrompt !== undefined && <span />}
                {agent?.systemPrompt !== undefined && <div className='settings-info-item' id={'info-name'}>
                    <h1>Tags</h1>
                    <div className='settings-info-item-tags'>
                        {tags.map((tag, index) => (
                            <div className='settings-info-item-tags-tag' key={index}>
                                <p>{tag}</p>
                                <AiOutlineClose
                                    className='settings-info-item-tags-tag-delete'
                                    onClick={() => {
                                        const newTags = [...tags]
                                        newTags.splice(index, 1)
                                        setTags(newTags)
                                    }} />
                            </div>
                        ))}
                        {!addingTag &&
                            <div className='settings-info-item-tags-tag settings-info-item-tags-tag-add'
                                 onClick={() => {
                                     setAddingTag(true)
                                 }}>
                                <AiOutlinePlus />
                            </div>}
                        {addingTag && <div className='settings-info-item-tags-tag'>
                            <input className='settings-info-item-tags-input' placeholder='Add tag'
                                   ref={tagInputRef} />
                            <AiOutlineClose
                                className='settings-info-item-tags-tag-deleteCreating'
                                onClick={() => {
                                    setAddingTag(false)
                                }} />
                            <AiOutlineCheck
                                className='settings-info-item-tags-tag-confirm'
                                onClick={() => {
                                    const newTags = [...tags]
                                    // @ts-ignore
                                    newTags.push(tagInputRef.current.value)
                                    setTags(newTags)
                                    setAddingTag(false)
                                }} />
                        </div>}
                    </div>
                </div>}
                {agent?.systemPrompt !== undefined && <span />}
                {agent?.systemPrompt !== undefined && <div className='settings-info-item' id={'info-color'}>
                    <h1>Agent icon</h1>
                    <div className='settings-info-item-color-wrapper'>
                        <h1>Color </h1>
                        <div className='settings-info-item-color'
                             style={{ background: color }}
                             onClick={(e) => {
                                 e.stopPropagation()
                                 setColorModal(true)
                             }}>
                            {colorModal && <SketchPicker
                                className='settings-info-item-color-picker'
                                color={color}
                                onChangeComplete={(color) => {
                                    setColor(color.hex)
                                }}
                            />
                            }
                        </div>
                    </div>
                    <div className='settings-info-item-color-icons'>
                        <h1>Icon </h1>
                        <AgentIcon agent={agent} className='settings-info-item-color-icons-current'
                                   onClick={() => setIconsModal(!iconsModal)}
                        />
                        {iconsModal && <div className='settings-info-item-color-icons-modal'>
                            <div className='settings-info-item-color-icons-modal-topbar'>
                                <input />
                            </div>
                            {
                                Object.keys(FaIcons).map((iconName: string) => {
                                    const IconComponent = icons[iconName]
                                    return <IconComponent key={iconName}
                                                          onClick={() => {
                                                              setIcon(iconName)
                                                              setIconsModal(false)
                                                          }}
                                                          className='settings-info-item-color-icons-modal-icon' />
                                })
                            }
                        </div>}
                    </div>
                    <span />
                </div>}
                { agent?.systemPrompt !== undefined && <div className='settings-documentPerms'>
                    <h1>Document visibility</h1>
                        <Dropdown selected={perms} 
                                setSelected={setPerms}
                                options={dropdownItems}
                                displayAttribute='name' />
                </div>}
                {agent?.systemPrompt !== undefined && <span />}
                {agent?.systemPrompt !== undefined && <button className='settings-update' onClick={() => {
                    updateAgent()
                }}>Save
                </button>}
            </div>
            {agent?.systemPrompt !== undefined && <>
            <h1 className='settings-danger-title'>Danger zone</h1>
            <span />
            <div className='settings-danger-item'>
                <h1>Delete agent</h1>
                <button onClick={() => setDeleteModal(true)}>Delete</button>
            </div>
            <div className='settings-danger-item'>
                <h1>Transfer ownership</h1>
                <button onClick={() => setTransferModal(true)}>Transfer</button>
            </div>
            </>}
        </div>
    )
}
import axios from 'axios'
import { ErrorType } from './enums'
import { AlertSeverity, AlertType } from '../Interfaces/alertsInterfaces'

const apiGet = async (url, data = null, query_params = false, jwt = null) => {
    let headers
    if (jwt) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'accept': '*/*',
        }
    } else {
        headers = {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    }
    if (!query_params) {
        const response = await axios
            .get(
                process.env.REACT_APP_API_URL + url,
                {
                    headers: headers,
                })
            .catch((error) => {
                throw error
            })
        return await response.data
    } else {
        const response = await axios
            .get(
                process.env.REACT_APP_API_URL + url,
                {
                    params: data,
                    headers: headers,
                },
            )
            .catch((error) => {
                throw error
            })
        return await response.data
    }
}

const apiPost = async (url, data, query_params = false, jwt = null, content_type = null) => {
    let headers
    if (jwt) {
        headers = {
            'Content-Type': content_type ? content_type : 'application/json',
            'Authorization': `Bearer ${jwt}`,
        }
    } else {
        headers = {
            'Content-Type': 'application/json',
        }
    }
    if (!query_params) {
        const response = await axios
            .post(process.env.REACT_APP_API_URL + url, data, { headers: headers })
            .catch((error) => {
                throw error
            })
        return response.data
    } else {
        const response = await axios
            .post(
                process.env.REACT_APP_API_URL + url,
                null,
                {
                    params: data,
                    headers: headers,
                },
            )
            .catch((error) => {
                throw error
            })
        return await response.data
    }
}

const apiPut = async (url, data, query_params = false, jwt = null) => {
    let headers
    if (jwt) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
        }
    } else {
        headers = {}
    }
    if (!query_params) {
        const response = await axios
            .put(
                process.env.REACT_APP_API_URL + url,
                data,
                {
                    headers: headers,
                })
            .catch((error) => {
                throw error
            })
        return response.data
    } else {
        const response = await axios
            .put(
                process.env.REACT_APP_API_URL + url,
                null,
                {
                    params: data,
                    headers: headers,
                },
            )
            .catch((error) => {
                throw error
            })
        return await response.data
    }
}


const apiDelete = async (url, data = null, query_params = false, jwt = null) => {
    let headers
    if (jwt) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
        }
    } else {
        headers = {}
    }
    const response = await axios
        .delete(
            process.env.REACT_APP_API_URL + url,
            {
                params: data,
                headers: headers,
            },
        )
        .catch((error) => {
            const status = error.response.status
            if (status === 401) {
                console.log('Unauthorized')
                return ErrorType.UNAUTHORIZED
            }
        })
    return await response.data
}

const handleError = (error, sendAlert, message) => {
    console.log('HANDLING ERROR:', error)
    if (error.response && error.response.status) {
        if (error.response.status === 500) {
            sendAlert(
                AlertType.INTERNAL_SERVER_ERROR,
                message ? message : 'Unknown error occurred. Please try again later.',
                AlertSeverity.ERROR,
            )
        }
        if (error.response.status === 404) {
            sendAlert(
                AlertType.UNKNOWN,
                message ? message : 'Resource not found.',
                AlertSeverity.ERROR,
            )
        }
        if (error.response.status === 401) {
            sendAlert(
                AlertType.UNAUTHORIZED,
                message ? message : 'Unauthorized access.',
                AlertSeverity.ERROR,
            )
        }
        if (error.response.status === 400) {
            sendAlert(
                AlertType.UNKNOWN,
                message ? message : 'Bad request.',
                AlertSeverity.ERROR,
            )
        }
    }
}

export { apiGet, apiPost, apiDelete, apiPut, handleError }

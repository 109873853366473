import './DocumentsSecurity.scss'
import React from 'react'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { BiLock } from 'react-icons/bi'

interface DocumentSecurityInterface {
    hovered?: boolean;
}

export const DocumentsSecurity = ({ hovered }: DocumentSecurityInterface) => {

    const documents = ['#D18B95', '#F5A302', '#B09A51', '#FFEB08', '#ED1660', '#6A0DAD', '#b40c0c', '#D18B95', '#F5A302', '#B09A51']

    return (
        <div className="staticListPoint-children">
            <div className={hovered ?
                'documentSecurity documentSecurity-hovered' :
                'documentSecurity'}>
                <div className="documentSecurity-inside">
                    <img src={require('../../../../../Images/Logo/APPI_icon_white.png')} alt={'appi'} />
                    {documents.map((item, index) => (
                        <IoDocumentTextOutline key={index} id={'doc' + index}
                                               style={hovered ? { color: item } : {}}
                        />
                    ))}
                </div>
                <BiLock className="documentSecurity-lock" />

            </div>
        </div>
    )
}
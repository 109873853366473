import { Action, createHook, createStore } from 'react-sweet-state'
import { UserInterface } from './Interfaces/userInterfaces'

interface GlobalStateInterface {
    user: UserInterface | null
    developer: boolean
    hasOrganizations: boolean
}


type SetUserAction = Action<GlobalStateInterface, UserInterface | null>;
type SetDeveloperAction = Action<GlobalStateInterface, boolean>;
type SetHasOrganizationsAction = Action<GlobalStateInterface, boolean>;

const Store = createStore<GlobalStateInterface, {
    setUser: (user: UserInterface | null) => SetUserAction,
    setDeveloper: (developer: boolean) => SetDeveloperAction,
    setHasOrganizations: (hasOrganizations: boolean) => SetHasOrganizationsAction,
}>({
    initialState: {
        user: null,
        developer: false,
        hasOrganizations: false,
    },
    actions: {
        setUser: (user: UserInterface | null): SetUserAction => ({ setState }) => {
            setState({ user: user })
        },
        setDeveloper: (developer: boolean): SetDeveloperAction => ({ setState }) => {
            setState({ developer: developer })
        },
        setHasOrganizations: (hasOrganizations: boolean): SetHasOrganizationsAction => ({ setState }) => {
            setState({ hasOrganizations: hasOrganizations })
        },
    },
    name: 'globalState',
})

export const useGlobalState = createHook(Store)
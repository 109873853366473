import React from 'react'
import './InformationBlock.scss'

function hexToRGBA(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const InformationBlock = ({ bg, title, children }: { bg: string, title?: string, children?: any }) => {
    const pastelBg = hexToRGBA(bg, 0.2)
    const bgLight = hexToRGBA(bg, 0.1)
    return (
        <div className='informationBlock' style={{
            background: `linear-gradient(-135deg, ${pastelBg}, ${bgLight})`,
            // border: `1px solid ${bg}`,
        }}>
            <div className='informationBlock-title' style={{
                color: bg,
            }}>
                {title}
            </div>
            {children}
        </div>
    )

}

export default InformationBlock
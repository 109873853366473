import React from 'react'
import { SidebarItem } from '../SidebarItem/SidebarItem'
import { SearchBar } from '../SearchBar'
import { useGlobalState } from '../../../../GlobalStateContext'
import { AiFillStar } from 'react-icons/ai'


interface SidebarSummarizersInterface {
    summarizers_list: any,
    summarizer: any,
    setSummarizer: any,
    selectedItem: any,
    setSelectedItem: any,
}

export const SidebarSummarizers = ({
                                       summarizers_list,
                                       summarizer,
                                       setSummarizer,
                                       selectedItem,
                                       setSelectedItem,
                                   }: SidebarSummarizersInterface) => {
    const [summarizer_list_filtered, setSummarizer_list_filtered] = React.useState<any>(null)

    const [globalState, globalActions] = useGlobalState()

    return (
        <div className='chat-sidebar-summarizer'>
            <h1 className='chat-sidebar-titles'>Summarizers</h1>
            <SearchBar
                id='summarizer'
                agents_list={summarizer_list_filtered}
                setAgents_list_filtered={setSummarizer_list_filtered}
            />
            {globalState.user?.favouriteSummarizers.length !== 0 && <div className='line-wrapper'>
                <h1>Favourites</h1>
            </div>}
            {!summarizer_list_filtered && <>
                {globalState.user?.favouriteSummarizers?.map((summarizer_item: any, index: number) => (
                    <SidebarItem
                        id={summarizer?.id === summarizer_item?.id ? 'summarizer-item-selected' : 'summarizer-item'}
                        onClick={() => {
                            if (summarizer === summarizer_item) {
                                setSummarizer(null)
                            } else {
                                setSummarizer(summarizer_item)
                            }
                        }}
                        item={summarizer_item}
                        type={false}
                        key={index}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                ))}
                {globalState.user?.favouriteSummarizers.length !== 0 && <div className='line-wrapper'>
                    <AiFillStar />
                    <div className='line' />
                    <AiFillStar />
                </div>}
                {summarizers_list.map((summarizer_item: any, index: number) => (
                    <SidebarItem
                        id={summarizer?.id === summarizer_item?.id ? 'summarizer-item-selected' : 'summarizer-item'}
                        onClick={() => {
                            if (summarizer === summarizer_item) {
                                setSummarizer(null)
                            } else {
                                setSummarizer(summarizer_item)
                            }
                        }}
                        item={summarizer_item}
                        type={false}
                        key={index}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                ))}
            </>
            }
            {summarizer_list_filtered && summarizer_list_filtered.map((summarizer_item: any, index: number) => (
                <SidebarItem
                    id={summarizer === summarizer_item ? 'summarizer-item-selected' : 'summarizer-item'}
                    onClick={() => {
                        if (summarizer === summarizer_item) {
                            setSummarizer(null)
                        } else {
                            setSummarizer(summarizer_item)
                        }
                    }}
                    item={summarizer_item}
                    type={false}
                    key={index}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
            ))}
        </div>
    )
}
import './OrganizationTeam.scss'
import React, { useState } from 'react'
import DashboardSearchPage from '../../../../Components/Dashboard/DashboardSearchPage/DashboardSearchPage'
import { ProfileComponent } from '../ProfileComponent/ProfileComponent'
import { OrganizationInterface } from '../../../../Interfaces/organizationInterfaces'

interface OrganizationTeamInterface {
    organization: OrganizationInterface,
    userRole: string
    initialEmployee: number | null;
}

export const OrganizationTeam = ({ organization, userRole, initialEmployee }: OrganizationTeamInterface) => {
    const [selectedMember, setSelectedMember] = useState<number | null>(initialEmployee)
    const [filter, setFilter] = useState('')
    const [previewOpen, setPreviewOpen] = useState(initialEmployee !== null)

    const filteredTeam = organization.members.filter(member =>
        member.name.toLowerCase().includes(filter.toLowerCase()) ||
        member.role.toLowerCase().includes(filter.toLowerCase()),
    )

    const handleClick = (index: number) => {
        if (selectedMember === index && previewOpen === true) setPreviewOpen(false);
        else setPreviewOpen(true);
        setSelectedMember(index);
    }

    return (
        <div className='overview-content-organizationTeam'>
            <DashboardSearchPage title='Team' className='organization-team' filter={filter} setFilter={setFilter}>
                <div className='overview-content-organizationTeam-members'>
                    {filteredTeam.map((member, index) => {
                        return (
                            <div key={index}
                                 className='overview-content-organizationTeam-member'
                                 onClick={() => {handleClick(index)}}>
                                <h2 className='overview-content-organizationTeam-member-name'>{member.name + " " + member.surname}</h2>
                                <p className='overview-content-organizationTeam-member-role'>{member.role}</p>
                            </div>
                        )
                    })}
                </div>
            </DashboardSearchPage>
            {previewOpen && selectedMember !== null && <div className='overview-content-organizationTeam-preview'>
                <ProfileComponent key={organization.members[selectedMember].id} employee={organization.members[selectedMember]} organization={organization} userRole={userRole}/>
            </div>}
        </div>
    )
}
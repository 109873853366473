import './Alerts.scss'
import { Alert, AlertSeverity } from '../../Interfaces/alertsInterfaces'
import { GoAlert } from 'react-icons/go'
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai'
import { BiErrorAlt } from 'react-icons/bi'

interface AlertsInterface {
    alerts: Alert[]
    setAlerts: Function
}

export const Alerts = ({ alerts, setAlerts }: AlertsInterface) => {

    const close = (targetAlert: Alert) => {
        const filteredAlerts = alerts.filter(alert => alert !== targetAlert)
        setAlerts(filteredAlerts)
    }
    return (
        <div className='alerts'>
            {alerts.map((alert: Alert, index: number) => (
                <div key={index} className='alerts-alert' id={alert.severity}>
                    {
                        alert.severity === AlertSeverity.ERROR && <>
                            <BiErrorAlt className='alerts-alert-icon' />
                            <h1 className='alerts-alert-header'>Error: <span
                                className='alerts-alert-header-highlight'>{alert.message}</span></h1>
                        </>
                    }
                    {
                        alert.severity === AlertSeverity.SUCCESS && <>
                            <AiOutlineCheckCircle className='alerts-alert-icon' />
                            <h1 className='alerts-alert-header'>Success: <span
                                className='alerts-alert-header-highlight'>{alert.message}</span></h1>
                        </>
                    }
                    {
                        alert.severity === AlertSeverity.INFO && <>
                            <AiOutlineInfoCircle className='alerts-alert-icon' />
                            <h1 className='alerts-alert-header'>Info: <span
                                className='alerts-alert-header-highlight'>{alert.message}</span></h1>
                        </>
                    }
                    {
                        alert.severity === AlertSeverity.WARNING && <>
                            <GoAlert className='alerts-alert-icon' />
                            <h1 className='alerts-alert-header'>Warning: <span
                                className='alerts-alert-header-highlight'>{alert.message}</span></h1>
                        </>
                    }

                    <AiOutlineClose className='alerts-alert-icon alerts-alert-icon-close'
                                    onClick={() => close(alert)} />
                </div>
            ))}
        </div>
    )
}
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import RouterWrapper from './Router'
import { HelmetProvider } from 'react-helmet-async'
import './i18n'

const App = () => {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <RouterWrapper />
            </BrowserRouter>
        </HelmetProvider>
    )
}

export default App

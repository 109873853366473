import { Route, Routes, useNavigate } from 'react-router-dom'
import Home from './Pages/Landing/LandingWrapper/LandingWrapper'
import Navbar from './Components/Navbar/Navbar'
import NotFound from './Pages/Static/NotFound/NotFound'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { FakeChat } from './Pages/Static/FakeChat/FakeChat'
import Login from './Pages/Login/Login'
import UserDashboard from './Pages/UserDashboard/UserDashboard'
import Agents from './Pages/Agents/Agents'
import Application from './Pages/Application/Application'
import { DevProfile } from './Pages/Developer/DevProfile/DevProfile'
import { Alert, AlertSeverity, AlertType } from './Interfaces/alertsInterfaces'
import { Alerts } from './Components/Alerts/Alerts'
import Cookies from 'js-cookie'
import { getMyDeveloperAccount, getMyUserAccount } from './Requests/userRequests'
import { useGlobalState } from './GlobalStateContext'
import { MyAgents } from './Pages/Developer/MyAgents/MyAgents'
import { CreateNewAgent } from './Pages/Developer/CreateNewAgent/CreateNewAgent'
import { Verify } from './Pages/Utils/Verify'
import Pricing from './Pages/Pricing/Pricing'
import { Feedback } from './Pages/Static/Feedback/Feedback'
import { DeveloperInterface, UserInterface } from './Interfaces/userInterfaces'
import Organization from './Pages/Organization/Organization'
import { SolutionsBusinesses } from './Pages/Static/Solutions/SolutionsBusinesses/SolutionsBusinesses'
import { SolutionsIndividual } from './Pages/Static/Solutions/SolutionsIndividual/SolutionsIndividual'
import { Labs } from './Pages/Static/Solutions/Labs/Labs'
import { CustomAgents } from './Pages/Static/Solutions/CustomAgents/CustomAgents'
import { Contact } from './Pages/Static/Contact/Contact'
import { ChatContextProvider } from './Pages/Chat/ChatPropsManager'
import { PdfViewer } from './Pages/Static/PdfViewer/PdfViewer'
import { OrganizationSelector } from './Pages/Organization/OrganizationSelector/OrganizationSelector'
import { OrganizationCreate } from './Pages/Organization/OrganizationCreate/OrganizationCreate'
import { Playground } from './Pages/Playground/Playground'
import { getMyOrganizations } from './Requests/organizationsRequests'
import { Guide } from './Pages/Static/Solutions/Guide/Guide'
import { ProductFeatures } from './Pages/Static/Product/Features'
import { EarlyPartners } from './Pages/EarlyPartners/EarlyPartners'
import { SolutionsEnterprise } from './Pages/Static/Solutions/Enterprise/Enterprise'

const privacyPolicy = require('./Files/blank.pdf')
const cookiePolicy = require('./Files/blank.pdf')

const RouterWrapper = () => {
    const navigate = useNavigate()
    const [subdomain, setSubdomain] = useState<string>('')
    const [loggedIn, setLoggedIn] = useState(false)
    const [alerts, setAlerts] = useState<Alert[]>([])
    const [globalState, globalActions] = useGlobalState()

    useEffect(() => {
        const subdomain = window.location.hostname.split('.')[0]
        if (subdomain === 'chat' || subdomain === 'www' || subdomain === '')
            setSubdomain(subdomain)
        const token = Cookies.get('jwt')
        if (token && token !== 'undefined') {
            const token_decoded = jwtDecode(token) as { [key: string]: any }
            if (token_decoded === undefined || token_decoded['exp'] === undefined) {
                console.log('Token invalid')
                Cookies.remove('jwt')
            } else {
                const exp = token_decoded['exp'] * 1000
                if (exp && exp > Date.now()) {
                    if (token_decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Developer') === true)
                        globalActions.setDeveloper(true)
                } else {
                    console.log('Token expired')
                    globalActions.setDeveloper(false)
                    globalActions.setHasOrganizations(false)
                    globalActions.setUser(null)
                    Cookies.remove('token')
                    setLoggedIn(false)
                    return
                }
            }
            setLoggedIn(true)
        } else {
            console.log('Token not found')
            globalActions.setDeveloper(false)
            globalActions.setHasOrganizations(false)
            globalActions.setUser(null)
            Cookies.remove('jwt')
            setLoggedIn(false)
        }
    }, [Cookies.get('jwt'), subdomain])

    const sendAlert = (type: AlertType, message: string, severity: AlertSeverity) => {
        const newAlert: Alert = {
            'type': type,
            'message': message,
            'severity': severity,
        }
        setAlerts([...alerts, newAlert])
        setTimeout(() => {
            const filteredAlerts = alerts.filter(alert => alert !== newAlert)
            setAlerts(filteredAlerts)
        }, 3000)
    }

    const handleError = (error: any) => {
        const status = error.response.status
        if (status === 500)
            sendAlert(AlertType.INTERNAL_SERVER_ERROR, 'An unknown error has occured', AlertSeverity.ERROR)
    }

    useEffect(() => {
        console.log('Logged in:', loggedIn)
        if (loggedIn) {
            let temp_user: UserInterface | DeveloperInterface | null = null
            getMyUserAccount().then((res) => {
                temp_user = res
                if (globalState.developer) {
                    getMyDeveloperAccount().then((res) => {
                        temp_user = { ...temp_user, ...res }
                    })
                }
                globalActions.setUser(temp_user)
            })
            getMyOrganizations().then((res) => {
                if (res.length > 0) globalActions.setHasOrganizations(true)
            })
        }
    }, [loggedIn, globalState.developer])
    const RedirectIfNotChat = ({ subdomain }: { subdomain: string }) => {
        useEffect(() => {
            if (subdomain !== 'chat') {
                window.location.href = `http://chat.${process.env.REACT_APP_APPI_URL}`
            }
        }, [subdomain])
        return null
    }
    return (
        <div>
            <Navbar loggedIn={loggedIn} subdomain={subdomain} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Routes>
                {subdomain !== 'chat' &&
                    <Route path="/" element={<Home sendAlert={sendAlert} />}></Route>
                }
                {(subdomain === 'chat' && loggedIn) &&
                    <Route path="/" element={<ChatContextProvider sendAlert={sendAlert} />}></Route>
                }
                {(subdomain === 'chat' && !loggedIn) &&
                    <Route path="/" element={<FakeChat />}></Route>
                }
                <Route path="/pricing" element={<Pricing />}></Route>
                <Route path="/chat" element={<RedirectIfNotChat subdomain={subdomain} />} />
                <Route path="/login" element={<Login navigate={navigate} sendAlert={sendAlert} />}></Route>
                <Route path="/agents" element={<Agents />}></Route>
                <Route path="/agents/:id"
                       element={<Application handleError={handleError} sendAlert={sendAlert} />}></Route>
                <Route path="/developer/:id" element={<DevProfile />}></Route>
                {loggedIn && <Route path="/dashboard"
                                    element={<UserDashboard sendAlert={sendAlert} />}></Route>}
                {loggedIn && <Route path="/organization" element={<OrganizationSelector />} />}
                {!loggedIn && <Route path="/organization" element={<div />} />}
                {loggedIn &&
                    <Route path="/organization/create" element={<OrganizationCreate sendAlert={sendAlert} />} />}
                {loggedIn && <Route path="/organization/:id" element={<Organization sendAlert={sendAlert} />} />}
                <Route path="*" element={<NotFound />}></Route>
                <Route path="/developer/agents" element={<MyAgents navigate={navigate} />}></Route>
                <Route path="/developer/agents/new"
                       element={<CreateNewAgent navigate={navigate} sendAlert={sendAlert} />}></Route>
                {/*<Route path="/developer/agents/:id"*/}
                {/*       element={<DevApplication location={location} navigate={navigate}*/}
                {/*                                sendAlert={sendAlert} />}></Route>*/}
                <Route path="/verify" element={<Verify />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/privacy-policy" element={<PdfViewer file={privacyPolicy} />} />
                <Route path="/cookie-policy" element={<PdfViewer file={cookiePolicy} />} />
                {
                    process.env.REACT_APP_DEV === 'true' &&
                    <Route path="/dev-chat" element={<ChatContextProvider sendAlert={sendAlert} />} />
                }
                <Route path="/solutions/enterprise" element={<SolutionsEnterprise />} />
                <Route path="/solutions/businesses" element={<SolutionsBusinesses />} />
                <Route path="/product/features" element={<ProductFeatures />} />
                <Route path="/solutions/individual" element={<SolutionsIndividual />} />
                <Route path="/product/guide" element={<Guide />} />
                <Route path="/labs" element={<Labs />} />
                <Route path="/developer/custom-agents" element={<CustomAgents />} />
                <Route path="/contact" element={<Contact sendAlert={sendAlert} />} />
                <Route path="/organization/:id" element={<Organization sendAlert={sendAlert} />} />
                {
                    process.env.REACT_APP_DEV === 'true' &&
                    <Route path="/dev" element={<Playground />} />
                }
                <Route path="/early-partners" element={<EarlyPartners />} />
            </Routes>
        </div>
    )
}

export default RouterWrapper

import './Features.scss'
import { StaticTitle } from '../Components/StaticTitle/StaticTitle'
import Footer from '../../Landing/Footer/Footer'
import { AnimatedBlob } from '../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { AiExpertF } from './Features/AiExpertF'
import { Title, TitleInterface } from '../../../Components/Title/Title'
import { FaRobot } from 'react-icons/fa6'
import { MultiExpertF } from './Features/MultiExpertF'
import { PiGraph } from 'react-icons/pi'
import { AiOutlineUser } from 'react-icons/ai'
import { CustomProfileF } from './Features/CustomProfileF'
import { DocumentsF } from './Features/DocumentsF'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { SummarizersF } from './Features/SummarizersF'
import { Knowledgebase } from './Features/Knowledgebase'
import Email from '../../Landing/Email/Email'
import { useEffect, useState } from 'react'

const titleConf: TitleInterface = {
    title: 'Explore our',
    highlight: 'features',
    imgPosition: 'top',
}


const descriptions = {
    AiExpert: 'Empower your construction, architecture, and engineering projects with A{P}PI\'s AI Experts: your on-demand, industry-specific knowledge partners',
    MultiExpert: 'Get comprehensive insights by consulting multiple AI Experts across disciplines in a single conversation, with the option to create custom experts for your specific needs.',
    CustomProfile: 'Personalize your AI interactions with Custom Profiles, ensuring responses match your specific context, tone, and objectives for every project or conversation.',
    Documents: 'Unlock key insights from your documents with A{P}PI\'s Document Genius: advanced processing for swift analysis and informed decision-making across your projects.',
    Summarizers: 'Consolidate complex multi-expert insights into clear, concise summaries with A{P}PI\'s AI Summarizer, capturing key points for efficient decision-making.',
    Knowledgebase: 'Empower AI Experts with your own uploaded resources for precise, context-rich answers tailored to your project\'s specific needs and standards.',
}

const useScreenWidth = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 900)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile
}

export const ProductFeatures = () => {
    const isMobile = useScreenWidth()

    return (
        <div className="features-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={10} top={'550vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={11} top={'600vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={12} top={'650vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={13} top={'700vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="features">
                <div className="features-container">

                    <StaticTitle
                        title={titleConf}
                        subtitle={''}
                        buttons={{
                            main: 'Contact us',
                            secondary: 'Read more',
                        }}

                    />
                    <Title subtitle={descriptions.AiExpert} title="AI Experts" imgPosition="top"
                           titleImg={() => <FaRobot />} />
                    {!isMobile && <AiExpertF />}
                    <Title title="Multi-Expert Responses" subtitle={descriptions.MultiExpert} imgPosition="top"
                           titleImg={() => <PiGraph />} />
                    <MultiExpertF />
                    <Title title="Custom Profiles" subtitle={descriptions.CustomProfile} imgPosition="top"
                           titleImg={() => <AiOutlineUser />} />
                    <CustomProfileF />
                    <Title title="Document Processing" subtitle={descriptions.Documents} imgPosition="top"
                           titleImg={() => <IoDocumentTextOutline />} />
                    <DocumentsF />
                    {/* title for summarizers inside the component */}
                    <SummarizersF description={descriptions.Summarizers} />
                    <Title title="Knowledgebase Support" subtitle={descriptions.Knowledgebase} imgPosition="top"
                           titleImg={() => <FaRobot />} />
                    <Knowledgebase />
                    {/*<StaticContactUs title={'Ready to get started?'}*/}
                    {/*                 subtitle={'Supercharge your business, with our solutions. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio'} />*/}

                    <Email sendAlert={() => {
                    }} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

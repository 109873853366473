import React, { useEffect, useState } from 'react'
import './UserDashboard.scss'
import { AiOutlineDollarCircle, AiOutlineLoading, AiOutlineUser } from 'react-icons/ai'
import { IoStatsChartSharp } from 'react-icons/io5'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowDimensions } from '../../Utils/hooks'
import { useGlobalState } from '../../GlobalStateContext'
import SEO from '../../Components/SEO'
import { Account } from './Subpages/Account/Account'
import { Stats } from './Subpages/Stats/Stats'
import { Payment } from './Subpages/Payment/Payment'
import { Dashboard } from '../../Components/Dashboard/Dasbhoard'

enum Page {
    Stats,
    Payment,
    Account,
    DevStats,
    Usage,
}

interface UserDashboardProps {
    sendAlert: Function;
}

const UserDashboard = ({ sendAlert }: UserDashboardProps) => {
    const dims = useWindowDimensions()
    const navigate = useNavigate()
    const mobile = dims.width < 991
    const [searchParams] = useSearchParams()
    const [page, setPage] = useState<Page>(Page.Stats)
    const [sidebar, setSidebar] = useState(false)
    const [globalState] = useGlobalState()

    useEffect(() => {
        const path = searchParams.get('page')
        switch (path) {
            case 'payment':
                setPage(Page.Payment)
                break
            case 'account':
                setPage(Page.Account)
                break
            case 'devstats':
                setPage(Page.DevStats)
                break
            case 'usage':
                setPage(Page.Usage)
                break
            case 'stats':
            default:
                setPage(Page.Stats)
                break
        }
    }, [searchParams])

    const userPages = [
        {
            label: 'Stats',
            icon: <IoStatsChartSharp className='dashboard-sidebar-menu-item-img' />,
            component: <Stats user={globalState.user} />,
        },
        // {
        //     label: 'Usage',
        //     icon: <FaBook className='dashboard-sidebar-menu-item-img' />,
        //     component: <Usage />,
        // },
        {
            label: 'Payment',
            icon: <AiOutlineDollarCircle className='dashboard-sidebar-menu-item-img' />,
            component: <Payment user={globalState.user} />,
        },
        {
            label: 'Account',
            icon: <AiOutlineUser className='dashboard-sidebar-menu-item-img' />,
            component: <Account sendAlert={sendAlert} />,
        },
        // {
        //     label: 'Developer Stats',
        //     icon: <IoStatsChartSharp className='dashboard-sidebar-menu-item-img' />,
        //     component: <div />,
        //     dev: true,
        // },
        {
            label: 'Your agents',
            icon: <IoStatsChartSharp className='dashboard-sidebar-menu-item-img' />,
            component: <div />,
            onClick: () => navigate('/developer/agents'),
            dev: true,
        },
    ]

    if (!globalState.user) {
        return (
            <div className='userDashboard userDashboard-loading'>
                <AiOutlineLoading className='userDashboard-loading-icon' />
            </div>
        )
    }

    return (
        <div className='userDashboard' onClick={() => setSidebar(false)}>
            <SEO
                title='APPI | UserDashboard'
                description='Account settings'
                keywords={['appi', 'account', 'settings', 'user', 'developer', 'api', 'keys', 'delete', 'danger', 'zone']}
                type='website'
                name='APPI UserDashboard'
            />
            {mobile && (
                <HiOutlineDotsHorizontal
                    className='userDashboard-burger'
                    onClick={(e) => {
                        e.stopPropagation()
                        setSidebar(!sidebar)
                    }}
                />
            )}
            <Dashboard pages={userPages}
                       SidebarTitle={<div className='userDashboard-title'>
                           <AiOutlineUser className='userDashboard-title-img' />
                           {/*<img id='logo' src={require('../../Images/Logo/Trailblazer.png')} alt='logo' />*/}
                           <h1>{globalState.user?.name} {globalState.user?.surname}</h1>
                       </div>} />
        </div>
    )
}

export default UserDashboard

import './Guide.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import Footer from '../../../Landing/Footer/Footer'
import { StaticList } from '../../Components/StaticList/StaticList'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticFlow } from '../../Components/StaticFlow/StaticFlow'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { StaticGrid } from '../../Components/StaticGrid/StaticGrid'
import { AiFillAlert } from 'react-icons/ai'

export const Guide = () => {
    return (
        <div className="guide-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={6} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="guide">
                <StaticTitle
                    title={{
                        title: 'Guide',
                        imgPosition: 'top',
                        titleImg: () => <AiFillAlert />,
                    }}
                    subtitle={'APPI makes it easy to apply Genrative AI into your business'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                    }}

                />


                <StaticFlow title={'Static flow'} bullets={[
                    {
                        'title': 'Step 1',
                        'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                    },
                    {
                        'title': 'Step 2',
                        'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                    },
                    {
                        'title': 'Step 3',
                        'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                    },
                ]} />

                <StaticList
                    title={{
                        title: 'Static list static list',
                        imgPosition: 'top',
                        newlineHighlight: 'highlight',
                        titleImg: () => <AiFillAlert />,
                    }}

                    points={[
                        {
                            title: 'Point 1',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                        {
                            title: 'Point 2',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                        {
                            title: 'Point 3',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                    ]}
                />

                <StaticList
                    title={{
                        title: 'Static list statics list',
                        imgPosition: 'top',
                        newlineHighlight: 'highlight',
                        titleImg: () => <AiFillAlert />,
                    }}

                    points={[
                        {
                            title: 'Point 1',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                        {
                            title: 'Point 2',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                    ]}
                />

                <StaticProps
                    title={{
                        title: 'Ai that understands',
                        highlight: 'you',
                        imgPosition: 'left',
                    }}
                    items={[
                        {
                            title: 'Point 1',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                        {
                            title: 'Point 2',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                        {
                            title: 'Point 3',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            icon: <HiOutlinePresentationChartLine />,
                        },
                    ]}
                />

                <StaticGrid
                    title={{
                        title: 'Guide',
                        imgPosition: 'top',
                        titleImg: () => <AiFillAlert />,
                    }}
                    items={[
                        {
                            title: 'Point 1',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            img: 'https://images.unsplash.com/photo-1634170380004-3b3b3b3b3b3b',
                        },
                        {
                            title: 'Point 2',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            img: 'https://images.unsplash.com/photo-1634170380004-3b3b3b3b3b3b',
                        },
                        {
                            title: 'Point 3',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio',
                            img: 'https://images.unsplash.com/photo-1634170380004-3b3b3b3b3b3b',
                        },
                    ]} />


                <StaticPrompt title={'Static prompt'}
                              subtitle={'Supercharge your business, with our solutions. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio'} />


                <Footer />
            </div>
        </div>
    )
}
import './StaticCards.scss'
import React, { ReactNode } from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticGridItemInterface {
    title: string
    text: string
    main?: boolean
    icon?: ReactNode
}

interface StaticCardInterface {
    items?: StaticGridItemInterface[]
    titleConfig: TitleInterface
}

export const StaticCards = (props: StaticCardInterface) => {
    return (
        <div className="landingSubpage staticCards">
            <Title
                title={props.titleConfig.title}
                subtitle={props.titleConfig.subtitle}
                highlight={props.titleConfig.highlight}
                titleImg={props.titleConfig.titleImg}
                imgPosition={props.titleConfig.imgPosition}
            />
            <div className="staticCards-itemsWrapper">
                {props.items?.map((item, index) => (
                    <StaticPropsItem key={index} {...item} main={index === 1} />
                ))}
            </div>
        </div>
    )
}

const StaticPropsItem = (props: StaticGridItemInterface) => {
    return (
        <div className={`staticCards-item`} id={props.main ? 'mainCard' : ''}>
            <div className={'staticCards-item-icon'}>
                {props.icon}
            </div>
            <h1>{props.title}</h1>
            <p>{props.text}</p>
        </div>
    )
}
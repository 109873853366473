import './StaticChoice.scss'
import React, { ReactNode, useState } from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticChoiceItemInterface {
    title: string
    text: string
    main?: boolean
    icon?: ReactNode
    component?: ReactNode
}

interface StaticChoiceInterface {
    items?: StaticChoiceItemInterface[]
    titleConfig: TitleInterface
}

export const StaticChoice = (props: StaticChoiceInterface) => {
    const [chosen, setChosen] = useState<number>(0)

    return (
        <div className="landingSubpage staticChoice">
            <Title
                title={props.titleConfig.title}
                subtitle={props.titleConfig.subtitle}
                highlight={props.titleConfig.highlight}
                titleImg={props.titleConfig.titleImg}
                imgPosition={props.titleConfig.imgPosition}
            />
            <div className="staticChoice-choices">
                {props.items !== undefined && props.items.map((item, index) => (
                    <div className="staticChoice-choices-choice" id={chosen === index ? 'chosenOption' : ''}
                         onClick={() => setChosen(index)}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
            <div className="staticChoice-main">
                {props.items !== undefined && props.items[chosen].component}
            </div>
        </div>
    )
}


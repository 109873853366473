import React, { useEffect, useState } from 'react'
import { ConversationState } from '../../../../Utils/enums'
import copy from 'copy-to-clipboard'
import { AiOutlineCheckSquare, AiOutlineLoading } from 'react-icons/ai'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'
import * as FaIcons from 'react-icons/fa'
import { FaBookOpen, FaCheck, FaRegCopy } from 'react-icons/fa'
import 'katex/dist/katex.min.css'
import './MessageAnswer.scss'
import { ExpertAnswerInterface, MessageType } from '../../../../Interfaces/messageInterfaces'
import { Parser } from '../Parser'
import { GenerationStage, useChatContext } from '../../ChatPropsManager'
import { ContextInterface, ContextPartInterface, ContextPartType } from '../../../../Interfaces/documentInterfaces'
import { IconType } from 'react-icons'
import 'react-medium-image-zoom/dist/styles.css'
import { InlineMath } from 'react-katex'
import { MessageAnswerModal } from '../MessageAnswerModal/MessageAnswerModal'
import { GetContextImage } from '../../../../Requests/conversationRequests'


interface MessageAnswerInterface {
    message: ExpertAnswerInterface,
    fold?: Function,
    conversationState: ConversationState,
    isSelected: boolean,
    folded: any,
    selectedMessages: any[],
    setSelectedMessages: Function,
    setFinalAnswer: Function,
    editPrompt: Function,
    setFlow: Function
    flow: any
    alignment: boolean
    setAlignment: Function
    generation_stage: GenerationStage
}

export const MessageAnswer = ({
                                  message,
                                  folded,
                                  fold,
                                  isSelected,
                                  selectedMessages,
                                  setSelectedMessages,
                                  generation_stage,
                              }: MessageAnswerInterface) => {
    const {
        conversationState,
        setFinalAnswer,
        setInspectorContext,
        setInspectorOpen,
    } = useChatContext()

    const isFolded = folded.includes(message.id)
    const [copied, setCopied] = useState<boolean>(false)
    const [visibleContext, setVisibleContext] = useState<ContextPartInterface[]>([])
    const [visibleContextOpen, setVisibleContextOpen] = useState<boolean>(false)
    const [visibleContextSelected, setVisibleContextSelected] = useState<number>(-1)
    const [contextImages, setContextImages] = useState<any>({})
    let className = 'message ' + message.id
    if (message.chosen)
        className += ' message-chosen'
    if (message.chosenFinal)
        className += ' message-chosen-final'
    if (!message.finished && (conversationState === ConversationState.CHOOSE || conversationState === ConversationState.CHOOSE_FINAL))
        className += ' message-toSelect'
    const handleCopy = (text: string) => {
        copy(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const icons = FaIcons as { [key: string]: IconType }
    const CurrentIconComponent = message.expertResponding?.logoUrl ? icons[message.expertResponding?.logoUrl] : icons['FaRobot']

    useEffect(() => {
        if (message.context && message.context.length !== 0 && message.context[0] !== undefined && message.expertResponding.documentDisplayPermission !== 0) {
            let iterator = -1
            const bounded_contexts = []
            for (let i = 0; i < message.context.length; i++) {
                const context = message.context[i]?.contextFragments?.map((context_item: any, index) => {
                    const type = context_item.fragment.messagePartType
                    const is_bounded = (type === ContextPartType.IMAGE || type === ContextPartType.FORMULA || type === ContextPartType.TABLE || type === ContextPartType.FIGURE)
                    if (is_bounded)
                        iterator++
                    return {
                        ...context_item,
                        isBounded: is_bounded,
                        index: is_bounded ? iterator : undefined,
                        documentId: message.context ? message.context[i].documentId : undefined,
                    }
                })
                message.context[i].expertId = message.expertResponding.id
                message.context[i].contextFragments = context
                bounded_contexts.push(message.context[i].contextFragments?.filter((context: ContextPartInterface) => context.isBounded))
            }
            setVisibleContext(bounded_contexts.flat())
        }
    }, [message.context?.length])


    function handleVisibleContextSelection(index: number) {
        if (!message.context || message.context.length === 0)
            return
        const temp_inspector_context = structuredClone(message.context)
        for (let i = 0; i < message.context.length; i++) {
            temp_inspector_context[i].contextFragments = message.context[i].contextFragments.filter((context: ContextPartInterface) => context.isBounded)
        }
        setInspectorContext(temp_inspector_context)
        setVisibleContextSelected(index)
    }

    console.log(message)

    return (
        <div className={className + ' message-bot'}
             onClick={() => {
                 if (!message.finished) {
                     if (conversationState === ConversationState.CHOOSE) {
                         if (selectedMessages?.find((id: string) => id === message.id)) {
                             setSelectedMessages?.(selectedMessages.filter((id: string) => id !== message.id))
                         } else {
                             setSelectedMessages?.([...selectedMessages, message.id])
                         }
                     }
                     if (conversationState === ConversationState.CHOOSE_FINAL) {
                         setFinalAnswer(message.id)
                     }
                 } else fold?.()
             }
             }
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
        >
            {visibleContextSelected !== -1 &&
                <div className="message-images-modal-wrapper" onClick={() => setVisibleContextSelected(-1)}>
                    <div className="message-images-modal" onClick={(e) => e.stopPropagation()}>
                        <MessageAnswerModal
                            visibleContext={visibleContext}
                            visibleContextSelected={visibleContextSelected}
                            setVisibleContextSelected={setVisibleContextSelected}
                            close={() => setVisibleContextSelected(-1)}
                        />
                    </div>
                </div>}
            <div className="message-innerWrapper">
                {(!message.finished || message.chosenFinal || fold) && <div className="message-icon-wrapper">
                    {/*<img className='message-icon' src={APPI_icon} alt='logo' />*/}
                    <CurrentIconComponent className="message-icon"
                                          style={{ color: message.expertResponding.logoColorHex ? message.expertResponding.logoColorHex : 'aqua' }} />
                    <div className="message-icon-sender">{message.expertResponding.name}</div>
                </div>
                }
                {
                    (!message.finished || message.chosenFinal || !isFolded) &&
                    <div className="message-text-wrapper">
                        <Parser copied={copied} handleCopy={handleCopy}>
                            {message.response[0]?.text}
                        </Parser>
                    </div>
                }
                {
                    (message.finished && !message.chosen && isFolded) && <div className="message-text-wrapper">
                        <div className="message-sender">{message?.expertResponding?.name}</div>
                    </div>
                }
                {
                    (message.finished && !message.chosenFinal) && <MdOutlineKeyboardArrowUp
                        className={isFolded ? 'message-fold-icon message-fold-icon-selected' : 'message-fold-icon'} />
                }
                {
                    (conversationState !== ConversationState.READY && !message.finished && ((message.agentAnswerType === MessageType.EXPERT_ANSWER) ||
                        (message.agentAnswerType !== MessageType.USER_MESSAGE && conversationState === ConversationState.CHOOSE_FINAL))) &&
                    <AiOutlineCheckSquare
                        className={isSelected ? conversationState === ConversationState.CHOOSE_FINAL ? 'message-checkbox message-checkbox-checked-final' : 'message-checkbox message-checkbox-checked' : 'message-checkbox'} />
                }
                {visibleContextOpen && <div className="message-images">
                    {visibleContext.filter((context: ContextPartInterface) => context.fragment.messagePartType !== ContextPartType.FORMULA).map((context_item: ContextPartInterface, index) => {
                        const imageRef = React.createRef<HTMLImageElement>()
                        const document_id = context_item.documentId
                        const node_id = context_item.nodeId
                        if (document_id && node_id) {
                            if (!contextImages[document_id + node_id])
                                GetContextImage(document_id, node_id)
                                    .then(response => {
                                        const url = URL.createObjectURL(response)
                                        setContextImages((prevState: any) => {
                                            return {
                                                ...prevState,
                                                [document_id + node_id]: url,
                                            }
                                        })
                                    })
                                    .catch(error => {
                                        const status = error.response ? error.response.status : 500
                                        if (status === 403) {
                                            setContextImages((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    [document_id + node_id]: 'NONE',
                                                }
                                            })
                                        }
                                    })
                            context_item.fragment.image = contextImages[document_id + node_id]
                            if (contextImages[document_id + node_id] === 'NONE')
                                return <div className="message-images-item" key={index}></div>
                            return (
                                <div className="message-images-item" key={index}
                                     onClick={() => {
                                         handleVisibleContextSelection(context_item.index ? context_item.index : index)
                                     }}>
                                    {(context_item.fragment.messagePartType === ContextPartType.IMAGE ||
                                        context_item.fragment.messagePartType === ContextPartType.TABLE ||
                                        context_item.fragment.messagePartType === ContextPartType.FIGURE) && <img
                                        src={
                                            Object.keys(contextImages).includes(document_id + node_id) ?
                                                contextImages[document_id + node_id] :
                                                'https://i.pinimg.com/originals/e2/63/00/e26300c0c746d3163a0f48223c897cee.gif'
                                        }
                                        alt={context_item.fragment.messagePartContent_TextAlt}
                                        ref={imageRef}
                                    />}
                                </div>
                            )
                        }
                    })}
                </div>}
                {visibleContextOpen && <div className="message-images message-formulas">
                    {visibleContext.filter((context: ContextPartInterface) => context.fragment.messagePartType === ContextPartType.FORMULA).map((context: ContextPartInterface, index) => (
                        <div className="message-images-item message-formulas-item" key={index}
                             onClick={() => {
                                 handleVisibleContextSelection(context.index ? context.index : index)
                             }}>
                            <InlineMath>{context.fragment.messagePartContent_TextAlt}</InlineMath>
                        </div>
                    ))}
                </div>}
                <div className="message-controls">
                    <div className="message-controls-iconHolder">
                        {!copied && <div className="message-controls-iconHolder-icon-wrapper"
                                         onClick={(e) => {
                                             e.stopPropagation()
                                             handleCopy(message?.response[0].text)
                                         }}>
                            <FaRegCopy
                                className="message-controls-iconHolder-icon message-controls-iconHolder-copy" />
                        </div>}
                        {copied && <div className="message-controls-iconHolder-icon-wrapper">
                            <FaCheck
                                className="message-controls-iconHolder-icon"
                            />
                        </div>}
                        {(message.context && message.context.length !== 0 && message.context[0] !== undefined) &&
                            <div className="message-controls-iconHolder-icon-wrapper" onClick={() => {
                                const context = message.context?.map((context: any) => {
                                    return {
                                        ...context,
                                        expertId: message.expertResponding.id,
                                    }
                                })
                                if (!context)
                                    return
                                const filteredContext = context.map((document: ContextInterface) => {
                                    const uniqueFragments = document.contextFragments.filter((fragment, index, self) =>
                                        index === self.findIndex(f => f.fragment.text === fragment.fragment.text),
                                    )
                                    return {
                                        ...document,
                                        contextFragments: uniqueFragments,
                                    }
                                })
                                setInspectorContext(filteredContext)
                                setInspectorOpen(true)
                            }}>
                                <FaBookOpen className="message-controls-iconHolder-icon" />
                                <p>Context</p>
                            </div>}
                        {visibleContext.length !== 0 &&
                            <div className="message-controls-iconHolder-icon-wrapper" onClick={() => {
                                setVisibleContextOpen(!visibleContextOpen)
                            }}>
                                <MdOutlineKeyboardArrowUp
                                    className={visibleContextOpen ? 'message-controls-iconHolder-icon' : 'message-controls-iconHolder-icon message-controls-iconHolder-icon-rotated'} />
                                <p>{visibleContextOpen ? 'Hide images' : 'Show images'}</p>
                            </div>}
                    </div>
                </div>
            </div>
            {(generation_stage !== undefined && generation_stage !== GenerationStage.NONE) &&
                <div className="generationStage">
                    <AiOutlineLoading className="generationStage-loading" />
                    {generation_stage === GenerationStage.PROCESSING_PROMPT && 'Processing prompt...'}
                    {generation_stage === GenerationStage.RETRIEVING_CONTEXT && 'Fetching context from documents...'}
                    {generation_stage === GenerationStage.COMBINING_RESPONSES && 'Combining responses...'}
                    {generation_stage === GenerationStage.VALIDATING_RESPONSE && 'Validating response...'}
                    {generation_stage === GenerationStage.STREAMING_RESPONSE && 'Streaming response...'}
                </div>}
        </div>
    )
}



import React from 'react'
import './Email.scss'
import './EmailMobile.scss'
import { Element } from 'react-scroll/modules'
import { ContactComponent } from '../../Static/Contact/Contact'
import { Title } from '../../../Components/Title/Title'
import { FaHandsClapping } from 'react-icons/fa6'


const Email = ({ sendAlert }: { sendAlert: Function }) => {

    return (
        <Element name="email" className="landingSubpage email">
            <Title
                title={'Contact us!'}
                titleImg={() => <FaHandsClapping />}
                imgPosition={'left'}
            />
            <ContactComponent title={false} side={true} sendAlert={sendAlert} />
        </Element>
    )
}

export default Email

import './DocumentsPipelines.scss'
import React from 'react'
import { RxTextAlignLeft } from 'react-icons/rx'
import { FaRobot } from 'react-icons/fa'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { PiGraph } from 'react-icons/pi'
import { CiChat1 } from 'react-icons/ci'

interface DocumentPipelinesInterface {
    hovered?: boolean;
}

export const DocumentsPipelines = ({ hovered }: DocumentPipelinesInterface) => {

    return (
        <div className="staticListPoint-children">
            <div className={hovered ?
                'documentPipelines documentPipelines-hovered' :
                'documentPipelines'}>
                <div className="documentPipelines-node" id="node1">
                    <CiChat1 />
                </div>
                <div className="documentPipelines-line" id="line12" />
                <div className="documentPipelines-node" id="node2">
                    <FaRobot />
                </div>
                <div className="documentPipelines-line" id="line23_1" />
                <div className="documentPipelines-line" id="line23_2" />
                <div className="documentPipelines-node" id="node3">
                    <IoDocumentTextOutline />
                </div>
                <div className="documentPipelines-line" id="line24" />
                <div className="documentPipelines-node" id="node4">
                    <IoDocumentTextOutline />
                </div>
                <div className="documentPipelines-line" id="line25_1" />
                <div className="documentPipelines-line" id="line25_2" />
                <div className="documentPipelines-node" id="node5">
                    <IoDocumentTextOutline />
                </div>
                <div className="documentPipelines-line" id="line36_1" />
                <div className="documentPipelines-line" id="line36_2" />
                <div className="documentPipelines-line" id="line46" />
                <div className="documentPipelines-node" id="node6">
                    <PiGraph />
                </div>
                <div className="documentPipelines-line" id="line67" />
                <div className="documentPipelines-node" id="node7">
                    <RxTextAlignLeft />
                </div>

            </div>
        </div>
    )
}
export enum AlertType {
    UNAUTHORIZED = 'Unauthorized',
    UNKNOWN = 'Unknown',
    SUCCESS = 'Success',
    DUPLICATE = 'Duplicate',
    INTERNAL_SERVER_ERROR = 'Internal server error',
}

export enum AlertSeverity {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success'
}

export interface Alert {
    type: AlertType,
    message: string | null,
    severity: AlertSeverity
}
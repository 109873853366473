import './Dashboard.scss'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGlobalState } from '../../GlobalStateContext'


interface DashboardPage {
    label: string;
    icon: ReactNode;
    component: ReactNode;
    onClick?: Function;
}

interface DashboardProps {
    pages: DashboardPage[];
    SidebarTitle: ReactNode
}

export const Dashboard = ({ pages, SidebarTitle }: DashboardProps) => {
    const [page, setPage] = useState<string>(pages[0].label)
    const [searchParams, setSearchParams] = useSearchParams()
    const [sidebar, setSidebar] = useState(false)
    const [globalState, globalActions] = useGlobalState()
    const navigate = useNavigate()

    useEffect(() => {
        const path = searchParams.get('page') || pages[0].label
        const foundPage = pages.find(p => p.label === path)
        if (foundPage) {
            setPage(foundPage.label)
        }
    }, [searchParams, pages])

    return (
        <div className='dashboard'>
            <div className='dashboard-sidebar'>
                {SidebarTitle}
                <div className='dashboard-sidebar-menu'>

                    {pages.map(pageData => (
                        <div
                            key={pageData.label}
                            className={`dashboard-sidebar-menu-item ${page === pageData.label ? 'dashboard-sidebar-menu-item-selected' : 'dashboard-sidebar-menu-item'}`}
                            onClick={() => {
                                setSidebar(false)
                                if (pageData.onClick) {
                                    pageData.onClick()
                                } else
                                    navigate(`?page=${pageData.label}`)
                            }}
                        >
                            {pageData.icon}
                            <span className='dashboard-sidebar-menu-item-title'>{pageData.label}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='dashboard-content'>
                {pages.map(pageData => (
                    page === pageData.label &&
                    <div key={pageData.label} style={{ width: '100%' }}>{pageData.component}</div>
                ))}
            </div>
        </div>
    )
}
import './UserInputSearchBar.scss'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

interface UserInputSearchBarProps {
    options: AgentInterface[];
    onSelection: (agent: AgentInterface) => void;
}

export const UserInputSearchBar = ({
                                       options,
                                       onSelection,
                                   }: UserInputSearchBarProps) => {
    const [searchBarActive, setSearchBarActive] = useState(true)
    const [highlighted, setHighlighted] = useState(-1)
    const [filter, setFilter] = useState('')
    const [filteredOptions, setFilteredOptions] = useState<AgentInterface[]>([])
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setFilteredOptions(
            options.filter(option => option.name.toLowerCase().includes(filter.toLowerCase())),
        )
    }, [filter, options])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }

    const handleClick = (option: AgentInterface) => {
        onSelection(option)
    }
    return (
        <div className='userInput-searchBar'>
            <input
                type='text'
                placeholder='Search...'
                value={filter}
                onChange={handleChange}
                ref={inputRef}
                autoFocus={searchBarActive}
            />
            {filteredOptions.length > 0 && (
                <ul className='userInput-searchBar-items'>
                    {filteredOptions.map((option, index) => (
                        <div key={option.id} onClick={() => handleClick(option)}
                             className='userInput-searchBar-items-item'>
                            <h1>{option.name}</h1>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    )
}
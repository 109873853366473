import './DashboardSearchPage.scss'
import { ChangeEvent, FC } from 'react'

interface DashboardSearchPageInterface {
    title: string
    className?: string
    children?: any
    filter: string
    setFilter: any
}

const DashboardSearchPage: FC<DashboardSearchPageInterface> = ({
                                                                   title,
                                                                   className,
                                                                   children,
                                                                   filter,
                                                                   setFilter,
                                                               }) => {

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }

    return (
        <div className={'dashboard-searchPage ' + className}>
            <h1 className='dashboard-searchPage-title'>{title}</h1>
            <input
                className='dashboard-searchPage-searchBar'
                type='text'
                placeholder='Search...'
                value={filter}
                onChange={handleSearchChange}
            />
            {children}
        </div>
    )
}

export default DashboardSearchPage
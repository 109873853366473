import React from 'react'
import './Home.scss'
import './HomeMobile.scss'
import { Element } from 'react-scroll/modules'
import useRedirect from '../../../Utils/redirect'
import SEO from '../../../Components/SEO'
import { AiFillPieChart, AiFillRocket, AiFillStar, AiFillTrophy } from 'react-icons/ai'


const Home = () => {
    const redirect = useRedirect()

    const tiles_config = [
        {
            figure: '87%',
            title: 'Competitive Edge',
            description: 'of global organizations believe that AI technologies will give them a competitive edge.',
            icon: <AiFillRocket />,
            source: 'https://web-assets.bcg.com/1e/4f/925e66794465ad89953ff604b656/mit-bcg-expanding-ai-impact-with-organizational-learning-oct-2020-n.pdf',
        },
        {
            figure: '40%',
            title: 'Productivity Boost',
            description: 'more productive, will be the employees using AI by 2035.',
            icon: <AiFillPieChart />,
            source: 'https://www.pwc.com/us/en/tech-effect/ai-analytics/ai-predictions.html',
        },
        {
            figure: '97%',
            title: 'Business Impact',
            description: 'of business owners believe ChatGPT will help their business.',
            icon: <AiFillStar />,
            source: 'https://www.forbes.com/advisor/business/software/ai-in-business/',
        },
        {
            figure: '83%',
            title: 'Strategic Priority',
            description: 'of organizations worldwide claim that AI is a top priority for their business processes.',
            icon: <AiFillTrophy />,
            source: 'https://techbullion.com/83-of-executives-consider-ai-a-strategic-priority-for-their-company/',
        },
    ]


    return (
        <Element name="Home">
            <SEO
                title="APPI | Home"
                description="Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love."
                name="Home"
                type="website"
                keywords={['APPI', 'Home', 'Chat', 'Experts', 'Create an account', 'Read more']}
            />
            <div className="home">
                <div className="home-titleWrapper">
                    <div className="home-announcement" onClick={() => redirect('app', 'early-partners')}>
                        Looking for early stage partners
                    </div>
                    <h1 className="home-title">
                        <b>77%</b> of the companies are exploring AI
                    </h1>
                    <div className="home-description">
                        Time for you to join them - we give you the native platform and support
                    </div>
                    <div className="home-tiles">
                        {tiles_config.map((tile, index) => (
                            <div className="home-tiles-tile" key={index}>
                                {tile.icon}
                                <div className="home-tiles-tile-figure">
                                    {tile.figure}
                                </div>
                                <div className="home-tiles-tile-title">
                                    {tile.title}
                                </div>
                                <div className="home-tiles-tile-description">
                                    {tile.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/*<div className="home-titleWrapper-buttonsWrapper">*/}
                    {/*    <Link to="video" className="home-titleWrapper-buttonsWrapper-button" spy={true}*/}
                    {/*          smooth={true} duration={500} offset={50} id={'first'}>*/}
                    {/*        <h1>{t('landing.buttons.title_1')}</h1>*/}
                    {/*        <h2>{t('landing.buttons.description_1')}</h2>*/}
                    {/*        <MdOutlineKeyboardArrowRight className="home-titleWrapper-buttonsWrapper-button-icon" />*/}
                    {/*    </Link>*/}
                    {/*    <div className="home-titleWrapper-buttonsWrapper-button" id={'second'} onClick={() => {*/}
                    {/*        if (!globalState.user)*/}
                    {/*            redirect('', 'login')*/}
                    {/*        else*/}
                    {/*            redirect('chat', '')*/}
                    {/*    }}>*/}
                    {/*        <h1>{t('landing.buttons.title_2')}</h1>*/}
                    {/*        <h2>{t('landing.buttons.description_2')}</h2>*/}
                    {/*        <MdOutlineKeyboardArrowRight className="home-titleWrapper-buttonsWrapper-button-icon" />*/}
                    {/*    </div>*/}
                    {/*    <a href="https://appi.chat" className="home-titleWrapper-buttonsWrapper-button" id={'third'}>*/}
                    {/*        <h1>{t('landing.buttons.title_3')}</h1>*/}
                    {/*        <h2>{t('landing.buttons.description_3')}</h2>*/}
                    {/*        <MdOutlineKeyboardArrowRight className="home-titleWrapper-buttonsWrapper-button-icon" />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    {/*<Newsletter sendAlert={() => {*/}
                    {/*}} />*/}
                </div>
            </div>
        </Element>
    )
}

export default Home

import React, { useEffect, useRef, useState } from 'react'
import './Dropdown.scss'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { AiOutlinePlus } from 'react-icons/ai'

type Renderable<T> = {
    [P in keyof T]: React.ReactNode;
};

interface DropdownProps<T> {
    selected: T;
    setSelected: (value: T) => void;
    options: T[];
    displayAttribute: keyof Renderable<T>;
    createNew?: Function
}

function Dropdown<T>({ selected, setSelected, options, displayAttribute, createNew }: DropdownProps<T>): JSX.Element {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div className='dropdown' ref={dropdownRef}>
            <div className='dropdown-selected' onClick={() => setIsOpen(!isOpen)}>
                {selected[displayAttribute] as React.ReactNode}

                <MdOutlineKeyboardArrowDown className='dropdown-icon' id={isOpen ? 'rotated' : ''} />
            </div>
            {isOpen && (
                <div className='dropdown-list'>
                    {options.map((option, index) => (
                        <div key={index} className='dropdown-item' onClick={() => {
                            setSelected(option)
                            setIsOpen(false)
                        }}>
                            {option[displayAttribute] as React.ReactNode}
                        </div>
                    ))}
                    {createNew && (
                        <div className='dropdown-item dropdown-item-create' onClick={() => {
                            createNew()
                            setIsOpen(false)
                        }}>
                            Create New <AiOutlinePlus />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Dropdown

import './CtaUseCase.scss'
import { Title } from '../../../Components/Title/Title'
import { Link } from 'react-scroll/modules'
import { BsQuestion } from 'react-icons/bs'

export const CtaUseCase = () => {

    return (
        <div className="landingSubpage ctaUsecase">
            <Title
                title={'Interested in custom feature'}
                newlineHighlight={'for your use-case?'}
                titleImg={() => <BsQuestion />}
                imgPosition={'top'}
            />
            <h2 className="ctaUsecase-subtitle">Navigating the complexities of AI can be challenging, but at APPI,
                we make it simple. Our goal is to deliver customized AI solutions tailored to your unique needs,
                empowering you to harness AI’s full potential without the need for deep technical expertise.</h2>
            <Link to={'email'} className="ctaUsecase-button" spy={true} smooth={true} duration={500}
                  offset={-50}>
                Discover how we can create AI solutions for you
            </Link>
        </div>
    )
}

import React, { Component } from 'react'
// Import all icons from 'react-icons/fa'
import * as FaIcons from 'react-icons/fa'
import { agentIconType, AgentInterface } from '../Interfaces/agentsInterfaces'

interface AgentIconProps extends React.HTMLAttributes<HTMLElement> {
    agent: AgentInterface;
}

class AgentIcon extends Component<AgentIconProps> {
    render() {
        const { agent, ...rest } = this.props
        const icons = FaIcons as agentIconType

        const IconName = agent?.logoUrl && icons[agent.logoUrl] ? agent.logoUrl : 'FaRobot'
        const IconComponent = icons[IconName] as React.ComponentType

        return <IconComponent {...rest} />
    }
}

export default AgentIcon
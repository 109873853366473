import './OrganizationAgents.scss'
import { OrganizationInterface } from '../../../../Interfaces/organizationInterfaces'
import DashboardSearchPage from '../../../../Components/Dashboard/DashboardSearchPage/DashboardSearchPage'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import React, { useEffect, useState } from 'react'
import { getExpert } from '../../../../Requests/agentRequests'
import AgentCard from '../../../../Components/AgentCard/AgentCard'

interface OrganizationAgentsInterface {
    organization: OrganizationInterface
}

export const OrganizationAgents = ({ organization }: OrganizationAgentsInterface) => {

    const [experts, setExperts] = useState<AgentInterface[]>([])
    const [filter, setFilter] = useState('')

    useEffect(() => {
        organization.assignedExpertsIds.forEach((expertId) => {
            getExpert(expertId).then((res) => {
                if (experts.find((e) => e.id === res.id)) return
                setExperts((prev) => [...prev, res])
            })
        })
    }, [organization.assignedExpertsIds])

    const filteredExperts = experts.filter(expert => expert.name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <div className='overview-content-organizationAgents'>
            <DashboardSearchPage title='Agents' className='organization-team' filter={filter} setFilter={setFilter}>
                <div className='overview-content-organizationAgents-agents'>
                    {filteredExperts.map((expert, index) => (
                        <AgentCard key={index} agent={expert} nav={'dev'}/>
                    ))}
                </div>
            </DashboardSearchPage>
        </div>
    )
}
import React, { useState } from 'react'
import './LoginComponent.scss'
import '../../Pages/Login/Login.scss'
import { apiPost } from '../../Utils/RequestsUtil'
import { sha256 } from 'js-sha256'
import { AiOutlineLoading } from 'react-icons/ai'
import Cookies from 'js-cookie'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'
import { AlertSeverity, AlertType } from '../../Interfaces/alertsInterfaces'

function LoginComponent({ navigate, swap, sendAlert }) {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    const [loading, setLoading] = useState(false)
    const [credetialsError, setCredentialsError] = useState(false)

    useGoogleOneTapLogin({
        onSuccess: (response) => {
            apiPost(
                'User/google-login',
                {
                    'googleToken': response.credential,
                },
                true,
            ).then((response) => {
                Cookies.set('jwt', response.token, {
                    expires: 1,
                    path: '/',
                    domain: '.' + process.env.REACT_APP_APPI_DOMAIN,
                })
                navigate('/dashboard')
            })
        },
        onError: (error) => console.log('One Tap Login Failed:', error),
    })

    const googleLogin = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onSuccess: (codeResponse) => {
            const code = codeResponse.code
            apiPost(
                'User/google-login',
                {
                    'googleToken': code,
                },
                true,
            ).then((response) => {
                console.log('RESPONSE', response)
                Cookies.set('jwt', response.token, {
                    expires: 1,
                    path: '/',
                    domain: '.' + process.env.REACT_APP_APPI_DOMAIN,
                })
                navigate('/dashboard')
            })
        },
        onError: (error) => console.log('Login Failed:', error),
        flow: 'auth-code',
    })
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData((prevState) => ({ ...prevState, [name]: value }))
    }
    const handleLoginSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        const email = formData.email
        const password = sha256(formData.password)
        apiPost(
            'User/login',
            {
                'email': email,
                'password_sha256hash': password,
            },
        ).then(async (response) => {
            Cookies.set('jwt', response.token, {
                expires: 1,
                path: '/',
                domain: '.' + process.env.REACT_APP_APPI_DOMAIN,
            })
            navigate('/dashboard')
        }).catch(async (error) => {
            console.log(error)
            await setFormData((prevState) => ({ ...prevState, password: '' }))
            setLoading(false)
            sendAlert(AlertType.UNAUTHORIZED, 'Invalid credentials', AlertSeverity.ERROR)
        })
    }
    return (
        <div className="loginComponent">
            <form onSubmit={handleLoginSubmit}>
                <h1 className="loginComponent-loginHeader">Login</h1>
                <label>
                    <input
                        placeholder={'Email'}
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required={true}
                    />
                </label>
                <label>
                    <input
                        placeholder={'Password'}
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required={true}
                    />
                </label>
                <br />
                {credetialsError && <h1 className="loginComponent-credentialsError">Invalid credentials</h1>}
                {!loading && <button className="loginComponent-submit" id={'submit'} type="submit">Login</button>}
                {loading && <AiOutlineLoading className="loginComponent-loading" />}
            </form>
            {/*<div className='line' />*/}
            {/*<div className='loginComponent-altlogs'>*/}
            {/*    <FcGoogle className='loginComponent-google' onClick={() => googleLogin()} />*/}
            {/*</div>*/}
            <div className="swap">
                <h2>
                    Hi! At this time we are working closely with our trusted partners. Registration for public is
                    expected soon. If you would like to be our partner message us at: <br /> <span><a
                    href="mailto:office@appi-marketplace.com">office@appi-marketplace.com</a></span>
                </h2>
                {/*    <h2>Don't have an account yet?</h2>*/}
                {/*    <button id='swap' onClick={swap}>Register</button>*/}
            </div>

        </div>
    )
}

export default LoginComponent

// useScrollBottom.js
import React, { useEffect, useState } from 'react'

const useScrollBottom = (
    containerRef: React.MutableRefObject<any>,
    threshold = 50,
) => {
    const [reachedBottom, setReachedBottom] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const element = containerRef.current
            if (element) {
                const offsetHeight = element.offsetHeight
                const scrollHeight = element.scrollHeight
                const scrollTop = element.scrollTop
                const hasReachedBottom = scrollHeight - (offsetHeight + scrollTop) <= threshold
                setReachedBottom(hasReachedBottom)
            }
        }

        const element = containerRef.current
        if (element) {
            element.addEventListener('scroll', handleScroll)
            handleScroll() // Initialize the scroll position check
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll)
            }
        }
    }, [containerRef, threshold])

    return reachedBottom
}

export default useScrollBottom

import { useCallback } from 'react'

function useRedirect() {
    const currentSubdomain = window.location.hostname.split('.')[0]
    return useCallback((domain: string, location: string) => {
        if (domain === 'chat') {
            if (process.env.REACT_APP_DEV === 'true')
                window.location.href = 'http://' + process.env.REACT_APP_APPI_URL + '/dev-chat/' + location
            else
                window.location.href = 'http://chat.' + process.env.REACT_APP_APPI_URL + '/' + location
        } else if (domain !== 'chat') {
            if (location !== '')
                window.location.href = 'http://' + process.env.REACT_APP_APPI_URL + '/' + location
            else
                window.location.href = 'http://' + process.env.REACT_APP_APPI_URL
        }
    }, [currentSubdomain])
}

export default useRedirect

import './DocumentsHallucinations.scss'
import { useEffect, useState } from 'react'

interface DocumentHallucinationsInterface {
        hovered?: boolean;
}

export const DocumentsHallucinations = ({ hovered }: DocumentHallucinationsInterface) => {
        const originalText = 'Yes sure, the fourth law of motion states that...';
        const newText = "Sorry, there is no Newton's fourth law of motion.";
        const [displayedText, setDisplayedText] = useState<string>(originalText);
        const [color, setColor] = useState<string>('red');
        const [index, setIndex] = useState<number>(0);
        const [isDeleting, setIsDeleting] = useState<boolean>(false);

        useEffect(() => {
                let interval: NodeJS.Timeout;

                if (hovered) {
                        if (!isDeleting) {
                                // Deleting text
                                interval = setInterval(() => {
                                        setDisplayedText((prev) => {
                                                const newText = prev.slice(0, -1);
                                                if (newText.length === 0) {
                                                        clearInterval(interval);
                                                        setIsDeleting(true);
                                                        setIndex(0);
                                                        setColor('green');
                                                }
                                                return newText;
                                        });
                                }, 8); // Speed of deletion
                        } else {
                                // Typing new text
                                interval = setInterval(() => {
                                        if (index < newText.length) {
                                                setDisplayedText((prev) => {
                                                        const nextText = prev + newText[index];
                                                        if (index + 1 === newText.length) {
                                                                clearInterval(interval);
                                                        }
                                                        return nextText;
                                                });
                                                setIndex((prev) => prev + 1);
                                        }
                                }, 30); // Speed of typing
                        }
                } else {
                        setDisplayedText(originalText);
                        setColor('red');
                        setIsDeleting(false);
                        setIndex(0);
                }

                return () => clearInterval(interval);
        }, [hovered, displayedText, index, isDeleting]);

        return (
                <div className="staticListPoint-children">
                        <div
                                className={hovered ? 'documentHallucinations documentHallucinations-hovered' : 'documentHallucinations'}>
                                <div className="documentHallucinations-message">
                                        <span className="documentHallucinations-message-author">
                                                User
                                        </span>
                                        <p>
                                                Explain to me fourth law of motion
                                        </p>
                                </div>
                                <div className="documentHallucinations-message documentHallucinations-message-answer">
                                        <span className="documentHallucinations-message-author">
                                                Engineering expert?
                                        </span>
                                        <p className={color}>
                                                {displayedText}
                                        </p>
                                </div>
                        </div>
                </div>
        );
};


import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getUserAccount } from '../../../Requests/userRequests'
import './devProfile.scss'
import './devProfileMobile.scss'
import { AiFillLinkedin, AiOutlineInstagram, AiOutlineMail, AiOutlineUser } from 'react-icons/ai'
import AgentCard from '../../../Components/AgentCard/AgentCard'

export const DevProfile = () => {
    const location = useLocation()
    const [user, setUser] = useState<any>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const id = location.pathname.split('/')[2]
        getUserAccount(id).then((res) => {
            setUser(res)
        })
    }, [location])

    return (
        <div className='devProfile-wrapper'>
            <img className='blobDevProfile blobDevProfile1' src={require('../../../Images/Blobs/blob1.svg').default} />
            <img className='blobDevProfile blobDevProfile2' src={require('../../../Images/Blobs/blob4.svg').default} />
            <img className='blobDevProfile blobDevProfile3' src={require('../../../Images/Blobs/blob5.svg').default} />

            <div className='devProfile'>
                <div className='devProfile-info'>
                    {user?.img ?
                        <img className='devProfile-info-img' src={user?.img} alt='appi' />
                        :
                        <AiOutlineUser className='devProfile-info-img' />
                    }
                    <h1 className='devProfile-info-name'>{user?.name} {user?.surname}</h1>
                    <span />
                    <p className='devProfile-info-description'>{user?.description} I'm a prompt engineer at Appi. I work
                        for Appi. I love Appi</p>
                    <span />
                    <div className='devProfile-info-social'>
                        <AiOutlineMail id='socialIcon' />
                        <AiFillLinkedin id='socialIcon' />
                        <AiOutlineInstagram id='socialIcon' />
                    </div>
                </div>
                <div className='devProfile-agents'>
                    <div className='devProfile-agents-experts-wrapper'>
                        <h1 className='devProfile-agents-experts-wrapper-title'>Experts</h1>
                        <div className='devProfile-agents-experts'>
                            {user?.expertsCreated.map((expert: any, index: number) => (
                                <AgentCard agent={expert} key={index} type='expert' />
                            ))}
                        </div>
                    </div>
                    <p />
                    <div className='devProfile-agents-summarizers-wrapper'>
                        <h1 className='devProfile-agents-summarizers-wrapper-title'>Summarizers</h1>
                        <div className='devProfile-agents-summarizers'>
                            {user?.summarizersCreated.map((summarizer: any, index: number) => (
                                <AgentCard agent={summarizer} key={index} type='summarizer' />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
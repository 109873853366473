import Blobs from '../components/BlobsMain/Blobs'
import Home from '../Home/Home'
import Email from '../Email/Email'
import Footer from '../Footer/Footer'
import './LandingWrapper.scss'
import { ReadMore } from '../ReadMore/ReadMore'
import { FunTool } from '../FunTool/FunTool'
import { AiExpert } from '../FunTool/Components/AiExpert/AiExpert'
import { AiOutlineDatabase } from 'react-icons/ai'
import { Newsletter } from '../../Static/Components/Newsletter/Newsletter'
import { DocumentShowcase } from '../DocumentShowcase/DocumentShowcase'
import { StaticList } from '../../Static/Components/StaticList/StaticList'
import useRedirect from '../../../Utils/redirect'
import { PiGraph } from 'react-icons/pi'
import { RiToolsFill } from 'react-icons/ri'
import { FaCloud, FaHandshake } from 'react-icons/fa'
import { MdAccountCircle } from 'react-icons/md'
import { MeetAppiPlatform } from '../components/MeetAppi/MeetAppiPlatform/MeetAppiPlatform'
import { MeetAppiExperts } from '../components/MeetAppi/MeetAppiExperts/MeetAppiExperts'
import { MeetAppiCloud } from '../components/MeetAppi/MeetAppiCloud/MeetAppiCloud'
import { DocumentsHallucinations } from '../components/Documents/DocumentsHallucinations/DocumentsHallucinations'
import { DocumentsPipelines } from '../components/Documents/DocumentsPipelines/DocumentsPipelines'
import { DocumentsSecurity } from '../components/Documents/DocumentsSecurity/DocumentsSecurity'
import { scroller } from 'react-scroll'
import { CtaUseCase } from '../CtaUseCase/CtaUseCase'

const Landing = ({ sendAlert }: { sendAlert: Function }) => {
    const redirect = useRedirect()

    const funToolConfig = [
        {
            'name': 'AI Expert',
            'component': <AiExpert />,
        },
    ]

    return (
        <div className="wrapper">
            <Blobs />
            <div className="landingWrapper">
                <Home />
                <StaticList
                    title={{
                        title: 'Meet',
                        highlight: 'APPI',
                        subtitle: 'your new AI partner',
                        imgPosition: 'top',
                        titleImg: () => <img src={require('../../../Images/Logo/APPI_icon.png')}
                                             alt="newsletter" />,
                    }}
                    points={[
                        {
                            title: 'All LLMs, All Functionalities: One Powerful Platform',
                            text: 'Access the best features of leading language models in a single, powerful platform—everything you need in one place',
                            icon: <PiGraph />,
                            children: <MeetAppiPlatform />,
                        },
                        {
                            title: 'Custom Experts For Individuals And Businesses',
                            text: 'Create tailored AI Experts that will be helping you daily in your professional tasks. You can also tap into shared ready-made AI Experts for specific tasks or overall domains',
                            icon: <RiToolsFill />,
                            children: <MeetAppiExperts />,

                        },
                        {
                            title: 'Private or shared cloud',
                            text: 'Join APPI today using our reliable shared platform or contact us and get APPI instance for your organization on secure Azure Private Cloud',
                            icon: <FaCloud />,
                            children: <MeetAppiCloud />,
                        },
                    ]}
                    className="aboutAppi"
                />
                <DocumentShowcase />
                <FunTool funToolConfig={funToolConfig} />
                <CtaUseCase />
                <StaticList
                    title={{
                        title: 'Built for professionals',
                        newlineHighlight: 'dealing with lots of data',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    points={[
                        {
                            title: 'Verification Pipelines',
                            text: 'We make sure that the answer you get is correct and based only on the data you provided.',
                            children: <DocumentsPipelines />,
                        },
                        {
                            title: 'No Hallucinations',
                            text: 'APPI is designed to avoid model hallucinations and provide you with the most accurate results.',
                            children: <DocumentsHallucinations />,
                        },
                        {
                            title: 'Security',
                            text: 'We take security seriously. Your data and documents are safe with us.',
                            children: <DocumentsSecurity />,
                        },
                    ]}
                    className="forDocuments"
                    // line={'one'}
                />
                <StaticList
                    title={{
                        title: '',
                        highlight: 'Quick start',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    points={[
                        {
                            title: 'Create an Account',
                            text: 'Jump right in',
                            icon: <MdAccountCircle />,
                            button: () => redirect('app', 'login'),
                            buttonText: 'I\'m in',
                        },
                        {
                            title: 'Talk to our team',
                            text: 'In need of custom solution for your business? Our team is here to help',
                            icon: <FaHandshake />,
                            button: () => scroller.scrollTo('email', {
                                duration: 800,
                                delay: 0,
                                smooth: 'easeInOutQuart',
                                offset: -55,
                            }),
                            buttonText: 'Contact us',

                        },
                    ]}
                    className="quickStart"
                />
                <ReadMore />
                <Email sendAlert={sendAlert} />
                <Newsletter sendAlert={sendAlert} formOnly={false} />
                <Footer />
            </div>
        </div>
    )
}

export default Landing

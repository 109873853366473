import './AiExpert.scss'
import '../../../DocumentShowcase/DocumentShowcase.scss'
import React, { useEffect, useRef, useState } from 'react'
import {
  general_chat,
  senior_java_developer,
  talent_optimization_specialist,
  user_expert,
} from '../../../../../Components/staticExperts'
import { FunToolItem } from '../../FunToolItem/FunToolItem'
import AgentCard from '../../../../../Components/AgentCard/AgentCard'
import { AiOutlineSend, AiOutlineUser } from 'react-icons/ai'
import { FaRobot } from 'react-icons/fa'
import { Parser } from '../../../../Chat/Message/Parser'
import useRedirect from '../../../../../Utils/redirect'

enum Phase {
  SelectExpert = 0,
  SelectPrompt = 1,
  GeneratingResponse = 2,
  ResponseGenerated = 3,
  Final = 4
}

const APPI_text = require('../../../../../Images/Logo/APPI_alone.png')
const APPI_icon = require('../../../../../Images/Logo/APPI_icon.png')
const promptList = [
  'How to write a program to reverse a string?',
  'What are the benefits of using version control systems in software development?',
  'What skills should a developer focus on to optimize their career growth?',
]

const expertList = [
  {
    'expert': senior_java_developer,
    'responses': [
      'You can reverse a string in Java by using the StringBuilder class. Here is an example of how to do it:\n\n```java\npublic class Main {\n   public static void main(String[] args) {\n      String str = "Hello, World!";\n      StringBuilder sb = new StringBuilder(str);\n\n      sb.reverse();\n\n      System.out.println("Reversed string is: " + sb.toString());\n   }\n}\n```',
      'Version control systems like Git are essential for collaborative software development. They allow developers to track changes, collaborate on code efficiently, and manage different versions of the software. By using version control, developers can revert to earlier versions of the code if necessary, collaborate in distributed teams, and ensure the integrity of the codebase.',
      'As a developer, focusing on skills such as problem-solving, proficiency in multiple programming languages, and familiarity with development methodologies like Agile will help you grow your career. Specializing in a field like cloud computing, AI, or cybersecurity can also give you a competitive edge.',
    ],
  },
  {
    'expert': general_chat,
    'responses': [
      'Reversing a string can be done in many programming languages. For example, in Python, you can do it like this:\n\n```python\nmy_string = "Hello, World!"\nreversed_string = my_string[::-1]\nprint(reversed_string)\n```\nThis uses Python slicing to reverse the string.',
      'Version control systems, like Git, are critical for teams to manage code changes efficiently. It helps in tracking the history of code changes, resolving conflicts in collaborative development, and allows rollbacks to previous stable states.',
      'Career growth for developers can be maximized by constantly learning new technologies, understanding system architecture, and developing soft skills like communication and teamwork. Having a clear learning path and setting goals also helps developers progress steadily.',
    ],
  },
  {
    'expert': talent_optimization_specialist,
    'responses': [
      'To reverse a string efficiently, it’s important to understand the performance implications of different methods. For example, using iterative or recursive methods might be better for certain constraints.',
      'Using a version control system helps with accountability and maintaining code integrity across multiple team members. It also fosters a culture of continuous integration and deployment, essential for modern development environments.',
      'For career growth, developers should focus on adaptability, the ability to work in cross-functional teams, and continuous learning. Employers value developers who can not only code but also understand the business impact of their work.',
    ],
  },
]


const phaseText = {
  [Phase.SelectExpert]: {
    'title': 'Select your Experts',
    'description': 'Choose an Expert (or multiple Experts) to receive a response from',
  },
  [Phase.SelectPrompt]: {
    'title': 'Select a Prompt',
    'description': 'Choose a prompt to generate a response for',
  },
  [Phase.GeneratingResponse]: {
    'title': 'Generating Responses',
    'description': 'Generating responses from the selected experts...',
  },
  [Phase.ResponseGenerated]: {
    'title': 'Select the best answer',
    'description': 'The conversation will continue with the selected answer',
  },
  [Phase.Final]: {
    'title': 'Continue the conversation',
    'description': 'Continue the conversation on A{P}PI',
  },
}


export const AiExpert = () => {
  const [phase, setPhase] = useState<Phase>(Phase.SelectExpert)
  const [selectedExperts, setSelectedExperts] = useState<number[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<number>(-1)
  const chatInputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  interface ChatMessage {
    text: string;
    expert: any;
  }

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
  const [selectedAnswer, setSelectedAnswer] = useState<ChatMessage | null>(null)
  const redirect = useRedirect()
  useEffect(() => {
    if (chatInputRef.current) chatInputRef.current.innerText = ''
    const timeouts: NodeJS.Timeout[] = []

    const clearAllTimeouts = () => timeouts.forEach(timeout => clearTimeout(timeout))

    if (phase === Phase.GeneratingResponse) {

      const simulateInput = async (text: string) => {
        const textArray = text.split('')
        for (let i = 0; i < textArray.length; i++) {
          await new Promise<void>((resolve) => {
            timeouts.push(setTimeout(() => {
              if (chatInputRef.current) chatInputRef.current.innerText += textArray[i]
              resolve()
            }, 30))
          })
        }
        const initialMessages = [{ text: text, expert: user_expert }]
        selectedExperts.forEach(index => {
          initialMessages.push({
            text: '',
            expert: expertList[index].expert,
          })
        })
        setChatMessages([{ text: text, expert: user_expert }])
        if (chatInputRef.current) chatInputRef.current.innerText = ''

        await new Promise<void>((resolve) => {
          timeouts.push(setTimeout(() => {
            resolve()
          }, 1000))
        })

        setChatMessages([...chatMessages, ...initialMessages])

        Promise.all(selectedExperts.map((arr_index, index) => simulateTyping(index, arr_index, initialMessages)))
          .then(() => {
            setPhase(Phase.ResponseGenerated)
          })
      }

      const simulateTyping = async (array_index: number, expertIndex: number, initialMessages: ChatMessage[]) => {
        const expertResponse = expertList[expertIndex].responses[selectedPrompt]
        for (let i = 0; i < expertResponse.length; i++) {
          await new Promise<void>((resolve) => {
            setTimeout(() => {
              initialMessages[array_index + 1] = {
                ...initialMessages[array_index + 1],
                text: expertResponse.slice(0, i + 1),
              }
              if (initialMessages[array_index + 1].expert === undefined)
                initialMessages[array_index + 1].expert = expertList[expertIndex].expert
              setChatMessages([...initialMessages])
              resolve()
            }, 15)
          })
        }
      }
      setChatMessages([])
      if (chatInputRef.current) chatInputRef.current.value = ''
      simulateInput(promptList[selectedPrompt])
    }

    return clearAllTimeouts

  }, [phase])


  return (
    <div className="documentShowcase-wrapper">
      <FunToolItem
        num={phase + 1}
        totalSteps={4}
        title={phaseText[phase].title}
        description={phaseText[phase].description}
      >
        <div className="documentShowcase-content-generating funToolComponent-dummyChat">
          <div
            className={phase === Phase.SelectExpert ? 'documentShowcase-content-generating-sidebar funToolComponent-highlight' : 'documentShowcase-content-generating-sidebar'}
            id={'funTool'}
            onClick={() => {
              if (phase !== Phase.SelectExpert) setPhase(Phase.SelectExpert)
            }}
          >
            <div className="funToolComponent-dummyChat-left-logo">
              <img src={APPI_icon} alt="A{P}PI" width="30px" className="icon" />
              <img src={APPI_text} alt="" width="100px" />
            </div>
            <div className="funToolComponent-dummyChat-left-title">
              <p>Experts</p>
            </div>
            <div className="funToolComponent-dummyChat-left-agents">
              {expertList.map((item, index) => (
                <AgentCard agent={item.expert}
                  key={index}
                  onClick={() => {
                    if (selectedExperts.includes(index)) {
                      setSelectedExperts(selectedExperts.filter(item => item !== index))
                    } else {
                      setSelectedExperts([...selectedExperts, index])
                    }
                  }}
                  id={selectedExperts.includes(index) ? 'selectedCard' : phase === Phase.SelectExpert || phase === Phase.Final ? '' : 'no-interact'}
                  onClickRedirect={false}
                  description={false}
                />
              ))}
            </div>
            {phase === Phase.SelectExpert &&
              <div className="funToolComponent-dummyChat-left-buttonContainer">
                <button className="funToolComponent-dummyChat-left-buttonContainer-next"
                  onClick={selectedExperts.length === 0 ? () => {
                  } : () => setPhase(Phase.SelectPrompt)}
                >
                  Select
                </button>
              </div>}
          </div>
          <div className="funToolComponent-dummyChat-right">
            {phase === Phase.SelectExpert &&
              <div className="funToolComponent-dummyChat-right-prompts">
              </div>
            }
            {phase === Phase.SelectPrompt &&
              <div className="funToolComponent-dummyChat-right-prompts">
                {promptList.map((prompt, index) => {
                  return (
                    <button className="funToolComponent-dummyChat-right-prompts-prompt"
                      onClick={() => {
                        setChatMessages([])
                        setSelectedPrompt(index)
                        setPhase(Phase.GeneratingResponse)
                      }}
                      key={index}
                    >
                      <h1>{prompt}</h1>
                    </button>
                  )
                })}
              </div>
            }
            {(phase === Phase.GeneratingResponse || phase === Phase.ResponseGenerated) &&
              <div className="funToolComponent-dummyChat-right-responses">
                {chatMessages.map((response: ChatMessage, index: number) => {
                  let responseClassName: string = "message"
                  if (index > 0) {
                    responseClassName += " response"
                    if (phase === Phase.ResponseGenerated) {
                      responseClassName += " response-select"
                    }
                  }
                  if (response.expert.name === 'User') {
                    responseClassName += " no-interact"
                  }

                  return (
                    <div
                      className={responseClassName}
                      onClick={() => {
                        setSelectedAnswer(response)
                        setPhase(Phase.Final)
                      }}
                      key={index}
                    >
                      <div className="message-innerWrapper">
                        <div className="message-icon-wrapper">
                          {response.expert.name === 'User' ?
                            <AiOutlineUser className="message-icon" />
                            :
                            <FaRobot className="message-icon" style={{
                              color: response.expert.logoColorHex ?? '',
                            }} />
                          }

                          <div className="message-icon-sender">{response.expert.name}</div>
                        </div>
                        <div className="message-text-wrapper">
                          <div className="message-text">
                            <Parser handleCopy={() => {
                            }}>{response.text}</Parser>
                          </div>
                        </div>
                      </div>
                    </div>)
                })}

              </div>
            }
            {phase === Phase.Final &&
              <div className="funToolComponent-dummyChat-right-responses">
                <div className="message no-interact">
                  <div className="message-innerWrapper">
                    <div className="message-icon-wrapper">
                      <AiOutlineUser className="message-icon" />
                      <div className="message-icon-sender">User</div>
                    </div>
                    <div className="message-text-wrapper">
                      <div className="message-text">
                        <Parser handleCopy={() => {
                        }}>{promptList[selectedPrompt]}</Parser>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedAnswer &&
                  <div className="message no-interact">
                    <div className="message-innerWrapper">
                      <div className="message-icon-wrapper">
                        <FaRobot className="message-icon" style={{
                          color: selectedAnswer.expert.logoColorHex ?? '',
                        }} />
                        <div className="message-icon-sender">{selectedAnswer.expert.name}</div>
                      </div>
                      <div className="message-text-wrapper">
                        <div className="message-text">
                          <Parser handleCopy={() => {
                          }}>{selectedAnswer.text}</Parser>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            <div className="funToolComponent-dummyChat-right-spacer" />
            <div className="funToolComponent-dummyChat-right-mockInput interact">
              <p ref={chatInputRef}></p>
              {phase === Phase.Final &&
                <div className="funToolComponent-dummyChat-right-mockInput-wrapper"
                  onClick={() => redirect('', 'login')}
                >
                  <p>Thanks for trying out! Let's continue on APPI</p>
                  <AiOutlineSend />
                </div>}
              {phase === Phase.ResponseGenerated &&
                <div className="funToolComponent-dummyChat-right-mockInput-wrapper"
                  style={{ justifyContent: 'center' }}
                >
                  <p>Select final answer</p>
                </div>
              }
              {phase === Phase.SelectExpert &&
                <div className="funToolComponent-dummyChat-right-mockInput-wrapper"
                  style={{ justifyContent: 'center' }}
                >
                  <p>Select experts from the sidebar</p>
                </div>
              }
            </div>
          </div>
        </div>
      </FunToolItem>
    </div>
  )
}

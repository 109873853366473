import React from 'react'
import './Footer.scss'
import { Element } from 'react-scroll/modules'
import { FaDiscord, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { IoMail } from 'react-icons/io5'

const logo = require('../../../Images/Logo/APPI_icon.png')
const Integrations = () => {
        return (
                <Element name='Footer' className='footer'>
                        <div className='footer-upper'>
                                <img src={logo} alt='logo' className='footer-upper-logo' />
                                <div className='footer-upper-links'>
                                        <a href='https://www.linkedin.com/company/90967506/'>
                                                <FaLinkedin className='footer-upper-links-link' />
                                        </a>
                                        <a href='mailto:office@appi-marketplace.com'>
                                                <IoMail className='footer-upper-links-link' />
                                        </a>
                                        <a href='https://www.instagram.com/appi_marketplace/'>
                                                <FaInstagram className='footer-upper-links-link' />
                                        </a>
                                        <a href=''>
                                                <FaDiscord className='footer-upper-links-link' />
                                        </a>
                                </div>
                        </div>
                        <div className='footer-mid'>
                                <div className='footer-links'>
                                        <h1>Links</h1>
                                        <div className='link-list'>
                                                <a href='/chat?new'>Chat</a>
                                                <a href='/feedback'>Leave feedback!</a>
                                                <a href='/contact'>Contact</a>
                                        </div>
                                </div>
                                <div className='footer-links' >
                                        <h1>Product</h1>
                                        <div className='link-list'>
                                                <a href='/product/features'>Features</a>
                                                <a href='/agents'>Agents</a>
                                        </div>
                                </div>
                                <div className='footer-links'>
                                        <h1>Solutions</h1>
                                        <div className='link-list'>
                                                <a href='/solutions/enterprise'>Enterprise</a>
                                                <a href='/solutions/individual'>Individual Users</a>
                                        </div>
                                </div>
                                <div className='footer-links'>
                                        <h1>Developers</h1>
                                        <div className='link-list'>
                                                <a href='/developer/custom-agents'>Earn</a>
                                                <a href='/labs'>Careers</a>
                                        </div>
                                </div>
                                <div className='footer-links'>
                                        <h1>About APPI</h1>
                                        <div className='link-list'>
                                                <a href='/pricing'>Pricing</a>
                                                <a href='/privacy-policy'>Privacy policy</a>
                                                <a href='/cookie-policy'>Cookie policy</a>
                                        </div>
                                </div>
                        </div>
                        <div className='footer-lower'>
                                APPI © 2024
                        </div>
                </Element>
        )
}

export default Integrations

import { useEffect, useState } from 'react'

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width, height,
    }
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export const useMobileDetect = () => {
    const { width } = useWindowDimensions()
    return width < 768
}


export const usePageBottom = () => {
    const [reachedBottom, setReachedBottom] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            const offsetHeight = document.documentElement.offsetHeight
            const innerHeight = document.documentElement.scrollHeight
            const scrollTop = document.documentElement.scrollTop
            const hasReachedBottom = innerHeight - (offsetHeight + scrollTop) <= 20
            setReachedBottom(hasReachedBottom)
        }

        window.addEventListener('scroll', handleScroll)

        handleScroll()

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return reachedBottom
}
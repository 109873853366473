import { ContextPartInterface, ContextPartType } from '../../../Interfaces/documentInterfaces'
import { BiMath } from 'react-icons/bi'


export const displayContext = (context: ContextPartInterface, small: boolean): string | undefined => {
    const type = context.fragment.messagePartType
    switch (type) {
        case ContextPartType.PARAGRAPH:
            return context.fragment.text ? context.fragment.text.length > 100 ? context.fragment.text.substring(0, 97) + '...' : context.fragment.text : ''
        case ContextPartType.FORMULA:
            return context.fragment.messagePartContent_TextAlt
        case ContextPartType.TEXT:
            return context.fragment.text ? context.fragment.text : ''
        case ContextPartType.TABLE:
            return context.fragment.cells ? context.fragment.messagePartContent_TextAlt.substring(0, 50) : ''
        case ContextPartType.IMAGE:
            return context.fragment.messagePartContent_TextAlt
        case ContextPartType.SECTION:
            return context.fragment.summary ? context.fragment.summary.length > 100 ? context.fragment.summary.substring(0, 97) + '...' : context.fragment.summary : ''
    }
}

export const DisplayIcon = ({ context }: { context: ContextPartInterface }) => {
    const type = context.fragment.messagePartType
    switch (type) {
        case ContextPartType.FORMULA:
            return <BiMath />
    }

    return <></>
}
export const getDate = (date: Date | null = null, interval: number | null = null) => {
    let currentDate = new Date()
    if (interval)
        currentDate.setDate(currentDate.getDate() - interval)
    if (date)
        currentDate = date

    const day = currentDate.getDate().toString().padStart(2, '0')
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const year = currentDate.getFullYear()
    const hours = (currentDate.getHours()).toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getSeconds().toString().padStart(2, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
import {
    ExpertAnswerInterface,
    MessageInterface,
    MessageInterfaceApi,
    MessageType,
} from '../../../Interfaces/messageInterfaces'
import { MessageFlow } from '../../../Utils/enums'
import React from 'react'

const fetchMessages = async (
    submessages: MessageInterfaceApi[],
    messages: MessageInterface[],
    setMessages: React.Dispatch<React.SetStateAction<MessageInterface[]>>,
) => {
    const new_messages: MessageInterface[] = []
    const message_ids = messages?.map((message: MessageInterfaceApi) => message.id)
    const previous_messages = messages
    for (const submessage of submessages) {
        if (message_ids.includes(submessage.id)) {
            continue
        }
        if (submessage.messageType === MessageType.CONTEXT_MESSAGE) {
            continue
        }
        const temp_ids = submessage.listOfAgentIds.split('&')
        const agent_ids = temp_ids[0].split(',')
        const summarizer_id = temp_ids[1] ? temp_ids[1] : ''
        submessage.agentAnswerIds = agent_ids
        submessage.summarizerAnswerIds = summarizer_id
        // let summarizerAnswer: SubMessageInterface | null = null
        // if (submessage.summarizationDto)
        //     await setSummarizerId([submessage.summarizationDto.id, submessage.id, submessage.summarizationDto.summarizationExpert.name, submessage.summarizationDto.summarizationExpert.id])
        //
        // if (submessage.AgentAnswers)
        //     for (const response of submessage.AgentAnswers) {
        //         if (response.answer !== null)
        //             submessage.AgentAnswers.push({
        //                 id: response.id,
        //                 text: response.answer,
        //                 sender: response.ExpertResponding,
        //                 timestamp: new Date(),
        //                 messageType: MessageType.EXPERT_ANSWER,
        //                 chosen: false,
        //                 chosenFinal: false,
        //                 finished: submessage.IsAnswered,
        //             })
        //     }

        // if (submessage.summarizationDto && submessage.summarizationDto.summary) {
        //     summarizerAnswer = {
        //         id: submessage.summarizationDto.id,
        //         text: submessage.summarizationDto.summary,
        //         sender: submessage.summarizationDto.summarizationExpert,
        //         timestamp: new Date(),
        //         messageType: MessageType.SUMMARIZER,
        //         chosenFinal: submessage.summarizationDto.ifChosen,
        //         chosen: submessage.summarizationDto.ifUsedForSummarization,
        //         finished: submessage.isAnswered,
        //     }
        // }
        let flow = MessageFlow.FULL
        if (submessage.agentAnswerIds?.length === 1) {
            if (!submessage.summarizerAnswerIds || submessage.summarizerAnswerIds.length === 0)
                flow = MessageFlow.NO_SUMMARY_ONE_EXPERT
            else
                flow = MessageFlow.ONE_EXPERT
        } else if (submessage.summarizerAnswerIds.length === 0)
            flow = MessageFlow.NO_SUMMARY

        const finished = (submessage.selectedExpertAnswerId !== null || submessage.selectedSummarizerAnswerId !== null)
        const agentAnswers: ExpertAnswerInterface[] = []
        if (submessage.agentAnswers) {
            for (const response of submessage.agentAnswers) {
                if (response.id === submessage?.selectedExpertAnswerId) {
                    response.chosenFinal = true
                } else {
                    response.chosenFinal = false
                }

                if (finished) {
                    response.finished = true
                } else {
                    response.finished = false
                }
                agentAnswers.push(response)
            }
        }


        const temp_message: MessageInterface = {
            ...submessage,
            agentAnswers: agentAnswers,
            messageFlow: flow,
            messageType: MessageType.USER_MESSAGE,
            summarizerAnswer: null,
            versions: 0,
            children: [],
            depth: 0,
            finished: finished,
        }
        new_messages.push(temp_message)
    }
    setMessages(previous_messages.concat(new_messages))
}

export default fetchMessages
import './DocumentUploadModal.scss'
import { AiOutlineLoading, AiOutlinePlus } from 'react-icons/ai'
import Dropzone from 'react-dropzone'
import React, { useState } from 'react'
import { uploadDocument } from '../../Requests/documentsRequests'
import { AlertSeverity, AlertType } from '../../Interfaces/alertsInterfaces'


interface DocumentUploadModalInterface {
    closeModal: Function;
    id: string;
    sendAlert: Function;
}

export const DocumentUploadModal = (props: DocumentUploadModalInterface) => {
    const [file, setFile] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const handleSend = () => {
        uploadDocument(props.id, file[0]).then(r => {
            console.log(r)
        }).catch(e => {
            props.sendAlert(AlertType.UNKNOWN, 'Error uploading document', AlertSeverity.ERROR)
        }).finally(() => {
            setLoading(false)
            props.closeModal()
        })
    }

    return (
        <div className='documentUploadModal-wrapper' onClick={() => props.closeModal()}>
            <div className='documentUploadModal' onClick={(e) => e.stopPropagation()}>
                <h1 className='documentUploadModal-title'>Upload document</h1>
                <Dropzone onDrop={acceptedFile => setFile(acceptedFile)} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className='documentUploadModal-dropzone'>
                            <input {...getInputProps()} />
                            {!file && <AiOutlinePlus className='documentUploadModal-dropzone-icon' />}
                            {file && <p className='documentUploadModal-dropzone-text'>{file[0].name}</p>}
                        </div>
                    )}
                </Dropzone>
                <div className='documentUploadModal-buttons'>
                    {!loading &&
                        <div className='documentUploadModal-cancel' onClick={() => props.closeModal()}>Cancel</div>}
                    {!loading && <div className='documentUploadModal-proceed' onClick={
                        () => {
                            setLoading(true)
                            handleSend()
                        }}>Proceed
                    </div>}
                    {loading && <AiOutlineLoading className='modal-content-buttons-loading' />}
                </div>
            </div>
        </div>
    )
}
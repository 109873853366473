import { apiDelete, apiGet, apiPost, apiPut } from '../Utils/RequestsUtil'
import Cookies from 'js-cookie'


export const getCustomProfiles = async () => {
    const jwt = Cookies.get("jwt")
    return await apiGet(
        'CustomProfile',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const createCustomInstruction = async (name: string, instruction: string) => {
    const jwt = Cookies.get("jwt")
    return await apiPost(
        'CustomProfile',
        {
            name: name,
            instruction: instruction,
        },
        false,
        // @ts-ignore
        jwt,
    )
}

export const editCustomInstruction = async (id: number, name: string, instruction: string) => {
    const jwt = Cookies.get('jwt')
    return await apiPut(
        'CustomProfile/' + id,
        {
            name: name,
            instruction: instruction,
        },
        false,
        // @ts-ignore
        jwt,
    )
}

export const deleteCustomInstruction = async (id: number) => {
    const jwt = Cookies.get('jwt')
    return await apiDelete(
        'CustomProfile/' + id,
        null,
        false,
        // @ts-ignore
        jwt,
    )
}
import { MessageInterface } from '../../../Interfaces/messageInterfaces'

export class HeapNode {
    key: string
    message: MessageInterface
    children: HeapNode[]
    depth: number
    variants: number


    constructor(key: string, message: MessageInterface, depth: number, variants: number) {
        this.key = key
        this.message = message
        this.children = []
        this.depth = depth
        this.variants = variants
    }

    addChild(node: HeapNode) {
        this.children.push(node)
    }
}

export class Heap {

    roots: HeapNode[]

    constructor() {
        this.roots = []
    }

    insertAsRoot(key: string, message: MessageInterface) {
        const newNode = new HeapNode(key, message, 0, this.roots.length)
        this.roots.push(newNode)
        for (let node of this.roots) {
            node.variants = this.roots.length
        }
    }

    insert(key: string, parent_key: string, message: MessageInterface) {
        const parentNode = this.findNode(this.roots, parent_key)
        if (parentNode) {
            const newNode = new HeapNode(key, message, parentNode.depth + 1, parentNode.children.length)
            parentNode.addChild(newNode)
            for (let node of parentNode.children) {
                node.variants = parentNode.children.length
            }
        }
    }

    findNode(nodes: HeapNode[], key: string): HeapNode | null {
        for (let node of nodes) {
            if (node.key === key) {
                return node
            }
            const childNode: HeapNode | null = this.findNode(node.children, key)
            if (childNode) {
                return childNode
            }
        }
        return null
    }

    maxDepth() {
        let maxDepth = 0
        for (let root of this.roots) {
            const depth = this.calculateDepth(root, 1)
            maxDepth = Math.max(maxDepth, depth)
        }
        return maxDepth
    }

    calculateDepth(node: HeapNode, currentDepth: number) {
        if (node.children.length === 0) {
            return currentDepth
        }
        let maxDepth = currentDepth
        for (let child of node.children) {
            const childDepth = this.calculateDepth(child, currentDepth + 1)
            maxDepth = Math.max(maxDepth, childDepth)
        }
        return maxDepth
    }
}

import './StaticFlow.scss'

interface StaticFlowInterface {
    title: string;
    bullets: {
        title: string;
        text: string;
    }[];
}

export const StaticFlow = (props: StaticFlowInterface) => {
    return (
        <div className='staticFlow'>
            <h1 className='staticFlow-title'>{props.title}</h1>
            <div className='staticFlow-flow'>
                {props.bullets.map((bullet, index) => {
                    return (
                        <div className='staticFlow-flow-bullet' key={index}>
                            <div className='staticFlow-flow-bullet-number'>{index + 1}</div>
                            <div className='staticFlow-flow-bullet-text'>
                                <h2 className='staticFlow-flow-bullet-text-title'>{bullet.title}</h2>
                                <p className='staticFlow-flow-bullet-text-text'>{bullet.text}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
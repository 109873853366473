import './Stats.scss'
import InformationBlock from '../../Charts/InformationBlock'
import { orange, orangeYellow, pink, yellow } from '../../../../Utils/colors'
import React, { useEffect, useState } from 'react'
import { DeveloperInterface, UserInterface } from '../../../../Interfaces/userInterfaces'
import { getMyUserStatsDaily } from '../../../../Requests/userRequests'
import { getDate } from '../../../../Utils/functions'
import MonthSlider from '../../../../Components/MonthSlider/MonthSlider'
import { useWindowDimensions } from '../../../../Utils/hooks'
import { useGlobalState } from '../../../../GlobalStateContext'
import { NumberOfRequestsPerDay } from '../../Charts/NumberOfRequestsPerDay'
import PricePerDay from '../../Charts/PricePerDay'
import { AiOutlineLoading } from 'react-icons/ai'

interface StatsInterface {
    user: UserInterface | DeveloperInterface | null
}

const totalCalls = (calls: CallsInterface[]) => {
    let total = 0
    for (const call of calls) {
        total += call.expertCallsSum
        total += call.summarizerCallsSum
    }
    return total
}

// const mostUsedExpert = (stats: any) => {
//     if (!stats) {
//         return ''
//     }
//     let expert = ''
//     const counts: any = {}
//     for (const call of stats) {
//         const name = call.expertDTO?.name
//         if (name in counts) {
//             // @ts-ignore
//             counts[name] += 1
//         } else {
//             // @ts-ignore
//             counts[name] = 1
//         }
//     }
//     let max = 0
//     for (const name of Object.keys(counts)) {
//         if (counts[name] > max) {
//             max = counts[name]
//             expert = name
//         }
//     }
//     return expert
// }
const totalCallsPrice = (calls: CallsInterface[]) => {
    let total = 0
    for (const call of calls) {
        total += call.moneyUsedSum
    }
    return Math.floor((total / 100000000) * 100) / 100 + '$'
}

const getLastDayOfMonth = (year: any, month: any, mobile: boolean) => {
    const nextMonthFirstDay = new Date(year, month + 1, 1)
    const thisMonthFirstDay = new Date(year, month, 1)
    // @ts-ignore
    const lastDayOfMonth = new Date(Math.min(nextMonthFirstDay - 1, new Date()))

    if (mobile) {
        let currentDate = new Date()
        let sevenDaysAgo = new Date(currentDate)
        sevenDaysAgo.setDate(currentDate.getDate() - 7)
        return [sevenDaysAgo, currentDate]
    }

    return [thisMonthFirstDay, lastDayOfMonth]
}

export interface CallsInterface {
    date: string
    moneyUsedSum: number
    expertInputTokenSum: number
    expertOutputTokenSum: number
    expertCallsSum: number
    summarizerCallsSum: number
    summarizerInputTokenSum: number
    summarizerOutputTokenSum: number
}

export const Stats = ({ user }: StatsInterface) => {
    const dims = useWindowDimensions()
    const mobile = dims.width < 767
    const [calls, setCalls] = useState<CallsInterface[]>([])
    const [month, setMonth] = useState(new Date())
    const [barChartConfig, setBarChartConfig] = useState<any>({})
    const [globalState, globalActions] = useGlobalState()
    const [usageMode, setUsageMode] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        setLoading(true)
        const [start, end] = getLastDayOfMonth(month.getFullYear(), month.getMonth(), mobile)
        const now = new Date(end)
        const twoHoursAgo = new Date(now.getTime() - (120 * 60 * 1000))

        const start_str = getDate(start, null)
        const end_str = getDate(twoHoursAgo, null)
        getMyUserStatsDaily(start_str, end_str).then(r => {
            setCalls(r)
            setBarChartConfig({
                start: new Date(start_str),
                end: new Date(end_str),
            })
            setLoading(false)
        })
    }, [month, mobile])
    let balance = 0
    if (globalState.user?.balance) {
        // @ts-ignore
        balance = globalState.user?.balance
    }
    return (
        <div className='dashboard-content-stats'>
            {/*<div className='dashboard-content-stats-statRow1'>*/}
            {/*<InformationBlock bg={lightPurple} title={'Balance'}>{balance}$ </InformationBlock>*/}
            {/*<InformationBlock bg={pink} title={'Monthly usage'}>{totalCallsPrice(calls)} </InformationBlock>*/}
            {/*<InformationBlock bg={orange} title={'Calls this month'}>{totalCalls(calls)}</InformationBlock>*/}
            {/*</div>*/}
            <div className='dashboard-content-stats-statRow0'>
                <div className='dashboard-content-stats-statRow0-balance'>
                    <h1>Tokens</h1>
                    <h2 className='dashboard-content-stats-statRow0-balance-num'>{balance}</h2>
                </div>
                <div className='dashboard-content-stats-statRow0-slider'>
                    <MonthSlider date={month} setDate={setMonth} />
                </div>

            </div>
            <div className='dashboard-content-stats-statRow1'>
                <div className='dashboard-content-stats-statRow1-header'>
                    <h1>Monthly usage</h1>
                    <div className='dashboard-content-stats-statRow1-header-settings'>
                        <h2 className={usageMode ? 'dashboard-content-stats-statRow1-header-settings-item-active' : 'dashboard-content-stats-statRow1-header-settings-item'}
                            onClick={() => setUsageMode(true)}>Cost</h2>
                        <h2 className={!usageMode ? 'dashboard-content-stats-statRow1-header-settings-item-active' : 'dashboard-content-stats-statRow1-header-settings-item'}
                            onClick={() => setUsageMode(false)}>Calls</h2>
                    </div>
                    {loading && <AiOutlineLoading className='dashboard-content-stats-statRow1-header-loading' />}
                </div>
                <span />

                {!usageMode && <NumberOfRequestsPerDay data={calls} lineChartConfig={barChartConfig}
                                                       setLineChartConfig={setBarChartConfig} />}
                {usageMode && <PricePerDay data={calls} barChartConfig={barChartConfig}
                                           setBarChartConfig={setBarChartConfig} />}
                <span />
                <div className='dashboard-content-stats-statRow1-informations'>
                    <InformationBlock bg={pink}
                                      title={'Total usage'}>{loading ? '...' : totalCallsPrice(calls)} </InformationBlock>
                    <InformationBlock bg={orange}
                                      title={'Total Calls'}>{loading ? '...' : totalCalls(calls)}</InformationBlock>
                    <InformationBlock bg={orangeYellow}
                                      title={'Cos 1'}>{loading ? '...' : totalCalls(calls)}</InformationBlock>
                    <InformationBlock bg={yellow}
                                      title={'Cos 2'}>{loading ? '...' : totalCalls(calls)}</InformationBlock>

                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { FreeBlob } from './FreeBlob'

interface Blob {
    id: number;
    number: number;
    scale: number;
    rotate: number;
    width: string;
    height: string;
    top: string;
    left: string;
    lifespan: number;
}

const generateNewBlob = (lifespan: number, min: number, max: number): Blob => {
    return {
        id: Math.floor(Math.random() * 100000), // Unique key
        number: Math.floor(Math.random() * 14), // Unique key
        scale: 1, // Scale set to 1
        rotate: Math.random() * 360, // Random rotation
        width: `${min + Math.random() * max}vh`, // Random width
        height: `${min + Math.random() * max}vh`, // Random height
        top: `${Math.random() * 100}vh`, // Random top position
        left: `${Math.random() * 100}vw`, // Random left position
        lifespan: lifespan,
    }
}

interface FreeBlobEnvInterface {
    width?: string;
    height?: string;
    min?: number;
    max?: number;
}

const FreeBlobEnv = ({ width = '100%', height = '100%', min = 20, max = 30 }: FreeBlobEnvInterface) => {
    const [blobs, setBlobs] = useState<Blob[]>([])


    useEffect(() => {
        const addBlob = (lifespan: number) => {
            const newBlob = generateNewBlob(lifespan, min, max)
            setBlobs(currentBlobs => [...currentBlobs, newBlob])
        }

        addBlob(25000)
        addBlob(25000)
        addBlob(25000)

        const intervalId = setInterval(() => addBlob(15000), 5000)
        return () => clearInterval(intervalId)
    }, [])

    const handleBlobExpire = (number: number) => {
        setBlobs(currentBlobs => currentBlobs.filter(blob => blob.number !== number))
    }

    return (
        <div className='freeBlobEnv' style={{
            width: width,
            height: height,

        }}>
            <div className='freeBlobEnv-blobs'>
                {blobs.map(blob => (
                    <FreeBlob
                        key={blob.id}
                        number={blob.number}
                        scale={blob.scale}
                        rotate={blob.rotate}
                        width={blob.width}
                        height={blob.height}
                        animated={true}
                        top={blob.top}
                        left={blob.left}
                        lifespan={blob.lifespan}
                        onDestruction={() => handleBlobExpire(blob.number)} // Handle blob expiration

                    />
                ))}
            </div>
            <div className='freeBlobEnv-shade' />

        </div>
    )
}

export default FreeBlobEnv
import './MyAgents.scss'
import React, { useEffect, useState } from 'react'
import { getMyDeveloperAccount } from '../../../Requests/userRequests'
import AgentCard from '../../../Components/AgentCard/AgentCard'
import { AiOutlineLoading, AiOutlinePlusSquare } from 'react-icons/ai'
import SEO from '../../../Components/SEO'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'

export const MyAgents = ({ navigate }: { navigate: Function }) => {
    const [loading, setLoading] = useState(true)
    const [experts, setExperts] = useState([])
    const [summarizers, setSummarizers] = useState([])
    useEffect(() => {
        getMyDeveloperAccount().then(r => {
            setExperts(r.expertsCreated)
            setSummarizers(r.summarizersCreated)
            setLoading(false)
        })
    }, [])

    return (
        <div className='myAgents-wrapper'>
            <SEO
                title='APPI | My Agents'
                description='My agents'
                keywords={['appi', 'my', 'agents', 'developer', 'api', 'keys', 'delete', 'danger', 'zone']}
                type='website'
                name='APPI | My Agents'
            />
            <FreeBlobEnv width={'100%'} height={'100%'} min={40} max={20} />
            {loading && <div className='myAgents'>
                <AiOutlineLoading className='myAgents-loading' />
            </div>}
            {!loading &&
                <div className='myAgents'>
                    <div className='myAgents-title'>
                        <h1>EXPERTS</h1>
                        <AiOutlinePlusSquare className='myAgents-title-new'
                                             onClick={() => navigate('/developer/agents/new?type=expert')} />
                    </div>
                    <div className='myAgents-panel' id='experts'>
                        {experts?.length > 0 && experts?.map((expert: any, index) => (
                            <AgentCard key={'expert' + index} agent={expert} nav='dev' />
                        ))}
                    </div>
                    <div className='myAgents-title myAgents-title-summarizer'>
                        <h1>SUMMARIZERS</h1>
                        <AiOutlinePlusSquare className='myAgents-title-new'
                                             onClick={() => navigate('/developer/agents/new?type=summarizer')} />
                    </div>
                    <div className='myAgents-panel' id='summarizers'>
                        {summarizers?.length > 0 && summarizers?.map((summarizer: any, index) => (
                            <AgentCard key={'expert' + index} agent={summarizer} nav='dev' />
                        ))}
                    </div>
                </div>}
        </div>
    )
}
import './StaticList.scss'
import React, { ReactNode, useState } from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'
import { Element } from 'react-scroll'

interface StaticListInterface {
    title: TitleInterface
    points?: PointInterface[]
    className?: string
    line?: string
}

interface PointInterface {
    title?: string
    text?: string
    icon?: ReactNode
    img?: string
    button?: () => void
    buttonText?: string
    children?: ReactNode
}

export const StaticList = (props: StaticListInterface) => {
    return (
        <Element name={props.className ? props.className : ''} className={'staticList ' + props.className}>
            <Title
                title={props.title.title}
                subtitle={props.title.subtitle}
                imgPosition={props.title.imgPosition}
                highlight={props.title.highlight}
                titleImg={props.title.titleImg}
                newlineHighlight={props.title.newlineHighlight}
            />
            {props.points?.length === 1 && <div className="staticList-list staticList-list1">
                <StaticListPoint
                    title={props.points[0].title}
                    text={props.points[0].text}
                    icon={props.points[0].icon}
                    img={props.points[0].img}
                    button={props.points[0].button}
                    buttonText={props.points[0].buttonText}
                    children={props.points[0].children}
                />
            </div>}
            {(props.points?.length === 2 || (props.points?.length === 3 && props.line === 'one')) && <div
                className="staticList-list staticList-list2">
                <StaticListPoint
                    title={props.points[0].title}
                    text={props.points[0].text}
                    icon={props.points[0].icon}
                    img={props.points[0].img}
                    button={props.points[0].button}
                    buttonText={props.points[0].buttonText}
                    children={props.points[0].children}
                />
                <StaticListPoint
                    title={props.points[1].title}
                    text={props.points[1].text}
                    icon={props.points[1].icon}
                    img={props.points[1].img}
                    button={props.points[1].button}
                    buttonText={props.points[1].buttonText}
                    children={props.points[1].children}
                />
                {props.points[2] && <StaticListPoint
                    title={props.points[2].title}
                    text={props.points[2].text}
                    icon={props.points[2].icon}
                    img={props.points[2].img}
                    button={props.points[2].button}
                    buttonText={props.points[2].buttonText}
                    children={props.points[2].children}
                />}

            </div>}
            {(props.points?.length === 3 && props.line !== 'one') && <div
                className="staticList-list staticList-list3">
                <div className="staticList-list3-upper">
                    <StaticListPoint
                        title={props.points[0].title}
                        text={props.points[0].text}
                        icon={props.points[0].icon}
                        img={props.points[0].img}
                        button={props.points[0].button}
                        buttonText={props.points[0].buttonText}
                        children={props.points[0].children}
                    />
                </div>
                <div className="staticList-list3-lower">
                    <StaticListPoint
                        title={props.points[1].title}
                        text={props.points[1].text}
                        icon={props.points[1].icon}
                        img={props.points[1].img}
                        button={props.points[1].button}
                        buttonText={props.points[1].buttonText}
                        children={props.points[1].children}
                    />
                    <StaticListPoint
                        title={props.points[2].title}
                        text={props.points[2].text}
                        icon={props.points[2].icon}
                        img={props.points[2].img}
                        button={props.points[2].button}
                        buttonText={props.points[2].buttonText}
                        children={props.points[2].children}
                    />
                </div>
            </div>}
        </Element>
    )
}

const StaticListPoint = (props: PointInterface) => {
    const [hovered, setHovered] = useState<boolean>(false)
    return (
        <div className="staticListPoint"
             onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
        >
            <div className="staticListPoint-icon">
                {props.icon}
            </div>
            {(props.text || props.title) && <div className="staticListPoint-text">
                <h1>{props.title}</h1>
                <h2>{props.text}</h2>
            </div>}
            {props.img && <div className="staticListPoint-img">
                <img src={props.img} alt="icon" />
            </div>}
            {!props.img && props.button !== undefined && <div className="staticListPoint-img" />}
            {React.Children.map(props.children, child =>
                React.isValidElement(child) ?
                    // @ts-ignore
                    React.cloneElement(child, { hovered })
                    : child,
            )}
            {props.button !== undefined && <button onClick={props.button} className="staticListPoint-button">
                {props.buttonText}
            </button>}

        </div>
    )
}
import { useEffect, useState } from 'react'
import '../Features.scss'
import '../../../Landing/DocumentShowcase/DocumentShowcase.scss'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import { AiOutlineSend, AiOutlineUser } from 'react-icons/ai'
import { FaRobot } from 'react-icons/fa6'
import { Parser } from '../../../Chat/Message/Parser'

enum Phase {
        SelectExpert = 0,
        TypePrompt = 1,
        ReceiveResponse = 2,
}

// NOTE: CONFIG
const prompt: string = 'prompt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
const response: string = 'response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
const conversationStarter: string = 'Hi! How can I help you with technical issues today?'

const phaseText = [
        {
                'title': 'Select an expert',
                'description': 'A{P}PI provides a wide variety of specialized experts. If you still can\'t find an expert that suits your needs, A{P}PI makes it easy to create one on your own',
        },
        {
                'title': 'Prompt the selected expert',
                'description': 'Prompt your selected specialized AI Expert',
        },
        {
                'title': 'Receive a response',
                'description': 'A{P}PI specialized AI Experts provide the highest quality responses with least hallucinations',
        },
]

const experts = [
        {
                name: 'Law AI Expert',
                shortDescription: 'Solve legal problems with AI',
                logoUrl: 'FaGavel', // Icon for law
                logoColorHex: '#b40c0c', // Red
        },
        {
                name: 'Finance AI Expert',
                shortDescription: 'Financial insights powered by AI',
                logoUrl: 'FaChartLine', // Icon for finance
                logoColorHex: '#B09A51', // Gold
        },
        {
                name: 'Tech Support AI Expert',
                shortDescription: 'AI-driven technical support',
                logoUrl: 'FaTools', // Icon for tech support
                logoColorHex: '#0C6CB4', // Blue
        },
        {
                name: 'Cybersecurity AI Expert',
                shortDescription: 'Strengthen security with AI',
                logoUrl: 'FaShieldAlt', // Icon for cybersecurity
                logoColorHex: '#F5A302', // Orange
        },
]

export const AiExpertF = () => {

        const [phase, setPhase] = useState<Phase>(Phase.SelectExpert)
        const [input, setInput] = useState<string>('')
        const [output, setOutput] = useState<string>('')
        const [indexI, setIndexI] = useState<number>(0)
        const [indexO, setIndexO] = useState<number>(0)

        useEffect(() => {
                if (phase === Phase.TypePrompt) {
                        let interval: NodeJS.Timeout
                        interval = setInterval(() => {
                                setIndexI((prevIndex) => {
                                        if (prevIndex < prompt.length) {
                                                setInput((prev) => prev + prompt[prevIndex])
                                                return prevIndex + 1
                                        } else {
                                                clearInterval(interval)
                                                return prevIndex
                                        }
                                })
                        }, 15) // Speed of typing

                        return () => clearInterval(interval)
                } else {
                        setIndexI(0)
                        setInput('')
                }
        }, [phase, prompt])

        useEffect(() => {
                if (phase === Phase.ReceiveResponse) {
                        let interval: NodeJS.Timeout

                        interval = setInterval(() => {
                                setIndexO((prevIndex) => {
                                        if (prevIndex < response.length) {
                                                setOutput((prev) => prev + response[prevIndex])
                                                return prevIndex + 1
                                        } else {
                                                clearInterval(interval)
                                                return prevIndex
                                        }
                                })
                        }, 20) // Speed of typing

                        return () => clearInterval(interval)
                } else {
                        setIndexO(0)
                        setOutput('')
                }
        }, [phase, response])

        return (
                <div className="features-feature features-aiExpert">
                        <div className="features-aiExpert-funTool">
                                <div className="features-aiExpert-funTool-left">
                                        <h2>{phaseText[phase].title}</h2>
                                        <p>{phaseText[phase].description}</p>
                                        <div className="features-aiExpert-funTool-left-buttons">
                                                {phase !== Phase.SelectExpert &&
                                                        <button
                                                                className="features-aiExpert-funTool-left-buttons-prev"
                                                                onClick={() => setPhase(phase - 1)}>
                                                                Previous
                                                        </button>}
                                                {phase !== Phase.ReceiveResponse &&
                                                        <button
                                                                className="features-aiExpert-funTool-left-buttons-next"
                                                                onClick={() => setPhase(phase + 1)}>
                                                                Next
                                                        </button>}

                                        </div>
                                </div>
                                <div className="features-aiExpert-funTool-right">
                                        {phase === Phase.SelectExpert &&
                                                <div className="features-aiExpert-funTool-right-experts">
                                                        {experts.map((agent, index) => {
                                                                return (
                                                                        <AgentCard
                                                                                key={index}
                                                                                agent={{
                                                                                        name: agent.name,
                                                                                        shortDescription: agent.shortDescription,
                                                                                        tags: [],
                                                                                        logoColorHex: agent.logoColorHex,
                                                                                        authorId: '',
                                                                                        logoUrl: agent.logoUrl,
                                                                                        description: '',
                                                                                        usageInstruction: '',
                                                                                        id: '',
                                                                                        isPublic: true,
                                                                                        suggestedAIModel: '',
                                                                                        documentDisplayPermission: 0,
                                                                                        agentType: '',
                                                                                }}
                                                                                description={false}
                                                                                onClickRedirect={false}
                                                                                colored={false}
                                                                                small={true}
                                                                                onClick={() => {
                                                                                }}
                                                                                id={agent.name === 'Tech Support AI Expert' ? 'selectedExpert' : ''}
                                                                        />
                                                                )
                                                        })}
                                                </div>}
                                        {phase === Phase.TypePrompt &&
                                                <div className="features-aiExpert-funTool-right-mockChat">
                                                        <div
                                                                className={'message response no-hover'}
                                                        >
                                                                <div className="message-innerWrapper">
                                                                        <div className="message-icon-wrapper">
                                                                                <FaRobot className="message-icon" style={{
                                                                                        color: experts[2].logoColorHex ?? '',
                                                                                }} />
                                                                                <div className="message-icon-sender">{experts[2].name}</div>
                                                                        </div>
                                                                        <div className="message-text-wrapper">
                                                                                <div className="message-text">
                                                                                        <Parser>{conversationStarter}</Parser>
                                                                                </div>
                                                                        </div>


                                                                </div>
                                                        </div>
                                                        <div className="features-aiExpert-funTool-right-mockChat-input">
                                                                <p>{input}</p>
                                                                <AiOutlineSend />
                                                        </div>
                                                </div>
                                        }
                                        {phase === Phase.ReceiveResponse &&
                                                <div className="features-aiExpert-funTool-right-mockChat">
                                                        <div
                                                                className={'message response no-hover'}
                                                        >
                                                                <div className="message-innerWrapper">
                                                                        <div className="message-icon-wrapper">
                                                                                <AiOutlineUser />
                                                                                <div className="message-icon-sender">You</div>
                                                                        </div>
                                                                        <div className="message-text-wrapper">
                                                                                <div className="message-text">
                                                                                        <Parser>{prompt}</Parser>
                                                                                </div>
                                                                        </div>


                                                                </div>
                                                        </div>
                                                        <div
                                                                className={'message response no-hover'}
                                                        >
                                                                <div className="message-innerWrapper">
                                                                        <div className="message-icon-wrapper">
                                                                                <FaRobot className="message-icon" style={{
                                                                                        color: experts[2].logoColorHex ?? '',
                                                                                }} />

                                                                                <div className="message-icon-sender">{experts[2].name}</div>
                                                                        </div>
                                                                        <div className="message-text-wrapper">
                                                                                <div className="message-text">
                                                                                        <Parser>{output}</Parser>
                                                                                </div>
                                                                        </div>


                                                                </div>
                                                        </div>
                                                </div>}
                                </div>
                        </div>
                </div>
        )
}

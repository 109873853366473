import { useState } from 'react'
import { MockMessage } from './MockMessage'

// NOTE: CONFIG
const leftSide = {
    title: 'titleee',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
}
const prompt: string = 'hola'
const conversations = [
    {
        agent: {
            name: 'Law AI Expert',
            shortDescription: 'Solve legal problems with AI',
            logoUrl: 'FaGavel', // Icon for law
            logoColorHex: '#b40c0c', // Red
        },
        response: 'law response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
        agent: {
            name: 'Finance AI Expert',
            shortDescription: 'Financial insights powered by AI',
            logoUrl: 'FaChartLine', // Icon for finance
            logoColorHex: '#B09A51', // Gold
        },
        response: 'finance response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
]

interface conversationInterface {
    agent: any,
    response: string,
}

export const MultiExpertF = () => {
    const [conversation, setConversation] = useState<conversationInterface | null>(null)

    return (
        <div className="features-feature features-multiExpert">
            <div className="features-multiExpert-right">
                <div className="features-multiExpert-right-prompt">
                    <MockMessage
                        authorName="You"
                        message={prompt}
                        isUser={true}
                        className="no-hover"
                    />
                </div>
                <div className="features-multiExpert-right-responses">
                    {conversations.map((conv: conversationInterface, index: number) => {
                        const selected: boolean = conv === conversation

                        return (
                            <div
                                key={index}
                                className={
                                    `features-multiExpert-right-responses-response-${index}
                                                                                features-multiExpert-right-responses-response` + ' ' +
                                    (conversation === null ? '' : selected ? 'selected' : 'fade')}
                                onClick={selected ?
                                    () => setConversation(null)
                                    :
                                    () => setConversation(conv)
                                }
                            >
                                <MockMessage
                                    authorName={conv.agent.name}
                                    message={conv.response}
                                    iconColor={conv.agent.logoColorHex}
                                    isUser={false}
                                    className="no-hover"
                                />
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

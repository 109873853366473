import './StaticUs.scss'
import { FaLinkedin } from 'react-icons/fa'
import React from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticUsInterface {
    title: TitleInterface
}

export const StaticUs = (props: StaticUsInterface) => {
    return (
        <div className="staticUs">
            <Title
                title={props.title.title}
                subtitle={props.title.subtitle}
                imgPosition={props.title.imgPosition}
                highlight={props.title.highlight}
                titleImg={props.title.titleImg}
                newlineHighlight={props.title.newlineHighlight}
            />
            <div className="staticUs-content">
                <div className="staticUs-content-person">
                    <img src={require('../../../../Images/Assets/Labs/bartek.jpeg')} alt="person" />
                    <h1>Bartosz Kostarczyk</h1>
                    <h2>CEO</h2>

                    <p>
                        Bartek is passionate of inventions that optimize workload and help people be better professionals
                    </p>
                    <div className="staticUs-content-person-socials">
                        <a href="https://www.linkedin.com/in/bartosz-kostarczyk-702476229/">
                            <FaLinkedin className="staticUs-content-person-socials-link" />
                        </a>
                    </div>

                </div>
                <div className="staticUs-content-person">
                    <img src={require('../../../../Images/Assets/Labs/adam.jpg')} alt="person"
                         className="staticUs-content-person-flipped" />
                    <h1>Adam Kaniasty</h1>
                    <h2>CTO</h2>
                    <p>
                        Adam is a tech enthusiast who loves to create new data solutions and optimize existing ones.
                    </p>
                    <div className="staticUs-content-person-socials">
                        <a href="https://www.linkedin.com/in/adam-kaniasty?originalSubdomain=pl">
                            <FaLinkedin className="staticUs-content-person-socials-link" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
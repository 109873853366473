import './Message.scss'
import './MessageMobile.scss'
import { ConversationState } from '../../../Utils/enums'
import { useEffect, useState } from 'react'
import { MessageItem } from './MessageItem/MessageItem'
import { ExpertAnswerInterface, MessageInterface } from '../../../Interfaces/messageInterfaces'
import { MessageAnswer } from './MessageAnswer/MessageAnswer'
import { useChatContext } from '../ChatPropsManager'


interface MessageComponentInterface {
    message: MessageInterface,
    selectedMessages: any[],
    conversationState: ConversationState,
    setSelectedMessages: Function
    setFinalAnswer: Function
    finalAnswer: any
    editPrompt: Function
}

const Message = ({
    message,
    selectedMessages,
    conversationState,
    setSelectedMessages,
    setFinalAnswer,
    finalAnswer,
    editPrompt,
}: MessageComponentInterface) => {

    const {
        generationStage,
        flow,
        setFlow,
    } = useChatContext()

    useEffect(() => {
        if (message.finished && message.agentAnswers && message.agentAnswers?.length > 0) {
            let folded: string[] = []
            message.agentAnswers.forEach((subMessage) => {
                if (!subMessage.chosenFinal)
                    folded.push(subMessage.id)
            })
            if (message.summarizerAnswer && !message.summarizerAnswer.chosenFinal)
                folded.push(message.summarizerAnswer.id)
            setFolded(folded)
        }
    }, [message.finished])

    const [alignment, setAlignment] = useState<boolean>(true)
    const [folded, setFolded] = useState<any[]>([])
    return (
        <div className="messageBlock">
            <MessageItem message={message}
                folded={folded}
                conversationState={conversationState}
                isSelected={false}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
                setFinalAnswer={setFinalAnswer}
                editPrompt={editPrompt}
                setFlow={setFlow}
                flow={flow}
                alignment={alignment}
                setAlignment={setAlignment}
            />
            <div className="messageBlock-folded">
                {message?.agentAnswers?.map((subMessage: ExpertAnswerInterface, index: number) => {
                    if (folded.includes(subMessage.id)) {
                        return (
                            <MessageAnswer message={subMessage}
                                key={'subMessage' + index}
                                folded={folded}
                                fold={() => {
                                    if (!folded.includes(subMessage.id))
                                        setFolded([...folded, subMessage.id])
                                    else setFolded(folded.filter((id: string) => id !== subMessage.id))
                                }}
                                conversationState={conversationState}
                                isSelected={selectedMessages.includes(subMessage.id) || finalAnswer === subMessage.id}
                                selectedMessages={selectedMessages}
                                setSelectedMessages={setSelectedMessages}
                                setFinalAnswer={setFinalAnswer}
                                editPrompt={editPrompt}
                                setFlow={setFlow}
                                flow={flow}
                                alignment={alignment}
                                setAlignment={setAlignment}
                                generation_stage={generationStage[message.id + "_" + subMessage.expertResponding.id]}

                            />
                        )
                    }
                    else return null
                },
                )}
                {/*{(message?.agentAnswers && folded.includes(message?.summarizer_answer.id)) && (*/}
                {/*    <MessageAnswer message={message.summarizer_answer}*/}
                {/*                 fold={() => {*/}
                {/*                     if (!folded.includes(message.summarizer_answer.id) && !message.chosenFinal)*/}
                {/*                         setFolded([...folded, message.summarizer_answer.id])*/}
                {/*                     else setFolded(folded.filter((id: string) => id !== message.summarizer_answer.id))*/}
                {/*                 }}*/}
                {/*                 folded={folded}*/}
                {/*                 conversationState={conversationState}*/}
                {/*                 isSelected={selectedMessages.includes(message.summarizer_answer.id) || finalAnswer[0] === message.summarizer_answer.id}*/}
                {/*                 selectedMessages={selectedMessages}*/}
                {/*                 setSelectedMessages={setSelectedMessages}*/}
                {/*                 setFinalAnswer={setFinalAnswer}*/}
                {/*                 editPrompt={editPrompt}*/}
                {/*                 setFlow={setFlow}*/}
                {/*                 flow={flow}*/}
                {/*                 alignment={alignment}*/}
                {/*                 setAlignment={setAlignment}*/}
                {/*    />)}*/}
            </div>
            <div
                className={alignment ? 'messageBlock-unfolded' : 'messageBlock-unfolded messageBlock-unfolded-vertical'}>
                {message?.agentAnswers?.map((subMessage, index: number) => {
                    if (!folded.includes(subMessage.id)) {
                        return (
                            <MessageAnswer message={subMessage}
                                key={'subMessage' + index}
                                folded={folded}
                                fold={() => {
                                    if (!folded.includes(subMessage.id) && !subMessage.chosenFinal)
                                        setFolded([...folded, subMessage.id])
                                    else setFolded(folded.filter((id: string) => id !== subMessage.id))
                                }}
                                conversationState={conversationState}
                                isSelected={selectedMessages.includes(subMessage.id) || finalAnswer === subMessage.id}
                                selectedMessages={selectedMessages}
                                setSelectedMessages={setSelectedMessages}
                                setFinalAnswer={setFinalAnswer}
                                editPrompt={editPrompt}
                                setFlow={setFlow}
                                flow={flow}
                                alignment={alignment}
                                setAlignment={setAlignment}
                                generation_stage={generationStage[message.id + '_' + subMessage.expertResponding.id]}
                            />
                        )
                    }
                    else return null
                },
                )}
                {/*{(message?.SummarizerAnswer && !folded.includes(message?.SummarizerAnswer.id)) && (*/}
                {/*    <MessageAnswer message={message.SummarizerAnswer}*/}
                {/*                   folded={folded}*/}
                {/*                   fold={() => {*/}
                {/*                       if (!folded.includes(message.SummarizerAnswer.id) && !message.SummarizerAnswer.chosenFinal)*/}
                {/*                           setFolded([...folded, message.SummarizerAnswer.id])*/}
                {/*                       else setFolded(folded.filter((id: string) => id !== message.SummarizerAnswer.id))*/}
                {/*                   }}*/}
                {/*                   conversationState={conversationState}*/}
                {/*                   isSelected={selectedMessages.includes(message.SummarizerAnswer.id) || finalAnswer[0] === message.SummarizerAnswer.Id}*/}
                {/*                   selectedMessages={selectedMessages}*/}
                {/*                   setSelectedMessages={setSelectedMessages}*/}
                {/*                   setFinalAnswer={setFinalAnswer}*/}
                {/*                   editPrompt={editPrompt}*/}
                {/*                   setFlow={setFlow}*/}
                {/*                   flow={flow}*/}
                {/*                   alignment={alignment}*/}
                {/*                   setAlignment={setAlignment}*/}
                {/*    />)}*/}

            </div>
        </div>
    )
}

export default Message
import './Unfiltered.scss'
import SearchBar from '../../../Components/SearchBar/SearchBar'
import React from 'react'
import AgentCard from '../../../Components/AgentCard/AgentCard'
import { AiOutlineLoading } from 'react-icons/ai'
import { AgentInterface } from '../../../Interfaces/agentsInterfaces'


interface UnfilteredInterface {
    agents: AgentInterface[],
    summarizers: any[],
    navigate: Function
    loading: boolean
    tags?: string[]
}

export const Unfiltered = ({ agents, summarizers, navigate, loading, tags }: UnfilteredInterface) => {

    const tags_2 = [
        'Trending',
        'Academic',
        'Programming',
    ]
    const [selectedCategory, setSelectedCategory] = React.useState<string>('Recommended')
    const filteredAgents = agents.filter(agent => agent.tags.some(tag => tags_2.includes(tag)))

    const categories = ['Recommended', 'Most used', 'Trending', 'Academic', 'Programming']
    return (
        <div className='agents'>
            <h1 className='agents-title'>Find and use the best agents</h1>
            <SearchBar agents={agents.concat(summarizers)} />
            <div className='agents-tags'>
                {tags_2.map((tag: string, index: number) => (
                    <p className='agents-tags-tag' key={index}
                       onClick={() => navigate('/agents?categories=' + tag)}
                    >
                        {tag}
                    </p>
                ))}
            </div>
            {loading && <AiOutlineLoading className='agents-loading' />}
            {!loading && <div className='agents-recommendations-wrapper'>
                <div className='agents-appList-selections'>
                    {categories.map((category: string, index: number) => (
                        <div className={selectedCategory === category ? 'agents-appList-selections-selection agents-appList-selections-selection-selected' : 'agents-appList-selections-selection'} key={index}
                             onClick={() => setSelectedCategory(category)}>
                            <h1>{category}</h1>
                        </div>
                    ))}
                </div>
                <div className='agents-appList'>
                    {agents.length !== 0 && agents.slice(0, 50).map((app: AgentInterface, index: number) => (
                        <AgentCard type='expert' agent={app} key={index} />
                    ))}
                </div>
                <div className='agents-seeMoreButton' onClick={() => navigate('/agents?type=experts')}>See all experts
                </div>
                {/*<h1 className='agents-recommendations agents-recommendations-summarizers'>Featured AI summarizers</h1>*/}
                {/*<div className='agents-appList agents-appList-bottom'>*/}
                {/*    {summarizers.length !== 0 && summarizers.slice(0, 6).map((app: ApplicationInterface, index: number) => (*/}
                {/*        <AgentCard type='summarizer' agent={app} key={index} />*/}
                {/*    ))}*/}
                {/*</div>*/}
                {/*<div className='agents-seeMoreButton agents-seeMoreButton-summarizers'*/}
                {/*     onClick={() => navigate('/agents?type=summarizers')}>See all summarizers*/}
                {/*</div>*/}


            </div>}

        </div>
    )
}

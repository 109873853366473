import './StaticPrompt.scss'

interface StaticPromptInterface {
    title: string
    subtitle?: string
    href?: string
    button?: string
}

export const StaticPrompt = (props: StaticPromptInterface) => {
    return (
        <div className="staticPrompt">
            <div className="staticPrompt-text">
                <h1 className="staticPrompt-text-title">{props.title}</h1>
                <h2 className="staticPrompt-text-subtitle">{props.subtitle}</h2>
            </div>
            <div className="staticPrompt-buttons">
                <a href={props.href ? props.href : '/contact'}
                   className="staticPrompt-buttons-button">{props.button ? props.button : 'Contact us'}</a>
            </div>

        </div>
    )
}
import React from 'react'
import { ModalOpen } from '../Conversation'
import { useChatContext } from '../../ChatPropsManager'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import './ConfigDroplist/ConfigDroplist.scss'

const APPI_icon = require('../../../../Images/Logo/APPI_icon.png')


interface StarterInterface {
    customProfiles: any
    selectedCustomInstruction: any
    setSelectedCustomInstruction: Function
    CustomProfilesOpen: boolean
    setCustomProfilesOpen: Function
    models: any[]
    selectedModel: any
    setSelectedModel: Function
    languages: any[]
    modal: ModalOpen
    setModal: Function
    selectedLanguage: any
    setSelectedLanguage: Function
}

export const Starter = ({
                            models,
                            selectedModel,
                            setSelectedModel,
                            modal,
                            setModal,
                        }: StarterInterface) => {

    const {
        agentsList,
        setAgentsSelected,
        agentsSelected,
    } = useChatContext()

    const handleSelect = (modalTarget: ModalOpen, close: boolean) => {
        if (modal === modalTarget || close)
            setModal(ModalOpen.NULL)
        else
            setModal(modalTarget)
    }

    const handleExpertSelection = (expert: any) => {
        if (agentsSelected.includes(expert)) {
            setAgentsSelected(agentsSelected.filter((agent) => agent !== expert))
        } else if (agentsSelected.length < 5) {
            setAgentsSelected([...agentsSelected, expert])
        }
    }

    const recommendedExperts = agentsList.slice(0, 4)

    return (
        <div className="conversationStarter">
            <div className="chat-mainArea-chat-welcome">
                <img src={APPI_icon} alt="appi-icon" />
            </div>
            <div className="chat-mainArea-chat-config">
                {/*<ConfigDrolist className='chat-mainArea-chat-config-instructions'*/}
                {/*               string='Custom instructions'*/}
                {/*               open={CustomProfilesOpen}*/}
                {/*               setOpen={setCustomProfilesOpen}*/}
                {/*               selected={selectedCustomInstruction}*/}
                {/*               options={CustomProfiles}*/}
                {/*               setSelected={setSelectedCustomInstruction}*/}
                {/*               defaultSelection={{*/}
                {/*                   id: '-1',*/}
                {/*                   name: 'No instruction',*/}
                {/*                   instruction: '',*/}
                {/*               }}*/}
                {/*               displayname={false}*/}
                {/*/>*/}
                {/*<ConfigDroplist className="chat-mainArea-chat-config-models"*/}
                {/*                string="Model: "*/}
                {/*                open={modal === ModalOpen.MODELS}*/}
                {/*                setOpen={(close: boolean) => handleSelect(ModalOpen.MODELS, close)}*/}
                {/*                selected={selectedModel}*/}
                {/*                options={models}*/}
                {/*                setSelected={setSelectedModel}*/}
                {/*                defaultSelection={models[0]}*/}
                {/*                category={'creator'}*/}
                {/*/>*/}
                <div className={'chat-mainArea-chat-config-experts'}>
                    {recommendedExperts.map((expert, index) => (
                        <AgentCard
                            agent={expert}
                            description={false}
                            key={index}
                            onClickRedirect={false}
                            onClick={() => handleExpertSelection(expert)}
                            id={agentsSelected.includes(expert) ? 'agent_selected' : ''}
                        />
                    ))}
                </div>
                {/*<ConfigDroplist className="chat-mainArea-chat-config-models"*/}
                {/*                string="Language: "*/}
                {/*                open={modal === ModalOpen.LANGUAGES}*/}
                {/*                setOpen={(close: boolean) => handleSelect(ModalOpen.LANGUAGES, close)}*/}
                {/*                selected={selectedLanguage}*/}
                {/*                options={languages}*/}
                {/*                setSelected={setSelectedLanguage}*/}
                {/*                defaultSelection={languages[0]}*/}
                {/*/>*/}
            </div>
        </div>
    )
}
import './FunTool.scss'
import React, { useEffect, useState } from 'react'
import { Title } from '../../../Components/Title/Title'
import { FaRobot } from 'react-icons/fa6'

interface FunToolProps {
        funToolConfig: any[]
}

export const FunTool = (props: FunToolProps) => {

        const [selected, setSelected] = useState<string>('AI Expert')
        const [config, setConfig] = useState<FunToolInterface>(props.funToolConfig[0])

        useEffect(() => {
                setConfig(props.funToolConfig.find(item => item.name === selected) || props.funToolConfig[0])
        }, [selected])

        return (
                <div className="landingSubpage funTool">
                        <Title title="More than just a "
                                imgPosition={'left'}
                                highlight="chatbot"
                                titleImg={() => <FaRobot />}
                        />
                        <div className="funTool-content">
                                <div className="funTool-content-panel">
                                        {config.component}
                                </div>
                        </div>
                </div>
        )

}

interface FunToolInterface {
        name: string,
        component?: any
}
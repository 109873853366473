import './EarlyPartners.scss'
import { StaticTitle } from '../Static/Components/StaticTitle/StaticTitle'
import Footer from '../Landing/Footer/Footer'
import { StaticCards } from '../Static/Components/StaticCards/StaticCards'
import { AiOutlineDatabase } from 'react-icons/ai'
import { AnimatedBlob } from '../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { Title } from '../../Components/Title/Title'
import { BsQuestion } from 'react-icons/bs'
import { Link } from 'react-scroll/modules'
import { StaticChoice } from '../Static/Components/StaticChoice/StaticChoice'
import Email from '../Landing/Email/Email'
import { FaCheck } from 'react-icons/fa'
import { scroller } from 'react-scroll'

const ticks = {
    'teams': [
        'Early access',
        'Streamlined project management tools',
        'Automated team workflows',
        'AI-powered task prioritization',
        'Enhanced communication via AI',
        'Real-time feedback generation',
        'Shared knowledge databases',
        'AI-assisted brainstorming sessions',
    ],
    'businesses': [
        'Early access',
        'Optimized decision-making processes',
        'Customer service automation',
        'Tailored marketing insights',
        'AI-driven financial forecasting',
        'Productivity-enhancing AI solutions',
        'Data-driven business strategies',
        'AI-enhanced customer engagement',
    ],
    'corporations': [
        'Early access',
        'Advanced analytics and reporting',
        'Scalable AI-driven automation',
        'Custom AI for global teams',
        'Corporate compliance automation',
        'AI for large-scale optimization',
        'Risk management with AI',
        'Cross-department AI collaboration',
    ],
}


export const EarlyPartners = () => {
    return (
        <div className="earlyPartners-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="earlyPartners">
                <StaticTitle
                    title={{
                        title: 'We are looking for early partners',
                        imgPosition: 'top',
                    }}
                    subtitle={'Learn how we can help in your case'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                        mainLink: () => {
                            scroller.scrollTo('email', {
                                duration: 800,
                                delay: 0,
                                offset: -70,
                                smooth: 'easeInOutQuart',
                            })
                        },
                    }}
                />

                <StaticCards
                    titleConfig={{
                        title: 'What do you',
                        highlight: 'get?',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    items={[
                        {
                            title: 'Early access',
                            text: 'We are now in prerelease phase. Be the first to try our product.',
                            icon: <AiOutlineDatabase />,
                        },
                        {
                            title: 'Custom features',
                            text: 'We are constantly improving and expanding our product. Your feedback is crucial. We wish to tailor our product to our partners needs.',
                            icon: <AiOutlineDatabase />,
                        },
                        {
                            title: 'Cooperation',
                            text: 'Our partners are our priority. We are here to fully introduce AI to your business. Apart from our product, we offer consulting and support.',
                            icon: <AiOutlineDatabase />,
                        },
                    ]}
                />

                <div className="earlyPartners-verified">
                    <Title
                        title={'We are'}
                        highlight={'market verified'}
                        titleImg={() => <BsQuestion />}
                        imgPosition={'top'}
                    />
                    <h2 className="ctaUsecase-subtitle">
                        We are closely cooperating with our close partners, answering their needs...
                    </h2>
                    <Link to={'email'} className="ctaUsecase-button"
                          spy={true} smooth={true} duration={500} offset={-50}
                    >
                        Let's cooperate
                    </Link>
                </div>

                <StaticChoice
                    titleConfig={{
                        title: 'Suitable for',
                        highlight: 'everyone',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    items={[
                        {
                            title: 'Teams',
                            text: 'Lorem ipsum',
                            icon: <AiOutlineDatabase />,
                            component: <TickComponent ticks={ticks['teams']} />,
                        },
                        {
                            title: 'Businesses',
                            text: 'Lorem ipsum',
                            icon: <AiOutlineDatabase />,
                            component: <TickComponent ticks={ticks['businesses']} />,
                        },
                        {
                            title: 'Corporations',
                            text: 'Lorem ipsum',
                            icon: <AiOutlineDatabase />,
                            component: <TickComponent ticks={ticks['corporations']} />,
                        },
                    ]}
                />

                <Email sendAlert={() => {
                }} />

                <Footer />
            </div>
        </div>
    )
}

interface TickComponentInterface {
    ticks: string[]
}

const TickComponent = (props: TickComponentInterface) => {
    return (
        <div className="ticks">
            {props.ticks.map((value, index: number) => (
                <div className="ticks-tick">
                    <FaCheck />
                    <p>{value}</p>
                </div>
            ))}
        </div>
    )
}
import React, { useState } from 'react'
import './Login.scss'
import LoginComponent from '../../Components/LoginComponent/LoginComponent'
import RegisterComponent from '../../Components/RegisterComponent/RegisterComponent'
import { GoogleOAuthProvider } from '@react-oauth/google'
import SEO from '../../Components/SEO'

const Login = ({ navigate, sendAlert }: { navigate: Function, sendAlert: Function }) => {
    const [type, setType] = useState(true)
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_CLIENT ? process.env.REACT_APP_GOOGLE_API_CLIENT : ""}>
            <SEO
                title='APPI | Login'
                description='Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love.'
                name='Login'
                type='website'
                keywords={['APPI', 'Login', 'Chat', 'Create an account', 'Read more']}
            />
            <div className='loginWrapper'>
                <div className='login'>
                    {type && <LoginComponent navigate={navigate} swap={() => setType(false)} sendAlert={sendAlert} />}
                    {!type &&
                        <RegisterComponent navigate={navigate} swap={() => setType(true)} sendAlert={sendAlert} />}
                </div>
                <img className='blobLogin blobLogin1' src={require('../../Images/Blobs/blob3.svg').default}
                     alt='mySvgImage' />
                <img className='blobLogin blobLogin2' src={require('../../Images/Blobs/blob3.svg').default}
                     alt='mySvgImage' />
                <img className='blobLogin blobLogin3' src={require('../../Images/Blobs/blob9.svg').default}
                     alt='mySvgImage' />
            </div>
        </GoogleOAuthProvider>
    )
}
export default Login

import './SolutionsIndividual.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import Footer from '../../../Landing/Footer/Footer'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { AiOutlineDatabase } from 'react-icons/ai'
import { MdAccountCircle } from 'react-icons/md'
import { FaDollarSign, FaGraduationCap, FaHandshake, FaWrench } from 'react-icons/fa'
import { StaticList } from '../../Components/StaticList/StaticList'
import useRedirect from '../../../../Utils/redirect'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { scroller } from 'react-scroll'
import {
    IndividualChatProcessing,
} from '../../../Landing/components/IndividualChat/IndividualChatProcessing/IndividualChatProcessing'
import {
    IndividualChatUnderstands,
} from '../../../Landing/components/IndividualChat/IndividualChatUnderstands/IndividualChatUnderstands'
import Email from '../../../Landing/Email/Email'

export const SolutionsIndividual = () => {
    const redirect = useRedirect()

    return (
        <div className="solutionIndividuals-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="solutionIndividuals">
                <div className="solutionIndividuals">
                    <StaticTitle
                        title={{
                            title: 'You know what,',
                            highlight: 'we know how',
                            imgPosition: 'top',
                        }}
                        subtitle={'A{P}PI is designed for professionals seeking to get the most out of their time'}
                        buttons={{
                            main: 'Explore AI Tools',
                            secondary: 'Learn More',
                            mainLink: () => {
                                redirect('app', 'login')
                            },
                        }}
                    />

                    <StaticList
                        title={{
                            title: 'Interact with your data',
                            newlineHighlight: 'like never before',
                            imgPosition: 'left',
                            titleImg: () => <AiOutlineDatabase />,
                        }}
                        points={[
                            {
                                title: 'Reliable',
                                text: 'We make sure all answers are correct and based on your data.',
                                children: <IndividualChatUnderstands />,
                            },
                            {
                                title: 'Powerful processing capabilities',
                                text: 'We extract the most out of your data to provide you with the smartest answers.',
                                children: <IndividualChatProcessing />,
                            },
                        ]}
                        className="individualDocuments"
                    />

                    <StaticProps
                        title={{
                            title: 'AI that understands',
                            highlight: 'you',
                            imgPosition: 'top',
                            titleImg: () => <AiOutlineDatabase />,
                        }}
                        items={[
                            {
                                title: 'Document knowledge',
                                text: 'Work closely with your documents and get accurate and verified answers even to complex questions',
                                icon: <HiOutlinePresentationChartLine />,
                            },
                            {
                                title: 'Custom profiles',
                                text: 'Personalize your experience with APPI. Get answers tailored for your character and use-case',
                                icon: <HiOutlinePresentationChartLine />,
                            },
                            {
                                title: 'Multiple AI Experts',
                                text: 'For complex tasks utilize many AI Experts at once ang get versatile answer from many points of views',
                                icon: <HiOutlinePresentationChartLine />,
                            },
                        ]}
                    />
                    <StaticList
                        title={{
                            title: 'Create your own',
                            newlineHighlight: 'custom experts',
                            imgPosition: 'left',
                            titleImg: () => <AiOutlineDatabase />,
                        }}
                        points={[
                            {
                                icon: <FaGraduationCap />,
                                title: 'Domain expertise',
                                text: 'In one sitting create and prompt your work copilot, in every other benefit from its domain-based answers. Shared unique work experience and knowledge makes you AI Expert a perfect work companion',
                            },
                            {
                                icon: <FaWrench />,
                                title: 'Customizable',
                                text: 'With every LLM on APPI come versatile configuration capabilities',
                            },
                            {
                                icon: <FaDollarSign />,
                                title: 'Earn commissions',
                                text: 'Make your expert public and earn commissions on every use',
                            },
                        ]}
                        className="solutionIndividuals-customAgents"
                    />
                    <StaticList
                        title={{
                            title: '',
                            highlight: 'Quick start',
                            imgPosition: 'left',
                            titleImg: () => <AiOutlineDatabase />,
                        }}
                        points={[
                            {
                                title: 'Create an Account',
                                text: 'Jump right in',
                                icon: <MdAccountCircle />,
                                button: () => redirect('app', 'login'),
                                buttonText: 'I\'m in',
                            },
                            {
                                title: 'Talk to our team',
                                text: 'In need of custom solution for your business? Our team is here to help',
                                icon: <FaHandshake />,
                                button: () => scroller.scrollTo('email', {
                                    duration: 800,
                                    delay: 0,
                                    smooth: 'easeInOutQuart',
                                    offset: -55,
                                }),
                                buttonText: 'Contact us',

                            },
                        ]}
                        className="quickStart"
                    />

                    <Email sendAlert={() => {
                    }} />

                    <Footer />
                </div>
            </div>
        </div>
    )
}
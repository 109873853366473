import './MeetAppiCloud.scss'
import { SiMicrosoftazure } from 'react-icons/si'
import { FaCloud } from 'react-icons/fa'

interface MeetAppiCloudInterface {
    hovered?: boolean;
}

export const MeetAppiCloud = ({ hovered }: MeetAppiCloudInterface) => {
    return (
        <div className="staticListPoint-children">
            <div className={hovered ? 'meetAppiCloud meetAppiCloud-hovered' : 'meetAppiCloud'}>
                <div className="meetAppiCloud-icon meetAppiCloud-icon-azure">
                    <SiMicrosoftazure />
                </div>
                <div className="meetAppiCloud-icon meetAppiCloud-icon-cloud">
                    <FaCloud />

                </div>
                <div className="meetAppiCloud-text">
                    {hovered && <p>Ready</p>}
                    {hovered && <p>Reliable</p>}

                    {!hovered && <p>Secure</p>}
                    {!hovered && <p>Tailored</p>}
                </div>

            </div>
        </div>
    )
}
import './Title.scss'
import React from 'react'
import { useMobileDetect } from '../../Utils/hooks'


export interface TitleInterface {
    title?: string
    subtitle?: string
    titleImg?: React.FunctionComponent<any>;
    highlight?: string
    newlineHighlight?: string
    imgPosition: 'left' | 'top'
}

export const Title = (props: TitleInterface) => {
    const mobile = useMobileDetect()
    let imgPosition = props.imgPosition
    if (mobile) {
        imgPosition = 'top'
    }

    const { title, subtitle, titleImg: TitleImg, highlight, newlineHighlight } = props
    return (
        <div className="titleComponent"
             style={imgPosition === 'left' ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
            {TitleImg && (
                <div className="titleComponent-img" style={
                    (subtitle || newlineHighlight || imgPosition === 'top') ? {
                        width: '4rem',
                        height: '4rem',
                    } : {
                        width: '3rem',
                        height: '3rem',
                    }
                }>
                    <TitleImg />
                </div>
            )}
            <div className="titleComponent-text"
                 style={imgPosition === 'left' ? { marginLeft: '2rem' } : {
                     alignItems: 'center',
                     marginTop: '2rem',
                 }}
            >
                <div className="titleComponent-text-title">
                    {title} <span className="titleComponent-text-title-highlight">{highlight}</span>
                </div>
                {newlineHighlight &&
                    <div className="titleComponent-text-title titleComponent-text-title-highlight">
                        {newlineHighlight}
                    </div>
                }
                <div className="titleComponent-text-subtitle">{subtitle}</div>
            </div>
        </div>
    )
}
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    landing: {
                        homeTitle: '<1>77%</1> of the companies are exploring AI',
                        homeSubtitle: 'See how you can join them',
                        buttons: {
                            title_1: 'Discover AI Experts',
                            description_1: 'Find the right AI for your business needs.',
                            title_2: 'Create an Account',
                            description_2: 'Join the community and start your AI journey.',
                            title_3: 'AI Transformation for Enterprises',
                            description_3: 'Learn how A{P}PI brings the future of AI to your enterprise.',
                        },
                    },
                },
            },
            pl: {
                translation: {
                    landing: {
                        homeTitle: 'Gotów na swoją <1>AI</1> podróż?',
                        homeSubtitle: 'Korzystaj z niestandardowych ekspertów AI i podnieś swoją firmę na wyższy poziom',
                    },
                },
            },
        },
    })

export default i18n
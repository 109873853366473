import './StaticProps.scss'
import React from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticPropsItemInterface {
    title: string
    text: string
    icon: React.ReactNode
}

interface StaticPropsInterface {
    items?: StaticPropsItemInterface[]
    altStyle?: boolean
    title: TitleInterface
}

export const StaticProps = (props: StaticPropsInterface) => {
    return (
        <div className="staticProps" id={props.altStyle ? 'alt' : ''}>
            <Title
                title={props.title.title}
                subtitle={props.title.subtitle}
                imgPosition={props.title.imgPosition}
                highlight={props.title.highlight}
                titleImg={props.title.titleImg}
                newlineHighlight={props.title.newlineHighlight}
            />
            <div className="staticProps-itemsWrapper">
                {props.items?.map((item, index) => (
                    <StaticPropsItem key={index} {...item} />
                ))}
            </div>
        </div>
    )
}


const StaticPropsItem = (props: StaticPropsItemInterface) => {
    return (
        <div className="staticProps-item">
            {props.icon}
            <h1>{props.title}</h1>
            <p>{props.text}</p>
        </div>

    )
}
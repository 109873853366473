import '../Features.scss'
import { InView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { FaRobot } from 'react-icons/fa6'
import { MockMessage } from './MockMessage'

const conversations = [
        {
                prompt: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                response: 'decent response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
                prompt: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                response: 'decent response Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
]
const agent = {
        name: 'Cybersecurity AI Expert',
        shortDescription: 'Strengthen security with AI',
        logoUrl: 'FaShieldAlt', // Icon for cybersecurity
        logoColorHex: '#F5A302', // Orange
}

const useIsMobile = () => {
        const [isMobile, setIsMobile] = useState<boolean>(false);

        useEffect(() => {
                const checkMobile = () => setIsMobile(window.innerWidth <= 800);
                checkMobile();
                window.addEventListener('resize', checkMobile);
                return () => window.removeEventListener('resize', checkMobile);
        }, []);

        return isMobile;
};

export const DocumentsF = () => {
        const [loading, setLoading] = useState<boolean>(true)
        const [index1, setIndex1] = useState<number>(0)
        const [response1, setResponse1] = useState<string>('')
        const [index2, setIndex2] = useState<number>(0)
        const [response2, setResponse2] = useState<string>('')
        const [filledDot, setFilledDot] = useState<number>(0)
        const isMobile = useIsMobile();

        useEffect(() => {       // loading dot animation
                if (loading) {
                        const interval = setInterval(() => {
                                setFilledDot((prev) => {
                                        return prev + 1
                                })
                        }, 750)

                        return () => clearInterval(interval)
                } else {
                        setFilledDot(0)
                }
        }, [loading])

        useEffect(() => {       // typing effect for the first response
                if (!loading) {
                        let interval: NodeJS.Timeout

                        interval = setInterval(() => {
                                setIndex1((prevIndex) => {
                                        if (prevIndex < conversations[0].response.length) {
                                                setResponse1((prev) => prev + conversations[0].response[prevIndex])
                                                return prevIndex + 1
                                        } else {
                                                clearInterval(interval)
                                                return prevIndex
                                        }
                                })
                        }, 10) // Speed of typing

                        return () => clearInterval(interval)
                } else {
                        setIndex1(0)
                        setResponse1('')
                }
        }, [loading, conversations])

        useEffect(() => {       // typing effect for the second response
                if (!loading) {
                        let interval: NodeJS.Timeout

                        interval = setInterval(() => {
                                setIndex2((prevIndex) => {
                                        if (prevIndex < conversations[1].response.length) {
                                                setResponse2((prev) => prev + conversations[1].response[prevIndex])
                                                return prevIndex + 1
                                        } else {
                                                clearInterval(interval)
                                                return prevIndex
                                        }
                                })
                        }, 10) // Speed of typing

                        return () => clearInterval(interval)
                } else {
                        setIndex2(0)
                        setResponse2('')
                }
        }, [loading, conversations])

        return (
                <div className="features-feature features-documents">
                        <InView as="div"
                                className="features-documents-rightSide"
                                threshold={isMobile ? 0 : 0.3}
                                onChange={(inView) => {
                                        if (inView) {
                                                setTimeout(() => {
                                                        const interval = setInterval(() => {
                                                                setFilledDot((prev) => {
                                                                        if (prev >= 3) {
                                                                                clearInterval(interval)
                                                                                setLoading(false)
                                                                                return prev
                                                                        }
                                                                        return prev + 1
                                                                })
                                                        }, 750)

                                                        return () => {
                                                                clearInterval(interval)
                                                                setLoading(false)
                                                        }
                                                }, 2000)
                                        } else {
                                                setLoading(true)
                                                setFilledDot(0)
                                        }
                                }}
                        >
                                {loading ?
                                        <div className="features-documents-rightSide-upload">
                                                <AiOutlineLoading className="features-documents-rightSide-upload-loading" />
                                                <div className="features-documents-rightSide-upload-content">
                                                        <IoDocumentTextOutline className="features-documents-rightSide-upload-content-icon" />
                                                        <div
                                                                className={filledDot === 0 ? 'features-documents-rightSide-upload-content-dot-filled features-documents-rightSide-upload-content-dot' : 'features-documents-rightSide-upload-content-dot'} />
                                                        <div
                                                                className={filledDot === 1 ? 'features-documents-rightSide-upload-content-dot-filled features-documents-rightSide-upload-content-dot' : 'features-documents-rightSide-upload-content-dot'} />
                                                        <div
                                                                className={filledDot === 2 ? 'features-documents-rightSide-upload-content-dot-filled features-documents-rightSide-upload-content-dot' : 'features-documents-rightSide-upload-content-dot'} />
                                                        <div
                                                                className={filledDot === 3 ? 'features-documents-rightSide-upload-content-dot-filled features-documents-rightSide-upload-content-dot' : 'features-documents-rightSide-upload-content-dot'} />
                                                        <FaRobot className="features-documents-rightside-upload-content-icon" />
                                                </div>
                                        </div>
                                        :
                                        <div className="features-documents-rightSide-responses">
                                                {conversations.map((conversation: any, index: number) => {
                                                        return (
                                                                <div key={index} className="features-documents-rightSide-responses-response">
                                                                        <MockMessage
                                                                                authorName="You"
                                                                                isUser={true}
                                                                                message={conversation.prompt}
                                                                                className="no-hover"
                                                                        />
                                                                        <MockMessage
                                                                                authorName={agent.name}
                                                                                isUser={false}
                                                                                message={index === 0 ? response1 : response2}
                                                                                iconColor={agent.logoColorHex}
                                                                                className="no-hover"
                                                                        />
                                                                </div>
                                                        )
                                                })}
                                        </div>
                                }
                        </InView>
                </div>
        )
}

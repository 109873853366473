import './CustomAgents.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import Footer from '../../../Landing/Footer/Footer'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { ExpertCreation } from '../../../Landing/FunTool/Components/ExpertCreation/ExpertCreation'
import { StaticList } from '../../Components/StaticList/StaticList'
import Email from '../../../Landing/Email/Email'
import { Title } from '../../../../Components/Title/Title'
import {
    academic_financial_expert,
    custom_expert,
    customer_research_expert,
} from '../../../../Components/staticExperts'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import { AiOutlineDatabase, AiOutlineUser } from 'react-icons/ai'
import { MdAccountCircle, MdAttachMoney, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { FaCheck, FaHandshake, FaLock, FaRobot } from 'react-icons/fa'
import { scroller } from 'react-scroll'
import useRedirect from '../../../../Utils/redirect'

export const CustomAgents = () => {

    const redirect = useRedirect()

    const funToolConfig = [
        {
            'name': 'Agent creation',
            'component': <ExpertCreation />,
        },
    ]

    const experts_config = [
        customer_research_expert,
        academic_financial_expert,
        custom_expert,
    ]

    const llms = [
        require('../../../../Images/Assets/Icons/mistral.png'),
        require('../../../../Images/Assets/Icons/claude.png'),
        require('../../../../Images/Assets/Icons/gpt.png'),
    ]


    return (
        <div className="customAgents-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="customAgents">
                <StaticTitle
                    title={{
                        title: 'Custom agents',
                        imgPosition: 'top',
                        titleImg: () => <HiOutlinePresentationChartLine />,
                    }}
                    subtitle={'Earn money through your specialized agents'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                        mainLink: () => {
                            scroller.scrollTo('email', {
                                duration: 800,
                                delay: 0,
                                smooth: 'easeInOutQuart',
                                offset: -55,
                            })
                        },
                    }}
                />

                <StaticList
                    title={{
                        title: 'Fully',
                        highlight: 'customizable',
                        imgPosition: 'left',
                        titleImg: () => <HiOutlinePresentationChartLine />,
                    }}
                    points={[
                        {
                            title: 'Use all pre-trained LLMs',
                            text: 'Access the best features of leading language models in a single, powerful platform—everything you need in one place',
                            children: <div className="customizable-llms">
                                {llms.map((llm, index) => {
                                    return (
                                        <div className="commissions-llm">
                                            <img src={llm} alt="llm" />
                                        </div>
                                    )
                                })}
                            </div>,
                        },
                        {
                            title: 'Tweak the models',
                            text: 'Our models are fully customizable. You can adjust them to your needs',
                        },
                        {
                            title: 'Share you experts',
                            text: 'Decide if you want to share your experts with the community, team or keep them private',
                        },
                    ]}
                    className="customizable"
                />

                <StaticList
                    title={{
                        title: 'Earn',
                        highlight: 'commissions',
                        imgPosition: 'left',
                        titleImg: () => <HiOutlinePresentationChartLine />,
                    }}
                    points={[
                        {
                            children: <div className="commissions-component">
                                <div className="commissions-component-graph">
                                    <AiOutlineUser className="commissions-component-graph-item" />
                                    <MdOutlineKeyboardArrowRight className="commissions-component-graph-arrow" />
                                    <FaRobot className="commissions-component-graph-item" />
                                    <MdOutlineKeyboardArrowRight className="commissions-component-graph-arrow" />
                                    <MdAttachMoney className="commissions-component-graph-item" />
                                </div>
                                <h1>Whenever somebody uses your expert you get a commission</h1>
                            </div>,
                        },
                    ]}
                    className="commissions"
                />

                <StaticList
                    title={{
                        title: 'Document',
                        highlight: 'based power',
                        imgPosition: 'left',
                        titleImg: () => <HiOutlinePresentationChartLine />,
                    }}
                    points={[
                        {
                            title: 'Reliable answers',
                            text: 'Access the best features of leading language models in a single, powerful platform—everything you need in one place',
                            // children: <MeetAppiPlatform />,
                            children: <div className="customAgents-document-reliable">
                                <FaCheck />
                            </div>,
                        },
                        {
                            title: 'Secure',
                            text: 'Your documents are safe with us. You decide who and how can access them',
                            // children: <MeetAppiPlatform />,
                            children: <div className="customAgents-document-secure">
                                <FaLock />
                            </div>,
                        },
                    ]}
                    className="customAgents-document"
                />

                <div className="customAgents-experts">
                    <Title
                        title={'Create your new'}
                        newlineHighlight={'experts stack'}
                        imgPosition="left"
                        titleImg={() => <HiOutlinePresentationChartLine />}
                    />
                    <div className="customAgents-experts-list">
                        {experts_config.map((agent, index) => {
                            return (
                                <AgentCard
                                    agent={agent}
                                    onClickRedirect={false}
                                />
                            )
                        })}
                    </div>
                </div>

                <StaticList
                    title={{
                        title: '',
                        highlight: 'Quick start',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    points={[
                        {
                            title: 'Create an Account',
                            text: 'Jump right in',
                            icon: <MdAccountCircle />,
                            button: () => redirect('app', 'login'),
                            buttonText: 'I\'m in',
                        },
                        {
                            title: 'Talk to our team',
                            text: 'In need of custom solution for your business? Our team is here to help',
                            icon: <FaHandshake />,
                            button: () => scroller.scrollTo('email', {
                                duration: 800,
                                delay: 0,
                                smooth: 'easeInOutQuart',
                                offset: -55,
                            }),
                            buttonText: 'Contact us',

                        },
                    ]}
                    className="quickStart"
                />

                <Email sendAlert={() => {
                }} />

                <Footer />
            </div>
        </div>
    )
}
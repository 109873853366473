import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import { BlockMath, InlineMath } from 'react-katex'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import { FaRegCopy } from 'react-icons/fa'

interface CodeInterface {
    node: any,
    inline: any,
    className: any,
    children: any,
}


interface ParserInterface {
    copied: boolean
    handleCopy: Function
}


export const Parser = (props: any, { copied, handleCopy }: ParserInterface) => {
    return (
        <ReactMarkdown
            className='message-text'
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            {...props}
            components={{
                math: ({ children }: { children: string }) =>
                    <span className='math'><BlockMath>{children}</BlockMath></span>,
                code({ inline, className, children }: CodeInterface) {
                    const match = /language-(\w+)/.exec(className || '')
                    if (match) {
                        return (
                            <div className='message-text-code'>
                                <div className='message-text-code-header'>
                                    <h1>{match[1]}</h1>
                                    {!props.copied &&
                                        <FaRegCopy className='message-text-code-header-copy'
                                                   onClick={() => props.handleCopy(String(children))} />}
                                    {props.copied &&
                                        <h2 className='message-text-code-header-copy'>Copied</h2>}
                                </div>
                                <SyntaxHighlighter
                                    className='syntax-highlighter'
                                    children={String(children).replace(/\n$/, '')}
                                    style={materialDark}
                                    language={match[1]}
                                    wrapLongLines={true}
                                    PreTag='div'
                                />
                            </div>
                        )
                    } else {
                        return <code className={className}>{children}</code>
                    }
                    // return (!inline && match) ? (
                    //     <div className='message-text-code'>
                    //         <div className='message-text-code-header'>
                    //             <h1>{match[1]}</h1>
                    //             {!props.copied &&
                    //                 <FaRegCopy className='message-text-code-header-copy'
                    //                            onClick={() => props.handleCopy(String(children))} />}
                    //             {props.copied &&
                    //                 <h2 className='message-text-code-header-copy'>Copied</h2>}
                    //         </div>
                    //         <SyntaxHighlighter
                    //             className='syntax-highlighter'
                    //             children={String(children).replace(/\n$/, '')}
                    //             style={materialDark}
                    //             language={match[1]}
                    //             wrapLongLines={true}
                    //             PreTag='div'
                    //         />
                    //     </div>
                    // ) : null
                },
                p: ({ children }: { children: string }) => <p>{detectMath(children)}</p>,
                li: ({ children }: { children: string }) => <li>{children}</li>,
                inlineMath: ({ children }: { children: string }) =>
                    <span className='inline-math'><InlineMath>{children}</InlineMath></span>,
            }}
        />
    )
}

const detectMath = (text: string | React.ReactNode[]): React.ReactNode[] => {
    // Function to process string and detect math expressions
    const processText = (inputText: string): React.ReactNode[] => {
        const mathRegex = /\[\s([^\]]*?)\]|\(\s([^\)]*?)\)/g;
        let parts: React.ReactNode[] = [];
        let match: RegExpExecArray | null;
        let lastIndex = 0;

        while ((match = mathRegex.exec(inputText)) !== null) {
            const [fullMatch, squareContent, parenContent] = match;
            const mathContent = squareContent || parenContent;

            // Add text before the match
            if (match.index > lastIndex) {
                parts.push(inputText.substring(lastIndex, match.index));
            }

            // Add math content
            if (mathContent) {
                parts.push(<InlineMath key={parts.length} math={mathContent} />);
            }

            lastIndex = match.index + fullMatch.length;
        }

        // Add any remaining text after the last match
        if (lastIndex < inputText.length) {
            parts.push(inputText.substring(lastIndex));
        }

        return parts;
    };

    // Check if text is a string and process it
    if (typeof text === 'string') {
        return processText(text);
    } else if (Array.isArray(text)) {
        // If text is an array, process each string element within it
        return text.reduce<React.ReactNode[]>((acc, item) => {
            if (typeof item === 'string') {
                // Process the string to detect math expressions
                acc.push(...processText(item));
            } else {
                // For non-string items, just add them to the accumulator
                acc.push(item);
            }
            return acc;
        }, []);
    } else {
        // If text is neither a string nor an array, return it as is
        return [text];
    }
};

import { apiDelete, apiGet, apiPost, apiPut } from '../Utils/RequestsUtil'
import { ErrorType } from '../Utils/enums'
import Cookies from 'js-cookie'

export const getExperts = async (developer: boolean) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Expert/PublicAndMyPrivate',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getPublicExperts = async (developer: boolean) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Expert',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getSummarizers = async () => {
    return await apiGet(
        'Summarizer',
        null,
        false,
    )
}

export const getExpert = async (id: string) => {
    try {
        return await apiGet(
            'Expert/' + id,
            null,
            false,
        )
    } catch (e) {
        throw e
    }
}

export const getSummarizer = async (id: string) => {
    try {
        return await apiGet(
            'Summarizer/' + id,
            null,
            false,
        )
    } catch (e) {
        return ErrorType.NOT_FOUND
    }
}

export const createExpert = async (name: string, shortDescription: string, description: string, usageInstruction: string, sysPrompt: string, userPrompt: string, tags: string[]) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPost(
            'Expert',
            {
                name: name,
                description: description,
                shortDescription: shortDescription,
                usageInstruction: usageInstruction,
                systemPrompt: sysPrompt,
                expertPrompt: userPrompt,
                tags: tags,
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}
export const createSummarizer = async (name: string, shortDescription: string, description: string, sysPrompt: string, userPrompt: string, tags: string[]) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPost(
            'Summarizer',
            {
                name: name,
                description: description,
                shortDescription: shortDescription,
                systemPrompt: sysPrompt,
                userPrompt: userPrompt,
                tags: tags,
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        return e
    }
}

export const deleteExpert = async (id: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiDelete(
            'Expert/' + id,
            null,
            true,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        return ErrorType.NOT_FOUND
    }
}

interface UpdateInterface {
    id?: string | null;
    name?: string | null;
    shortDescription?: string | null;
    description?: string | null;
    sysPrompt?: string | null;
    userPrompt?: string | null;
    tags?: string[] | null;
    color?: string | null;
    logoUrl?: string | null;
}

export const updateExpert = async ({
                                       id = null,
                                       name = null,
                                       shortDescription = null,
                                       description = null,
                                       sysPrompt = null,
                                       userPrompt = null,
                                       tags = null,
                                       color = null,
                                       logoUrl = null,
                                   }: UpdateInterface) => {
    const jwt = Cookies.get('jwt')
    const data = {}
    if (name !== null) {
        // @ts-ignore
        data.name = name
    }
    if (shortDescription !== null) {
        // @ts-ignore
        data.shortDescription = shortDescription
    }
    if (description !== null) {
        // @ts-ignore
        data.description = description
    }
    if (sysPrompt !== null) {
        // @ts-ignore
        data.systemPrompt = sysPrompt
    }
    if (userPrompt !== null) {
        // @ts-ignore
        data.userPrompt = userPrompt
    }
    if (tags !== null) {
        // @ts-ignore
        data.tags = tags
    }
    if (color !== null) {
        // @ts-ignore
        data.logoColorHex = color
    }
    if (logoUrl !== null) {
        // @ts-ignore
        data.logoUrl = logoUrl
    }
    try {
        return await apiPut(
            'Expert/' + id,
            data,
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const getExpertStats = async (expertId: string, start: string, stop: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiGet(
            'Expert/Stats/' + expertId,
            // @ts-ignore
            {
                start: start,
                stop: stop,
            },
            true,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const updateSummarizer = async ({
                                           id = null,
                                           name = null,
                                           shortDescription = null,
                                           description = null,
                                           sysPrompt = null,
                                           userPrompt = null,
                                           tags = null,
                                           color = null,
                                       }: UpdateInterface) => {
    const jwt = Cookies.get('jwt')
    const data = {}
    if (name !== null) {
        // @ts-ignore
        data.name = name
    }
    if (shortDescription !== null) {
        // @ts-ignore
        data.shortDescription = shortDescription
    }
    if (description !== null) {
        // @ts-ignore
        data.description = description
    }
    if (sysPrompt !== null) {
        // @ts-ignore
        data.systemPrompt = sysPrompt
    }
    if (userPrompt !== null) {
        // @ts-ignore
        data.userPrompt = userPrompt
    }
    if (tags !== null) {
        // @ts-ignore
        data.tags = tags
    }
    if (color !== null) {
        // @ts-ignore
        data.logoColorHex = color
    }
    try {
        return await apiPut(
            'Summarizer/' + id,
            data,
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        return ErrorType.NOT_FOUND
    }
}

export const modifyFavExperts = async (expertIds: string[]) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPut(
            'User',
            {
                'favouriteExpertsIds': expertIds,
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const modifyFavSummarizers = async (summarizerIds: string[]) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPut(
            'User',
            {
                'favouriteSummarizersIds': summarizerIds,
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const modifyDefaultExperts = async (expertIds: string[]) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPut(
            'User',
            {
                'defaultExpertsIds': expertIds,
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const modifyDefaultSummarizer = async (summarizerId: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPut(
            'User',
            {
                'defaultSummarizersIds': [summarizerId],
            },
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}


export const getExpertDetails = async (id: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiGet(
            'Expert/Details/' + id,
            null,
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const getSummarizerDetails = async (id: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiGet(
            'Summarizer/Details/' + id,
            null,
            false,
            // @ts-ignore
            jwt,
        )

    } catch (e) {
        throw e
    }
}

export const changePermissonForDocumentAccess = async (expertId: string, permission: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiPut(
            'Expert/ChangePermissionForDocumentAccess/' + expertId,
            permission,
            false,
            // @ts-ignore
            jwt,
        )
    } catch (e) {
        throw e
    }
}
import React, { useState } from 'react'
import './Pricing.scss'
import SEO from '../../Components/SEO'
import { Title } from '../../Components/Title/Title'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { StaticPrompt } from '../Static/Components/StaticPrompt/StaticPrompt'
import Footer from '../Landing/Footer/Footer'
import FAQ from '../Landing/FAQ/FAQ'

const marks = {
    100: '100',
    250: '250',
    500: '500',
    1000: '1000',
    2000: '2000',
    5000: '5000',
    10000: '10000',
    20000: '20000',
}


const Pricing = () => {
    const [reqNumber, setReqNumber] = useState<number | number[]>(100)

    const handleChange = (val: number | number[]) => {
        setReqNumber(val)
    }


    const pricing_config = [
        {
            title: 'Starter',
            tag: 'For small projects',
            price: '$10',
            pricePer: 'month',
            description: 'Get started with the basics',
            features: [
                `${reqNumber} answers per month`,
                'Unlimited integrations',
                'Basic support',
            ],
            button: 'Get started',
            featuresText: 'Features',
        },
        {
            title: 'Pro',
            tag: 'For growing businesses',
            price: '$50',
            pricePer: 'month per user',
            description: 'Get more answers and more features',
            features: [
                `${reqNumber} answers per user per month`,
                'Unlimited integrations',
                'Priority support',
            ],
            button: 'Get started',
            featuresText: 'Features',
        },
        {
            title: 'Enterprise',
            tag: 'For large businesses',
            description: 'Get the full experience',
            features: [
                `${reqNumber} answers per month`,
                'Unlimited integrations',
                'Priority support',
            ],
            button: 'Contact sales',
            featuresText: 'Features',
        },
    ]


    return (
        <div className="pricing-wrapper">
            <SEO
                title="APPI | Pricing"
                description="Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love."
                name="Pricing"
                type="website"
                keywords={['APPI', 'Pricing', 'Chat', 'Create an account', 'Read more']}
            />
            <img className="blobPricing blobPricing1" src={require('../../Images/Blobs/blob3.svg').default}
                 alt="mySvgImage" />
            <img className="blobPricing blobPricing2" src={require('../../Images/Blobs/blob6.svg').default}
                 alt="mySvgImage" />
            <img className="blobPricing blobPricing3" src={require('../../Images/Blobs/blob9.svg').default}
                 alt="mySvgImage" />
            <img className="blobPricing blobPricing4" src={require('../../Images/Blobs/blob9.svg').default}
                 alt="mySvgImage" />
            <img className="blobPricing blobPricing5" src={require('../../Images/Blobs/blob9.svg').default}
                 alt="mySvgImage" />
            <div className="pricing">
                <Title
                    title={'Reliable LLMs'}
                    imgPosition={'left'}
                    newlineHighlight={'for your business'}
                    titleImg={() => <img src={require('../../Images/Logo/APPI_icon_white.png')} alt="newsletter" />}
                />

                <div className="pricing-slider">
                    <div className="pricing-slider-texts">
                        <p>How many answers per month do you need?</p>
                        <p>
                            <span className="pricing-slider-texts-number">{reqNumber as number}</span> answers selected
                        </p>
                    </div>
                    <Slider
                        min={100}
                        max={2000}
                        marks={marks}
                        step={null}
                        value={reqNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="pricing-cards">
                    {pricing_config.map((config, index) => (
                        <PricingCard
                            key={index}
                            title={config.title}
                            tag={config.tag}
                            price={config.price ? config.price : ''}
                            pricePer={config.pricePer ? config.pricePer : ''}
                            description={config.description}
                            features={config.features}
                            button={config.button}
                            featuresText={config.featuresText}
                        />
                    ))}
                </div>

                <div className="pricing-features">
                    <h2>What you get with all the plans?</h2>
                    <h3>jakis bs ze acceleration of your work itd w punktach</h3>
                </div>

                <StaticPrompt
                    title={'Join our growing community'}
                    subtitle={'Optimize your work today'}
                />
                <FAQ />
                <Footer />
            </div>
        </div>
    )
}

interface PricingCardInterface {
    title: string
    tag: string
    price: string
    pricePer: string
    description: string
    features: string[]
    button: string
    featuresText: string
}

const PricingCard = ({
                         title,
                         tag,
                         price,
                         pricePer,
                         description,
                         features,
                         button,
                         featuresText,
                     }: PricingCardInterface) => {
    return (
        <div className="pricing-card">
            <h1>{tag}</h1>
            <div className="pricing-card-header">
                <h2>{title}</h2>
                <p>{description}</p>
                <h3>{price} <span>{pricePer}</span></h3>
            </div>
            <button>{button}</button>
            <div className="pricing-card-features">
                <h4>{featuresText}</h4>
                {features.map((feature, index) => (
                    <p key={index}>{feature}</p>
                ))}
            </div>
        </div>
    )
}

export default Pricing
import './Enterprise.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import Footer from '../../../Landing/Footer/Footer'
import { StaticList } from '../../Components/StaticList/StaticList'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { TitleInterface } from '../../../../Components/Title/Title'
import Email from '../../../Landing/Email/Email'
import { AiOutlineDatabase, AiOutlineUser } from 'react-icons/ai'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { FaBuilding, FaCloud, FaRobot } from 'react-icons/fa6'
import { SiMicrosoftazure } from 'react-icons/si'
import { FaCheckSquare } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import { scroller } from 'react-scroll'


const documentsComponentConfig = [	// text under the documents
    'Customer info',
    'Project draft',
    'Legal regulations',
    'Company policies',
]

const useScreenWidth = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const DocumentsComponent = () => {
    const [isHovered, setIsHovered] = useState<boolean>(false)
    return (
        <div className="documentsComponent"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {documentsComponentConfig.map((text, index) => {
                return (
                    <div className="documentsComponent-document" key={index}>
                        <IoDocumentTextOutline className="documentsComponent-document-icon" />
                        <p className="documentsComponent-document-text">{text}</p>
                    </div>
                )
            })}
            {documentsComponentConfig.map((_, index) => {
                if (isHovered) {
                    return (
                        <div className="documentsComponent-arrow" key={index}>
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    className="arrow-main-line"
                                    d="M10 50 L90 50"
                                    stroke="white"
                                    strokeWidth="2" // Thinner line
                                    fill="none"
                                />
                                <path
                                    className="arrow-pointer-line"
                                    d="M90 50 L75 40 M90 50 L75 60"
                                    stroke="white"
                                    strokeWidth="2" // Thinner line
                                    fill="none"
                                />
                            </svg>
                        </div>
                    )
                }
            })}

            <img src={require('../../../../Images/Logo/APPI_icon_white.png')} alt={'appi'}
                className="documentsComponent-appi" />

        </div>
    )
}

const ShareExpertsImage = () => {
    const isMobile = useScreenWidth()

    return (
        <div className="enterprise-images-shareExperts">
            <div className="enterprise-images-shareExperts-line">
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
            </div>
            <div className="enterprise-images-shareExperts-line">
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
            </div>
            <div className="enterprise-images-shareExperts-line">
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
                <div className="enterprise-images-shareExperts-line-dot" />
            </div>
            <FaRobot className="enterprise-images-shareExperts-expert" />
            <div className="enterprise-images-shareExperts-users">
                <AiOutlineUser />
                <AiOutlineUser />
                <AiOutlineUser />
            </div>
        </div>
    )
}

const KnowledgebaseImage = () => {
    return (
        <div className="enterprise-images-knowledgebase">
            <div className="enterprise-images-knowledgebase-row">
                <AiOutlineDatabase />
                <div className="enterprise-images-knowledgebase-dot" />
                <FaBuilding />
                <div className="enterprise-images-knowledgebase-dot" />
                <AiOutlineDatabase />
            </div>
            <div className="enterprise-images-knowledgebase-dot" />
            <AiOutlineDatabase />
        </div>
    )
}

export const SolutionsEnterprise = () => {
    const isMobile = useScreenWidth();

    const statsToDisplay = [
        {
            percentage: 0,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
        {
            percentage: 50,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
        {
            percentage: 100,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
    ]

    const titleConfig: TitleInterface = {
        title: 'AI is the future',
        subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
        imgPosition: 'left',
    }

    return (
        <div className="solutionsBusinesses-wrapper">
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className="solutionsBusinesses">
                <StaticTitle
                    title={{
                        title: 'Solutions for businesses',
                        imgPosition: 'top',
                    }}
                    subtitle={'APPI makes it easy to apply Genrative AI into your business'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                        mainLink: () => {
                            scroller.scrollTo('email', {
                                duration: 800,
                                delay: 0,
                                smooth: 'easeInOutQuart',
                                offset: -55,
                            })
                        },
                    }}
                />
                <StaticList
                    title={{
                        title: 'Documentation',
                        highlight: 'understanding',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineUser />,
                    }}
                    points={[
                        {
                            title: 'Build your domain knowledgebase',
                            text: 'Here you upload your documents into projects or your AI Expert knowledgebase. We process them for you and your employees, so that you can get the right, verified answer even for complex questions',
                            children: !isMobile && <DocumentsComponent />,
                        },
                    ]}
                />

                <StaticList
                    title={{
                        title: 'Business-grade',
                        highlight: 'security',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineUser />,
                    }}
                    points={[
                        {
                            title: 'Self-hosted',
                            text: 'Our most secure option. As our partner you can host your own instance of A{P}PI. You can be sure that your data is safe, secure and never leaves your company',
                            icon: <FaCloud />,
                        },
                        {
                            title: 'Secure cloud',
                            text: 'We use secure and guarded Azure cloud to host our services, to provide you with the best security and reliability',
                            icon: <SiMicrosoftazure />,
                        },
                    ]}
                    className="enterprise-security"
                />

                <StaticList
                    title={{
                        title: 'Meet your',
                        highlight: 'organization panel',
                        imgPosition: 'left',
                        titleImg: () => <AiOutlineUser />,
                    }}
                    points={[
                        {
                            title: 'Share AI Experts with your team',
                            text: 'Create custom AI Experts and let everyone in your organization use them and collaborate on selected projects',
                            children: <ShareExpertsImage />,

                        },
                        {
                            title: 'Build Company Knowledgebase',
                            text: 'Split documents into projects, AI Experts and Custom Profiles and enjoy the benefits flexibility of switching between contexts',
                            children: <KnowledgebaseImage />,
                        },
                    ]}
                    className="solutionsBusinesses-organizations"
                />

                <StaticProps
                    title={{
                        title: 'Ai that understands',
                        highlight: 'you',
                        imgPosition: 'top',
                        titleImg: () => <AiOutlineDatabase />,
                    }}
                    items={[
                        {
                            title: 'Multi-Expert responses',
                            text: 'for complex and cross-domain questions try asking multiple AI Experts at once. Choose those who specialize in different areas and get the best answer',
                            icon: <FaRobot />,
                        },
                        {
                            title: 'Fact Checks',
                            text: 'our truthfulness checkers will verify the information for you. You can be sure that the answer you get is correct and based on given documents',
                            icon: <FaCheckSquare />,
                        },
                        {
                            title: 'Custom Profiles',
                            text: 'Personalize the experience of using APPI and relevance of the answers by creating custom profiles for each member of your team',
                            icon: <AiOutlineUser />,
                        },
                    ]}
                />
                <Email sendAlert={() => {
                }} />

                <Footer />
            </div>
        </div>
    )
}

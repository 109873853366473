import './IndividualChatUnderstands.scss'
import { FaCheckCircle, FaLock, FaUserCog } from 'react-icons/fa'

export const IndividualChatUnderstands = () => {

    const icons = {
        reliable: <FaCheckCircle />,
        secure: <FaLock />,
        personalized: <FaUserCog />,
    }

    return (
        <div className="individualChatUnderstands">
            {Object.keys(icons).map((key, index) => (
                <div key={index} className="individualChatUnderstands-icon">
                    {icons[key as keyof typeof icons]}
                    <p>{key}</p>
                </div>
            ))}
        </div>
    )
}


import './StaticTitle.scss'
import FreeBlobEnv from '../../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import React from 'react'
import { Title, TitleInterface } from '../../../../Components/Title/Title'

interface StaticTitleInterface {
    title: TitleInterface
    subtitle?: string
    buttons: {
        main: string
        secondary: string
        mainLink?: Function
        secondaryLink?: string
    }
}

export const StaticTitle = (props: StaticTitleInterface) => {

    const handleClickSecondary = () => {
        window.scrollBy({
            left: 0,
            top: window.innerHeight * 0.7,
            behavior: 'smooth',
        })
    }
    return (
        <div className="staticTitle-wrapper">
            <FreeBlobEnv width={'100%'} height={'100%'} min={30} max={30} />
            <div className="staticTitle">
                <Title
                    title={props.title.title}
                    subtitle={props.subtitle}
                    titleImg={props.title.titleImg}
                    highlight={props.title.highlight}
                    newlineHighlight={props.title.newlineHighlight}
                    imgPosition={props.title.imgPosition}
                />

                <div className="staticTitle-buttons">
                    <button
                        className="staticTitle-buttons-button staticTitle-buttons-button-main"
                        onClick={() => props.buttons.mainLink && props.buttons.mainLink()}
                    >{props.buttons.main}</button>
                    <button className="staticTitle-buttons-button"
                            onClick={() => handleClickSecondary()}
                    >{props.buttons.secondary}</button>
                </div>
            </div>
        </div>
    )
}
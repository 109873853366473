import './Chat.scss'
import './ChatMobile.scss'
import React, { useEffect, useState } from 'react'
import { getMyUserAccount } from '../../Requests/userRequests'
import { Conversation } from './Conversation/Conversation'
import { UserInput } from './UserInput/UserInput'
import { Sidebar } from './Sidebar/Sidebar'
import {
    AddNextPrompt,
    deleteAllConversations,
    deleteConversation,
    selectAsFinalResponse,
    SelectExpertsAnswers,
    startConversation,
} from '../../Requests/conversationRequests'
import { ConfirmModal, Modal } from '../../Components/Modal/Modal'
import { ConversationState, MessageFlow, SidebarPages } from '../../Utils/enums'
import TextareaAutosize from 'react-textarea-autosize'
import { createCustomInstruction, editCustomInstruction } from '../../Requests/customProfilesRequests'
import { AgentInterface } from '../../Interfaces/agentsInterfaces'
import SEO from '../../Components/SEO'
import { useStreamHandler } from './StreamHandler'
import { ConversationInterface, MessageInterface } from '../../Interfaces/messageInterfaces'
import { InspectorMode, useChatContext } from './ChatPropsManager'
import { Inspector } from './Inspector/Inspector'
import { handleError } from '../../Utils/RequestsUtil'
import { chatPresets } from '../../Dev/chatPresets'
import useScrollBottom from './Functions/useReachedBottom'
import useFetchData from './Functions/useFetchResources'
import fetchFullConversation from './Functions/fetchFullConversation'
import useSwitchConversation from './Functions/useSwitchConversation'
import { CustomProfile } from '../../Interfaces/chatInterfaces'
import { useLocation, useNavigate } from 'react-router-dom'
import useStreamResponse from './Functions/useStreamResponse'

const languages = require('./languages.json')
const APPI = require('../../Images/Logo/APPI_icon.png')

const randomColor = () => {
    const hue = Math.floor(Math.random() * 360)
    const saturation = 30 + Math.random() * 40
    const lightness = 70 + Math.random() * 10
    return `hsl(${hue},${saturation}%,${lightness}%)`
}

interface ChatInterface {
    sendAlert: Function
}

const Chat = ({ sendAlert }: ChatInterface) => {
    const defaultInstruction: CustomProfile = {
        id: '-1',
        name: 'No profile',
        instruction: '',
    }
    const navigate = useNavigate()
    const location = useLocation()

    const {
        agentsList,
        setAgentsList,
        mobile,
        summarizersList,
        setSummarizersList,
        agentsSelected,
        setAgentsSelected,
        summarizer,
        setSummarizer,
        sidebar,
        history,
        setHistory,
        selectedConversation,
        setSelectedConversation,
        messages,
        setMessages,
        conversationState,
        setConversationState,
        historyPing,
        setHistoryPing,
        historyModal,
        setHistoryModal,
        setSelectedAnswers,
        models,
        setModels,
        selectedModel,
        setSelectedModel,
        customProfiles,
        setCustomProfiles,
        selectedCustomInstruction,
        newInstruction,
        setNewInstruction,
        editInstruction,
        setEditInstruction,
        setLoadingHistory,
        loadingConversation,
        setLoadingConversation,
        setSidebarModal,
        customInstructionNameRef,
        customInstructionRef,
        userMessageRef,
        setFlow,
        chosenMessages,
        setChosenMessages,
        selectedLanguage,
        setMessagesLoaded,
        inspectorOpen,
        setInspectorOpen,
        globalState,
        inspectorMode,
        setInspectorMode,
        flow,
        conversationContainerRef,
        setGenerationStage,
    } = useChatContext()

    const scrollDown = (smooth = true) => {
        const container = conversationContainerRef.current
        if (container) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto',
            })
        }
    }

    const [progressiveText, setProgressiveText] = useState<{ [id: string]: string }>({})
    const streamHandler = useStreamHandler()
    const reachedBottom = useScrollBottom(conversationContainerRef)
    const { chunks, error, execute: executeStream } = useStreamResponse()

    useSwitchConversation(
        scrollDown,
        globalState,
        defaultInstruction,
    )

    useFetchData(
        customProfiles,
        setCustomProfiles,
        agentsList,
        setAgentsList,
        summarizersList,
        setSummarizersList,
        models,
        setModels,
        setSelectedModel,
        globalState,
        randomColor,
    )

    useEffect(() => {
        const id = location.search.slice(1).split('&')[0]
        setSidebarModal(false)
        setMessagesLoaded(false)
        document.body.style.overflow = 'auto'
        if (id !== 'new') {
            const conversation = history.find((conversation: ConversationInterface) => conversation.id === id)
            if (conversation) setSelectedConversation(conversation)
        } else {
            setSelectedConversation(null)
        }
    }, [location])

    useEffect(() => {
        const agentToSelect = location.search.slice(1).split('&')[1]
        const type = agentToSelect?.split('=')[0]
        const agent_id = agentToSelect?.split('=')[1]
        if (type === 'expert') {
            const agent: AgentInterface | undefined = agentsList.find((agent: any) => agent.id === agent_id)
            if (agent) setAgentsSelected([agent])
        }
        if (type === 'summarizer') {
            const agent: AgentInterface | undefined = summarizersList.find((agent: any) => agent.id === agent_id)
            if (agent) setSummarizer(agent)
        }
    }, [agentsList, summarizersList])

    useEffect(() => {
        if (history.length === 0) setLoadingHistory(true)
        getMyUserAccount().then((response: any) => {
            setHistory(response.conversations)
            setLoadingHistory(false)
        })
    }, [historyPing])

    useEffect(() => {
        if (selectedConversation === null)
            setAgentsSelected(globalState.user?.defaultExperts ? globalState.user.defaultExperts : [])
    }, [globalState.user])

    // useEffect(() => {
    //     const intervals: { [id: string]: any } = {}
    //     for (const [key, value] of Object.entries(streamHandler.conversations)) {
    //         const user_message_id = key.split('_')[1]
    //         const agent_answer_id = key.split('_')[2]
    //         const agent_id = key.split('_')[3]
    //         const target_message = chosenMessages.find((message: MessageInterface) => message.id === user_message_id)
    //         const target_submessage = target_message?.agentAnswers.find(
    //             (submessage) => submessage.expertResponding.id === agent_id,
    //         )
    //         if (target_submessage) {
    //             console.log('target_submessage found', agent_answer_id)
    //             if (!progressiveText[agent_answer_id]) {
    //                 setProgressiveText((prev) => ({ ...prev, [agent_answer_id]: '' }))
    //             }
    //             if (!intervals[agent_answer_id]) {
    //                 intervals[agent_answer_id] = setInterval(() => {
    //                     setProgressiveText((prev) => {
    //                         const currentText = prev[agent_answer_id] || ''
    //                         if (currentText.length < value.chunk.Content.length) {
    //                             return {
    //                                 ...prev,
    //                                 [agent_answer_id]: value.chunk.Content.slice(0, currentText.length + 1),
    //                             }
    //                         } else {
    //                             clearInterval(intervals[agent_answer_id])
    //                             return prev
    //                         }
    //                     })
    //                 }, 10)
    //             }
    //             // if (target_submessage.context?.length === 0) {
    //             //     const context = streamHandler.contexts[agent_id] ? [streamHandler.contexts[agent_id][0]] : []
    //             //
    //             // }
    //
    //         } else {
    //             console.log('target_submessage not found', agent_answer_id)
    //         }
    //     }
    //     return () => {
    //         Object.values(intervals).forEach(clearInterval)
    //     }
    // }, [streamHandler.conversations])

    useEffect(() => {
        chosenMessages.forEach((message) => {
            message.agentAnswers.forEach((submessage) => {
                if (progressiveText[submessage.id]) {
                    submessage.response[0].text = progressiveText[submessage.id]
                    if (reachedBottom) {
                        scrollDown(true)
                    }
                }
            })
            if (message.summarizerAnswer && progressiveText[message.summarizerAnswer.id]) {
                message.summarizerAnswer.text = progressiveText[message.summarizerAnswer.id]
                if (reachedBottom) {
                    scrollDown(true)
                }
            }
        })
    }, [reachedBottom, progressiveText])

    // useEffect(() => {
    //     if (messagesLoaded) {
    //         const last_message = chosenMessages[chosenMessages.length - 1]
    //     }
    // }, [messagesLoaded])

    useEffect(() => {
        const last_message: MessageInterface = chosenMessages[chosenMessages.length - 1]
        if (
            conversationState === ConversationState.LOADING_AGENT_RESPONSE &&
            chosenMessages.length > 0 &&
            !last_message.finished
        ) {
            expertStream()
                .then(() => {
                    const last_message_flow = last_message.messageFlow
                    if (
                        last_message_flow === MessageFlow.NO_SUMMARY_ONE_EXPERT ||
                        last_message_flow === MessageFlow.NO_SUMMARY
                    ) {
                        chooseFinal()
                    } else choose()
                })
                .catch((e) => {
                    console.log(e)
                    setConversationState(ConversationState.READY)
                    setGenerationStage({})
                })
        }
    }, [conversationState, chosenMessages])

    const expertStream = async () => {
        const last_message = chosenMessages[chosenMessages.length - 1]
        if (last_message && last_message.agentAnswerIds) {
            const generated_answers = last_message.agentAnswers.map((answer: any) => answer.expertResponding.id)
            const streamPromises = last_message.agentAnswerIds.map((agent_id) => {
                let expertName = ''
                const expert = agentsSelected.find((agent: any) => agent.id === agent_id)
                if (expert) expertName = expert.name
                else expertName = 'Expert'
                if (agent_id && !generated_answers.includes(agent_id)) {
                    try {
                        scrollDown()
                        return executeStream(
                            'ExpertResponseStreamWithPythonModule',
                            agent_id,
                            last_message.id,
                            expertName,
                        )
                    } catch (e) {
                        throw new Error('There was an issue with streaming response. Please try again later.')
                    }
                }
            })

            await Promise.all(streamPromises)
        }
    }

    const choose = () => {
        const last_message: MessageInterface = chosenMessages[chosenMessages.length - 1]
        if (last_message.messageFlow === MessageFlow.NO_SUMMARY_ONE_EXPERT && last_message.agentAnswers[0]) {
            sendFinalAnswer(last_message.agentAnswers[0].id).then(() => {
                setConversationState(ConversationState.READY)
            })
        } else if (last_message.messageFlow === MessageFlow.ONE_EXPERT && last_message.agentAnswers[0]) {
            sendAnswers([last_message.agentAnswers[0].id]).then((r) => {
                setConversationState(ConversationState.LOADING_SUMMARIZER_RESPONSE)
            })
        } else setConversationState(ConversationState.CHOOSE)
    }

    const sendAnswers = async (answers: any = null) => {
        if (answers.length === 0) return
        else {
            SelectExpertsAnswers(answers).then(async (response: any) => {
                if (messages.length > 0) {
                    const last_message: MessageInterface = messages[messages.length - 1]
                    if (answers) {
                        for (const message of last_message.agentAnswers) {
                            if (answers.includes(message.id)) {
                                message.chosen = true
                            }
                        }
                        setSelectedAnswers([])
                        setConversationState(ConversationState.LOADING_SUMMARIZER_RESPONSE)
                    }
                }
            })
        }
    }
    //
    // useEffect(() => {
    //     if (conversationState === ConversationState.LOADING_SUMMARIZER_RESPONSE && chosenMessages.length > 0) {
    //         summaryStream().then(() => {
    //             chooseFinal()
    //         })
    //     }
    // }, [conversationState, chosenMessages])
    //
    // const summaryStream = async () => {
    //     const last_message = chosenMessages[chosenMessages.length - 1]
    //     if (last_message && last_message.id && summarizerId[0]) {
    //         await streamResponse('ExpertSummaryStream?summarizerAnswerId=', summarizerId[0], last_message.id, '1', summarizerId[2], summarizerId[3]).catch((e) => {
    //             console.log(e)
    //         })
    //     }
    // }
    //
    const chooseFinal = () => {
        const last_message = chosenMessages[chosenMessages.length - 1]
        if (
            last_message &&
            last_message.messageFlow === MessageFlow.NO_SUMMARY_ONE_EXPERT &&
            last_message.agentAnswers[0]
        ) {
            sendFinalAnswer(last_message.agentAnswers[0].id)
                .then(() => {
                    setConversationState(ConversationState.READY)
                })
                .catch((e) => {
                    setConversationState(ConversationState.CHOOSE_FINAL)
                })
        } else setConversationState(ConversationState.CHOOSE_FINAL)
    }

    const sendFinalAnswer = async (message_id: string) => {
        if (selectedConversation !== null) {
            try {
                console.log(message_id)
                await selectAsFinalResponse(message_id)
                const temp = [...chosenMessages]
                const last_message = temp[temp.length - 1]
                last_message.finished = true
                if (last_message.summarizerAnswer) {
                    if (last_message.summarizerAnswer.id === message_id) {
                        last_message.summarizerAnswer.chosenFinal = true
                    }
                    last_message.summarizerAnswer.finished = true
                }
                for (let i = 0; i < last_message.agentAnswers.length; i++) {
                    if (last_message.agentAnswers[i].id === message_id) {
                        last_message.agentAnswers[i].chosenFinal = true
                    }
                    last_message.agentAnswers[i].finished = true
                }
                setMessages(temp)
                setConversationState(ConversationState.READY)
            } catch (error) {
                console.error('Error selecting final answer')
            }
        }
    }


    const sendMessage = async (messagePassed?: string) => {
        if (userMessageRef && userMessageRef.current) {
            let message
            if (messagePassed === undefined || messagePassed === null) {
                // @ts-ignore
                message = userMessageRef.current.value
            } else message = messagePassed
            if (message === '') return
            const agents_ids = agentsSelected.map((agent: AgentInterface) => agent.id)
            let summarizer_id = null
            if (summarizer) summarizer_id = summarizer.id
            streamHandler.resetLastNumberChunks()
            if (messages.length === 0) {
                const selectedCustomInstructionToParse =
                    selectedCustomInstruction.id !== '-1' ? selectedCustomInstruction.id : null
                startConversation(
                    message.toString(),
                    agents_ids,
                    summarizer_id,
                    selectedCustomInstructionToParse,
                    selectedModel.id,
                    selectedLanguage.name,
                )
                    .then((response: ConversationInterface) => {
                        setHistory([response, ...history])
                        if (process.env.REACT_APP_DEV === 'true') navigate(`/dev-chat?${response.id}`)
                        else navigate(`/?${response.id}`)
                        setConversationState(ConversationState.LOADING_AGENT_RESPONSE)
                    })
                    .catch((error: any) => {
                        console.log(error)
                    })
            } else {
                const agents_ids = agentsSelected.map((agent: any) => agent.id)
                const previous_message = chosenMessages[chosenMessages.length - 1].id
                setConversationState(ConversationState.LOADING_AGENT_RESPONSE)
                AddNextPrompt(
                    message.toString(),
                    agents_ids,
                    summarizer ? summarizer.id : null,
                    previous_message ? previous_message : '',
                    null,
                )
                    .then(async (response: any) => {
                        await fetchFullConversation(
                            response,
                            setLoadingConversation,
                            selectedConversation,
                            messages,
                            setMessages,
                        )
                        setFlow([...flow, 0])
                        // setConversationState(ConversationState.UNKNOWN)
                    })
                    .catch((error) => {
                        handleError(error, sendAlert)
                        setConversationState(ConversationState.READY)
                    })
            }
            // @ts-ignore
            userMessageRef.current.value = ''
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        }
    }

    const createCustomInstructionFunc = () => {
        const name = customInstructionNameRef.current.value
        const instruction = customInstructionRef.current.value
        if (name === '' || instruction === '') return
        createCustomInstruction(name, instruction).then((response: any) => {
            setCustomProfiles([...customProfiles, response])
            setNewInstruction(false)
        })
    }

    const editCustomInstructionFunc = () => {
        const name = customInstructionNameRef.current.value
        const instruction = customInstructionRef.current.value
        if (name === '' || instruction === '') return
        editCustomInstruction(editInstruction.id, name, instruction).then((response: any) => {
            const index = customProfiles.findIndex((instruction: any) => instruction.id === editInstruction.id)
            const newCustomProfiles = [...customProfiles]
            newCustomProfiles[index] = response
            setCustomProfiles(newCustomProfiles)
            setEditInstruction(null)
        })
    }

    const handleEditPrompt = (
        previous_message_id: string | null,
        conversation_id: string | null,
        prompt: string,
        experts_ids: string[],
        summarizer_id: string,
    ) => {
        AddNextPrompt(prompt.toString(), experts_ids, summarizer_id, previous_message_id, conversation_id).then(
            async (response: any) => {
                await fetchFullConversation(
                    response,
                    setLoadingConversation,
                    selectedConversation,
                    messages,
                    setMessages,
                )
            },
        )
    }


    const executeChatPreset = async (index: number) => {
        const preset = chatPresets[index]
        const agentsToSelect = agentsList.filter((agent: any) => preset.experts_ids.includes(agent.id))
        setAgentsSelected(agentsToSelect)
        await sendMessage(preset.prompt)
    }

    return (
        <div className="chat">
            <SEO
                title="APPI | Chat"
                description="APPI chat page"
                name="APPI Chat"
                keywords={['APPI', 'chat', 'chatbot', 'artificial intelligence', 'AI', 'chat page']}
                type="website"
            />
            {historyModal !== null && historyModal === '' && (
                <ConfirmModal
                    title="Clear history"
                    description="This action will delete all of your conversations"
                    close={() => setHistoryModal(null)}
                    proceed={() => {
                        deleteAllConversations().then(() => {
                            setHistoryPing(!historyPing)
                            setHistoryModal(null)
                            navigate(`/?new`)
                            window.location.reload()
                        })
                    }}
                />
            )}

            {historyModal !== null && historyModal !== '' && (
                <ConfirmModal
                    title="Delete conversation"
                    description="This action will delete selected conversation"
                    close={() => setHistoryModal(null)}
                    proceed={() => {
                        if (selectedConversation)
                            deleteConversation(selectedConversation.id).then(() => {
                                setHistoryPing(!historyPing)
                                setHistoryModal(null)
                                if (process.env.REACT_APP_DEV === 'true') navigate(`/dev-chat?new`)
                                else navigate(`/?new`)
                            })
                    }}
                />
            )}

            {newInstruction && (
                <Modal close={() => setNewInstruction(false)}>
                    <h1 className="modal-content-title">Create new custom profile</h1>
                    <form className="modal-content-form">
                        <input type="text" placeholder="Profile name" ref={customInstructionNameRef} />
                        <TextareaAutosize
                            minRows={15}
                            maxRows={20}
                            className="modal-content-form-textarea"
                            placeholder="Profile instruction"
                            ref={customInstructionRef}
                        />
                        <div className="modal-content-buttons">
                            <div className="modal-content-buttons-proceed" onClick={() => setNewInstruction(false)}>
                                Cancel
                            </div>
                            <div
                                className="modal-content-buttons-cancel"
                                onClick={() => {
                                    createCustomInstructionFunc()
                                }}
                            >
                                Proceed
                            </div>
                        </div>
                    </form>
                </Modal>
            )}

            {editInstruction && (
                <Modal close={() => setEditInstruction(null)}>
                    <h1 className="modal-content-title">Edit instruction</h1>
                    <form className="modal-content-form">
                        <input
                            type="text"
                            placeholder="Profile name"
                            ref={customInstructionNameRef}
                            defaultValue={editInstruction.name}
                        />
                        <TextareaAutosize
                            minRows={10}
                            maxRows={15}
                            className="modal-content-form-textarea"
                            placeholder="Profile Instruction"
                            ref={customInstructionRef}
                            defaultValue={editInstruction.instruction}
                        />
                        <div className="modal-content-buttons">
                            <div className="modal-content-buttons-proceed" onClick={() => setEditInstruction(null)}>
                                Cancel
                            </div>
                            <div
                                className="modal-content-buttons-cancel"
                                onClick={() => {
                                    editCustomInstructionFunc()
                                }}
                            >
                                Proceed
                            </div>
                        </div>
                    </form>
                </Modal>
            )}

            <Sidebar navigate={navigate} APPI={APPI} executeChatPreset={executeChatPreset} />
            {/*{(messages.length === 0 && !loadingConversation) && <FreeBlobEnv*/}
            {/*    width={'100%'} height={'100%'} min={30} max={10}*/}
            {/*/>}*/}
            <div
                className="chat-conversation"
                style={
                    sidebar !== SidebarPages.None && !mobile
                        ? {
                            width: 'calc(100% - 21rem)',
                            marginLeft: '21rem',
                        }
                        : { width: '100%' }
                }
            >
                <div
                    className={
                        messages.length === 0 && !loadingConversation
                            ? 'chat-mainArea chat-mainArea-welcome'
                            : 'chat-mainArea'
                    }
                >
                    <Conversation
                        editPrompt={handleEditPrompt}
                        languages={languages}
                        scrollDown={scrollDown}
                        choose={choose}
                        chooseFinal={chooseFinal}
                    />
                    <form className="userInput-form">
                        <UserInput
                            SidebarInterface={SidebarPages}
                            sendAnswers={sendAnswers}
                            sendFinalAnswer={sendFinalAnswer}
                            sendMessage={sendMessage}
                            user_message_ref={userMessageRef}
                        />
                        <h1 className="userInput-form-notice">
                            LLM's can make mistakes. Consider checking important information.
                        </h1>
                    </form>
                </div>
            </div>
            <div className="chat-inspector-icons">
                {inspectorOpen && inspectorMode === InspectorMode.DOCUMENT && (
                    <div
                        className="chat-inspector-icon chat-inspector-close"
                        onClick={() => setInspectorMode(InspectorMode.BROWSER)}
                    >
                        Back
                    </div>
                )}
                <div className="chat-inspector-icon" onClick={() => setInspectorOpen(!inspectorOpen)}>
                    {inspectorOpen ? 'Close' : 'Inspector'}
                </div>
            </div>
            {inspectorOpen && <Inspector images={false} />}
        </div>
    )
}
export default Chat

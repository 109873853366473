import * as FaIcons from 'react-icons/fa'
import React, { useRef, useState } from 'react'
import { agentIconType, AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import { AgentType } from '../../../../Utils/enums'
import './UserInputAgentIcon.scss'
import { AiOutlineClose } from 'react-icons/ai'
import AgentIcon from '../../../../Utils/CustomAgentIcon'

interface UserInputAgentIconInterface {
    agent: AgentInterface,
    setSidebar: Function,
    setSidebarModal: Function,
    SidebarInterface: any,
    type: AgentType
    removeAgent: Function
}

export const UserInputAgentIcon = ({
                                       agent,
                                       setSidebar,
                                       setSidebarModal,
                                       SidebarInterface,
                                       type,
                                       removeAgent,
                                   }: UserInputAgentIconInterface) => {
    const [visible, setVisible] = useState(false)
    const hoverTimeout = useRef<NodeJS.Timeout | null>(null)
    const icons = FaIcons as agentIconType

    // const CurrentIconComponent = agent.logoUrl ? icons[agent.logoUrl] : agent?.logoUrl ? icons[agent?.logoUrl] : icons['FaRobot']
    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setVisible(true)
        }, 200)
    }

    const handleMouseLeave = () => {
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current)
        }
        setVisible(false)
    }
    return (
        <div className={type === AgentType.EXPERT ? 'userInput-agents-item' : 'userInput-summarizer'}
             onClick={() => {
                 setSidebarModal(true)
                 setSidebar(SidebarInterface.Agents)
             }}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <AgentIcon className='userInput-agents-item-robot'
                       agent={agent}
                // @ts-ignore
                       style={{ color: agent.logoColorHex }} />
            {visible && <div className='userInput-hover'>
                <h1>
                    {agent.name}
                    <AiOutlineClose className='userInput-hover-close' onClick={() => removeAgent()} />
                </h1>
            </div>}
        </div>
    )
}
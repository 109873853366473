import '../../../Landing/DocumentShowcase/DocumentShowcase.scss'
import { FaRobot } from 'react-icons/fa6'
import { AiOutlineUser } from 'react-icons/ai'
import { Parser } from '../../../Chat/Message/Parser'

interface mockMessageProps {
    isUser: boolean,
    iconColor?: string,
    authorName: string,
    message: string,
    className?: string,
}

export const MockMessage = (props: mockMessageProps) => {
    return (
        <div
            className={'message response' + (props.className ?? '')}
        >
            <div className="message-innerWrapper">
                <div className="message-icon-wrapper">
                    {!props.isUser ?
                        <FaRobot className="message-icon" style={{
                            color: props.iconColor ?? '',
                        }} />
                        :
                        <AiOutlineUser className="message-icon" />
                    }
                    <div className="message-icon-sender">{props.authorName}</div>
                </div>
                <div className="message-text-wrapper">
                    <div className="message-text">
                        <Parser>{props.message}</Parser>
                    </div>
                </div>


            </div>
        </div>
    )
}

import { ContextPartInterface, ContextPartType } from '../../../../Interfaces/documentInterfaces'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import Zoom from 'react-medium-image-zoom'
import React from 'react'
import './MessageAnswerModal.scss'
import { Inspector } from '../../Inspector/Inspector'
import { AiOutlineClose } from 'react-icons/ai'

interface MessageAnswerModalInterface {
    visibleContext: ContextPartInterface[],
    visibleContextSelected: number
    setVisibleContextSelected: Function
    close: Function
}

export const MessageAnswerModal = ({
                                       visibleContext,
                                       visibleContextSelected,
                                       setVisibleContextSelected,
                                       close,
                                   }: MessageAnswerModalInterface) => {
    const element = visibleContext[visibleContextSelected] ? visibleContext[visibleContextSelected] : null

    const next = () => {
        if (visibleContextSelected < visibleContext.length - 1)
            setVisibleContextSelected(visibleContextSelected + 1)
        else setVisibleContextSelected(0)
    }
    const previous = () => {
        if (visibleContextSelected > 0)
            setVisibleContextSelected(visibleContextSelected - 1)
        else setVisibleContextSelected(visibleContext.length - 1)
    }

    if (!element) return null
    return (
        <div className="message-images-modal-wrapper" onClick={() => close()}>
            <AiOutlineClose className="message-images-modal-close" onClick={() => close()} />
            <div className="message-images-modal-content">a
                <MdOutlineKeyboardArrowLeft className="message-images-modal-arrow message-images-modal-arrow-left"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                previous()
                                            }} />
                <MdOutlineKeyboardArrowRight className="message-images-modal-arrow message-images-modal-arrow-right"
                                             onClick={(e) => {
                                                 e.stopPropagation()
                                                 next()
                                             }} />
                {element.fragment.text &&
                    <p className="message-images-modal-text">{element.fragment.messagePartType === ContextPartType.SECTION ? element.fragment.summary : element.fragment.text}</p>}
                <div onClick={(e) => e.stopPropagation()}>
                    <Zoom>
                        {visibleContext[visibleContextSelected].fragment.image &&
                            <img className="message-images-modal-main"
                                 src={visibleContext[visibleContextSelected].fragment.image}
                                 alt={''}
                                 onClick={(e) => e.stopPropagation()}
                            />}
                    </Zoom>
                </div>
            </div>
            <div className="message-images-modal-inspector" onClick={(e) => e.stopPropagation()}>
                <Inspector images={true} setChunk={setVisibleContextSelected} highlighted={visibleContextSelected} />
            </div>

        </div>
    )
}